import React, { useState } from 'react';
import { Tr, Td, Center, Text, Button, IconButton } from '@chakra-ui/react';
import { colors } from '../../theme/colors';
import { Icon, CustomSwitch, DeleteConfirmPopup } from '../../components';
import { statusColor } from '../../constants';
import {
	TestRunningStatusChangeApiOperation,
	TestCycleItem,
	TestCycleStatus,
} from '../../types';
import {
	useDeleteTestCycle,
	useChangeTestRunningStatus,
} from '../../query-hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../constants/route-paths';
import { isPending } from '@reduxjs/toolkit';

export const TestRow: React.FC<TestCycleItem> = ({
	customerId,
	customerName,
	id,
	isTestRun,
	name,
	status,
	testGroup,
}) => {
	const navigate = useNavigate();
	const [statusLoading, setStatusLoading] = useState(false);
	const changeTestRunningStatus = useChangeTestRunningStatus();
	const deleteTestCycle = useDeleteTestCycle();

	const handleRunningStatusChange = (
		testCycleId: number,
		apiOperation: TestRunningStatusChangeApiOperation,
	) => {
		setStatusLoading(true);
		changeTestRunningStatus.mutate({
			apiOperation,
			testCycleId,
		});
	};
	// ========================

	const handleDeleteTestCycle = (testCycleId: number) => {
		deleteTestCycle.mutate(testCycleId, {
			onSuccess: data => {
				if (!data?.success) {
					return;
				}
				setStatusLoading(false);
			},
		});
	};

	const handleDetails = (id: number, statusId: number, isTestRun: boolean) =>
		navigate(`${ROUTE_PATH.tests}/${id}`, {
			state: { statusId, isTestRun },
		});

	return (
		<Tr>
			<Td>{id}</Td>
			<Td>{testGroup}</Td>
			<Td>{customerName}</Td>
			<Td>{customerId}</Td>
			<Td>
				<Center>
					<Text
						display={'flex'}
						alignItems={'center'}
						color={colors.dark}
						justifyContent={'center'}
						maxW={'105px'}
						w={'100%'}
						h={'40px'}
						fontWeight={600}
						backgroundColor={statusColor[status].bg}
						border={statusColor[status].border}
						borderRadius={'20px'}
						p={'10px'}>
						{' '}
						{TestCycleStatus[status]}
					</Text>
				</Center>
			</Td>
			<Td>
				<Center>
					<CustomSwitch
						isChecked={isTestRun}
						setIsChecked={() =>
							handleRunningStatusChange(
								id,
								isTestRun
									? TestRunningStatusChangeApiOperation.DEACTIVATE
									: TestRunningStatusChangeApiOperation.ACTIVATE,
							)
						}
						trackSize={45}
						isDisabled={
							status === TestCycleStatus.Completed ||
							(changeTestRunningStatus.isPending && statusLoading)
						}
					/>
				</Center>
			</Td>
			<Td>
				<DeleteConfirmPopup
					title="Are you sure you want to delete that test?"
					onConfirm={() => handleDeleteTestCycle(id)}
					confirmButtonProps={{
						isLoading: deleteTestCycle.isPending,
					}}>
					<IconButton
						padding={0}
						isDisabled={
							status === TestCycleStatus.Completed ||
							status === TestCycleStatus.Active
						}
						aria-label="Delete animal"
						w="20px"
						h="20px"
						minW="20px">
						<Icon
							name="trash"
							width={'20px'}
							height={'20px'}
							color={colors.error}
						/>
					</IconButton>
				</DeleteConfirmPopup>
			</Td>
			<Td>
				<Button onClick={() => handleDetails(id, status, isTestRun)}>
					{' '}
					<Icon
						name="right-arrow"
						color="dark"
						width={'20px'}
						height={'20px'}
					/>
				</Button>
			</Td>
		</Tr>
	);
};
