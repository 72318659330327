import React, { useState } from 'react';
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	IconButton,
	PopoverBody,
	Button,
	Heading,
	Flex,
	Text,
} from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';
import { useGetBinSettings } from '../../query-hooks';
import { AddBinSettingModal, EditBinSettingModal } from '../../modals';
import { SettingItem } from './SettingItem';
import { BinSetting } from '../../types';
import { colors } from '../../theme/colors';

export const BinSettings: React.FC = () => {
	const { data } = useGetBinSettings();
	const [addModalOpen, setAddModalOpen] = useState(false);
	const [toEditSetting, setToEditSetting] = useState<BinSetting | null>(null);

	return (
		<>
			<Popover>
				{({ isOpen }) => (
					<>
						<PopoverTrigger>
							<IconButton
								borderRadius="8px"
								aria-label="Bin settings"
								bg={isOpen ? '#DCE6D8' : 'white'}
								_hover={{
									bg: '#DCE6D8',
									borderWidth: '1px',
									borderColor: 'primary',
								}}>
								<Icon
									name="cog"
									width={'24px'}
									height={'24px'}
									color={colors.dark}
								/>
							</IconButton>
						</PopoverTrigger>
						<PopoverContent
							bg="light"
							borderColor="primary"
							borderRadius="8px"
							minW="344px"
							p="32px">
							<PopoverBody p={0}>
								<Heading
									variant="h2"
									fontWeight="700"
									color="#161616"
									textAlign="center">
									Bin Settings
								</Heading>
								<Flex
									my="32px"
									flexDirection="column"
									gap="16px">
									{data?.value?.length ? (
										data?.value?.map(elem => (
											<SettingItem
												key={elem.id}
												{...elem}
												onEdit={() =>
													setToEditSetting(elem)
												}
											/>
										))
									) : (
										<Text
											color="darkLight"
											variant="B01"
											textAlign="center">
											No settings yet
										</Text>
									)}
								</Flex>
								<Button
									variant={'primary'}
									fontWeight={400}
									height="48px"
									leftIcon={
										<Icon
											name="add"
											width={'20px'}
											height={'20px'}
											color="light"
										/>
									}
									onClick={() => setAddModalOpen(true)}>
									Add Setting
								</Button>
							</PopoverBody>
						</PopoverContent>
					</>
				)}
			</Popover>
			<AddBinSettingModal
				isOpen={addModalOpen}
				onClose={() => setAddModalOpen(false)}
			/>
			<EditBinSettingModal
				isOpen={!!toEditSetting}
				onClose={() => setToEditSetting(null)}
				data={toEditSetting}
			/>
		</>
	);
};
