import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { QueryKeys } from '../../constants';
import { ApiResponseEmpty } from '../../types';
import apiBase from '../../api/api.base';
import { Alerter } from '../../utils';

export const useDeleteTestCyclesAnimal = () => {
	const queryClient = useQueryClient();
	const { id } = useParams();

	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);

	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ animalInTestCycleId: number }
	>({
		mutationFn: ({ animalInTestCycleId }) => {
			return apiBase.delete(
				`/api/ranchos/${ranchoId}/test-cycles/${id}/animals/${animalInTestCycleId}/delete`,
				{},
			);
		},
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors?.[0]?.message);
				return;
			}
			Alerter.success(
				data.messages?.[0]?.message ||
					'Animal information was successfully deleted',
			);
			queryClient.invalidateQueries({
				queryKey: [QueryKeys.GET_TEST_CYCLES_ANIMALS],
			});
		},
	});
};
