import React from 'react';
import { Td, Tr, IconButton, HStack, Box } from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { Icon, CustomDatePicker, CustomTextarea } from '../../../../components';
import { colors } from '../../../../theme/colors';
import { useCreateDisqualifiedDay } from '../../../../query-hooks';
import { addDisqualifiedDayResolver } from '../../../../validation';

interface INewDayForm {
	date: Date;
	note: string;
}

interface INewDisqualifiedDayRowProps {
	onCancel: VoidFunction;
}

export const NewDisqualifiedDayRow: React.FC<INewDisqualifiedDayRowProps> = ({
	onCancel,
}) => {
	const createDisqualifiedDayMutation = useCreateDisqualifiedDay();

	const {
		control,
		handleSubmit,
		formState: { errors },
	} = useForm<INewDayForm>({
		// @ts-ignore
		resolver: addDisqualifiedDayResolver,
	});

	const onSubmit = (data: INewDayForm) => {
		createDisqualifiedDayMutation.mutate(
			{
				note: data.note,
				date: dayjs(data.date).format('YYYY-MM-DD'),
			},
			{
				onSuccess(data) {
					if (data.success) {
						onCancel();
					}
				},
			},
		);
	};

	return (
		<Tr>
			<Td w="100px" verticalAlign="top"></Td>
			<Td w="140px" verticalAlign="top">
				<HStack align="flex-start">
					<Controller
						control={control}
						name="date"
						render={({ field }) => (
							<CustomDatePicker
								showTime={false}
								value={field.value}
								onChange={field.onChange}
								className="row-field-low"
							/>
						)}
					/>
					<Box boxSize="16px" pt="4px">
						<Icon name="calendar" width={'16px'} height={'16px'} />
					</Box>
				</HStack>
			</Td>
			<Td minW="170px" verticalAlign="top">
				<Controller
					name="note"
					control={control}
					defaultValue=""
					render={({ field: { onChange, value } }) => (
						<CustomTextarea
							minHeight={24}
							padding="4px 8px"
							value={value}
							fontSize="12px"
							lineHeight="14px"
							onChange={onChange}
							errorMsg={errors.note?.message}
						/>
					)}
				/>
			</Td>
			<Td verticalAlign="top" style={{ width: '90px' }}>
				<HStack justify="space-between" maxW="65px" mx="auto">
					<IconButton
						aria-label="Save day"
						w="20px"
						h="20px"
						minW="20px"
						isLoading={createDisqualifiedDayMutation.isPending}
						onClick={handleSubmit(onSubmit)}>
						<Icon
							type="submit"
							name="save"
							width={'20px'}
							height={'20px'}
							color={colors.primary}
						/>
					</IconButton>
					<IconButton
						aria-label="Cancel creating day"
						w="20px"
						h="20px"
						minW="20px"
						onClick={onCancel}>
						<Icon
							name="trash"
							width={'20px'}
							height={'20px'}
							color={colors.error}
						/>
					</IconButton>
				</HStack>
			</Td>
		</Tr>
	);
};
