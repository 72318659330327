import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../constants';
import { ApiResponse, ReportsAnimalsBehaviorResponse } from '../../types';
import apiBase from '../../api/api.base';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

interface IPayload {
	currentPage: number;
	startDate?: string;
	endDate?: string;
	isEnabled?: boolean;
}

export const useGetReportAnimalBehavior = ({
	currentPage,
	startDate,
	endDate,
	isEnabled,
}: IPayload) => {
	const { id: testCycleId } = useParams();
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useQuery({
		queryKey: [
			QueryKeys.GET_REPORTS_ANIMAL_BEHAVIOR,
			ranchoId,
			testCycleId,
			currentPage,
			startDate,
			endDate,
		],
		queryFn: (): Promise<ApiResponse<ReportsAnimalsBehaviorResponse>> =>
			apiBase.get(
				`/api/ranchos/${ranchoId}/test-cycles/${testCycleId}/animal-behavior`,
				{ currentPage, startDate, endDate, pageSize: 10 },
			),
		enabled: !!testCycleId && !!isEnabled,
	});
};
