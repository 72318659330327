export const labelStyles = {
	color: 'primary',
	fontSize: '14px',
	fontWeight: '500',
	margin: 0,
};
export const labelHorizontalStyles = {
	color: 'dark',
	minWidth: '180px',
	fontWeight: '600',
	fontSize: '14px',
	margin: 0,
};

export const labelVerticalStyles = {
	color: 'primary',
	minWidth: '180px',
	fontWeight: '500',
	fontSize: '15px',
	margin: 0,
};
