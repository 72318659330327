export enum QueryKeys {
	GET_USERS_DETAILS = 'get-users-details',
	GET_ANIMALS = 'get-animals',
	GET_ANIMALS_GET_INFO = 'get-animals-get-info',
	GET_TEST_CYCLES = 'get-test-cycles',
	GET_TEST_CYCLES_ANIMALS = 'get-test-cycles-animals',
	GET_TEST_CYCLES_ANIMAL = 'get-test-cycles-animal',
	GET_BIN_SETTINGS = 'get-bin-settings',
	GET_PRE_SURVEY = 'get-pre-survey',
	GET_TEST_NOTES = 'get-test-notes',
	GET_DISQUALIFIED_DAYS = 'get-disqualified-days',
	GET_FEED_RATION = 'get-feed-ration',
	GET_FEED_RATION_BY_ID = 'get-feed-ration-by-id',
	GET_DAILY_FEED_DELIVERY = 'get-daily-feed-delivery',
	GET_SICK_DAYS = 'get-sick-days',
	GET_REMOVE_NOTES = 'get-remove-notes',
	GET_MEASUREMENT_SESSIONS = 'get-measurement-sessions',
	GET_TEST_CYCLES_ANIMALS_CHANGE_EID_NOTE = 'get-test-cycles-animals-change-eid-note',
	GET_AVAILABLE_INTERVALS = 'get-available-intervals',
	GET_ANALYSIS_DATA = 'get-analysis-data',

	//reports
	GET_REPORTS = 'get-reports',
	GET_REPORTS_ANIMAL_INFORMATION = 'get-reports-animal-information',
	GET_REPORTS_ANIMAL_WEIGHTS = 'get-reports-animal-weights',
	GET_REPORTS_DAILY_ERRORS = 'get-reports-daily-errors',
	GET_REPORTS_ANIMAL_INTAKES = 'get-reports-animal-intakes',
	GET_REPORTS_ANIMAL_BEHAVIOR = 'get-reports-animal-behavior',
	GET_REPORTS_FINAL_ANALYSIS = 'get-reports-final-analysis',

	//selectors
	GET_RANCHO_ANIMALS_OPTIONS = 'get-rancho-animals-options',
	GET_RANCHO_ANIMALS_TEST_OPTIONS = 'get-rancho-animals-test-options',
	GET_RANCHO_TEST_DATES = 'get-rancho-test-dates',
}
