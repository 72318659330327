import React, { useCallback, useState } from 'react';
import { Box, HStack, IconButton, Td, Tr } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import {
	CustomDatePicker,
	CustomTextarea,
	DeleteConfirmPopup,
	Icon,
	InputText,
} from '../../../../../components';
import { colors } from '../../../../../theme/colors';
import {
	convertStringToDate,
	getDateByFormat,
	onlyDigitsAndDot,
	toPayloadDate,
} from '../../../../../utils';
import {
	useDeleteDailyFeedDelivery,
	useUpdateDailyFeedDelivery,
} from '../../../../../query-hooks';
import {
	DailyFeedDeliveryFormType,
	dailyFeedDeliveryResolver,
} from '../../../../../validation';
import { DailyFeedDeliveryItemType } from '../../../../../types';

export const DailyFeedDeliveryRow: React.FC<DailyFeedDeliveryItemType> = ({
	date,
	feedDispensed,
	id,
	note,
}) => {
	const [isEdit, setIsEdit] = useState(false);

	const updateDailyFeedDeliveryMutation = useUpdateDailyFeedDelivery();
	const deleteDailyFeedDeliveryMutation = useDeleteDailyFeedDelivery();

	const {
		control,
		formState: { errors },
		handleSubmit,
	} = useForm<DailyFeedDeliveryFormType>({
		defaultValues: {
			date: convertStringToDate(date),
			feedDispensed: feedDispensed.toString(),
			note,
		},
		resolver: dailyFeedDeliveryResolver,
	});

	const onSubmit = (data: DailyFeedDeliveryFormType) => {
		const { note, date, feedDispensed } = data;
		updateDailyFeedDeliveryMutation.mutate(
			{
				noteId: id,
				note: note || '',
				date: toPayloadDate(date),
				feedDispensed: feedDispensed ? +feedDispensed : 0,
			},
			{
				onSuccess(data) {
					if (!data.success) {
						return;
					}
					setIsEdit(false);
				},
			},
		);
	};

	const onDelete = useCallback(
		() =>
			deleteDailyFeedDeliveryMutation.mutate(
				{
					noteId: id,
				},
				{
					onSuccess: data => {
						if (!data.success) {
							return;
						}
						setIsEdit(false);
					},
				},
			),
		[id, deleteDailyFeedDeliveryMutation],
	);

	return (
		<Tr>
			<Td w="100px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{id}
			</Td>
			<Td w="140px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{isEdit ? (
					<HStack align="flex-start">
						<Controller
							control={control}
							name="date"
							render={({ field }) => (
								<CustomDatePicker
									showTime={false}
									value={field.value}
									onChange={field.onChange}
									className="row-field-low"
									placeholder="Select a date"
									errorMsg={errors.date?.message as string}
								/>
							)}
						/>
						<Box boxSize="16px" pt="4px">
							<Icon
								name="calendar"
								width={'16px'}
								height={'16px'}
							/>
						</Box>
					</HStack>
				) : (
					getDateByFormat(date)
				)}
			</Td>
			<Td w="180px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{isEdit ? (
					<Controller
						name="feedDispensed"
						control={control}
						defaultValue=""
						render={({ field: { onChange, value } }) => (
							<InputText
								onChange={ev => {
									const inputValue = onlyDigitsAndDot(
										ev.target.value,
									);
									onChange(inputValue);
								}}
								value={value}
								placeholder="Enter feed dispensed"
								height="20px"
								py="4px"
								h="24px"
								fontSize="12px"
								lineHeight="16px"
								_placeholder={{
									fontSize: '12px',
									lineHeight: '16px',
									fontWeight: 500,
									color: 'darkLight',
								}}
								_focusVisible={{
									borderBottomColor: 'primary',
								}}
								errorMsg={
									errors.feedDispensed?.message as string
								}
							/>
						)}
					/>
				) : (
					feedDispensed
				)}
			</Td>
			<Td minW="170px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{isEdit ? (
					<Controller
						name="note"
						control={control}
						defaultValue=""
						render={({ field: { onChange, value } }) => (
							<CustomTextarea
								minHeight={24}
								placeholder="Enter Notes"
								padding="4px 8px"
								value={value}
								fontSize="12px"
								lineHeight="14px"
								onChange={onChange}
								errorMsg={errors.note?.message as string}
							/>
						)}
					/>
				) : (
					note
				)}
			</Td>
			<Td verticalAlign="top" style={{ width: '90px' }}>
				<HStack justify="space-between" maxW="65px" mx="auto">
					{isEdit ? (
						<IconButton
							aria-label="Save daily feed delivery"
							onClick={handleSubmit(onSubmit)}
							w="20px"
							h="20px"
							minW="20px"
							isLoading={
								updateDailyFeedDeliveryMutation.isPending
							}>
							<Icon
								name="save"
								width="20px"
								height="20px"
								color={colors.primary}
							/>
						</IconButton>
					) : (
						<IconButton
							aria-label="Edit daily feed delivery"
							w="20px"
							h="20px"
							minW="20px"
							onClick={() => setIsEdit(true)}>
							<Icon
								name="small-pen"
								width="20px"
								height="20px"
								color={colors.dark}
							/>
						</IconButton>
					)}
					<DeleteConfirmPopup
						title="Are you sure you want to delete that test?"
						onConfirm={onDelete}
						confirmButtonProps={{
							isLoading:
								deleteDailyFeedDeliveryMutation.isPending,
						}}>
						<IconButton
							aria-label="Delete daily feed delivery"
							w="20px"
							h="20px"
							minW="20px">
							<Icon
								name="trash"
								width="20px"
								height="20px"
								color={colors.error}
							/>
						</IconButton>
					</DeleteConfirmPopup>
				</HStack>
			</Td>
		</Tr>
	);
};
