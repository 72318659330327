import React, { useState } from 'react';
import { Td, Text, IconButton, Input } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { Icon } from '../../../../components';
import { colors } from '../../../../theme/colors';
import { useCreateAnimalWeight } from '../../../../query-hooks';

interface IWeightRowProps {
	weight: number;
	date: string;
	note: string;
	sessionId: number;
	animalInTestId: number;
}

export const WeightRow: React.FC<IWeightRowProps> = ({
	weight,
	date,
	note,
	sessionId,
	animalInTestId,
}) => {
	const [isEditMode, setIsEditMode] = useState(false);

	const { control, register, handleSubmit, reset } = useForm<{
		weight: number;
		note: string;
	}>();

	const createAnimalWeightMutation = useCreateAnimalWeight(sessionId);

	const onSubmit = (data: { weight: number; note: string }) => {
		createAnimalWeightMutation.mutate(
			{
				animalInTestCycleId: animalInTestId,
				note: data.note,
				weight: data.weight,
			},
			{
				onSuccess(data) {
					if (data.success) {
						setIsEditMode(false);
					}
				},
			},
		);
		console.log(data, 'edit data');
	};

	return (
		<>
			<Td p="8px" textAlign={'center'}>
				{isEditMode ? (
					<Input
						{...register('weight')}
						border="none"
						width="82px"
						textAlign="center"
						fontSize={'14px'}
						h={'22px'}
						lineHeight={'22px'}
						type="number"
						_focus={{ boxShadow: 'none' }}
					/>
				) : (
					<Text color="darker">{weight}</Text>
				)}
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{date}
				</Text>
			</Td>
			<Td>
				{isEditMode ? (
					<Input
						{...register('note')}
						border="none"
						width="82px"
						_focus={{ boxShadow: 'none' }}
					/>
				) : (
					<Text
						color="darker"
						textAlign="center"
						w="full"
						maxW="160px"
						isTruncated={true}>
						{note}
					</Text>
				)}
			</Td>
			<Td borderRightWidth="1px" borderRightColor="lightGray" p="8px">
				{isEditMode ? (
					<IconButton
						aria-label="Save editing of weight"
						onClick={handleSubmit(onSubmit)}
						isLoading={createAnimalWeightMutation.isPending}>
						<Icon
							name="save"
							width={'20px'}
							height={'20px'}
							color={colors.primary}
						/>
					</IconButton>
				) : (
					<IconButton
						aria-label="Edit note"
						onClick={() => {
							reset({ weight, note });
							setIsEditMode(true);
						}}>
						<Icon
							name="small-pen"
							width={'20px'}
							height={'20px'}
							color={colors.dark}
						/>
					</IconButton>
				)}
			</Td>
		</>
	);
};
