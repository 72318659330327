import { useQuery } from '@tanstack/react-query';
import API from '../../api/api.base';
import { QueryKeys } from '../../constants';
import { ApiResponse } from '../../types';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

export const useGetAvailableTestIntervals = (testId: number) => {
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useQuery<
		ApiResponse<{
			intervals: {
				from: string;
				to: string;
			}[];
		}>
	>({
		queryKey: [QueryKeys.GET_AVAILABLE_INTERVALS, testId],
		queryFn: () =>
			API.get(
				`/api/ranchos/${ranchoId}/user-pre-survey/available-intervals`,
				{ testCycleId: testId },
			),
	});
};
