import React from 'react';
import { Tr, Td } from '@chakra-ui/react';

interface ITestFeedRationModalRowProps {
	asFedPercent: number;
	dryMatter: number;
	ingredient: string;
	note: string;
}

export const TestFeedRationModalRow: React.FC<ITestFeedRationModalRowProps> = ({
	asFedPercent,
	dryMatter,
	ingredient,
	note,
}) => {
	return (
		<Tr>
			<Td verticalAlign="top" style={{ fontSize: '12px' }}>
				{asFedPercent}
			</Td>
			<Td verticalAlign="top" style={{ fontSize: '12px' }}>
				{dryMatter}
			</Td>
			<Td verticalAlign="top" style={{ fontSize: '12px' }}>
				{ingredient}
			</Td>
			<Td minW="170px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{note}
			</Td>
		</Tr>
	);
};
