import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { QueryKeys } from '../../constants';
import { ApiResponse, AnimalsTest } from '../../types';
import apiBase from '../../api/api.base';

const pageSize = 15;

interface IPayload {
	search?: string;
	currentPage: number;
}

export const useGetTestCyclesAnimals = ({ search, currentPage }: IPayload) => {
	const { id: testCycleId } = useParams();

	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);

	return useQuery({
		queryKey: [
			QueryKeys.GET_TEST_CYCLES_ANIMALS,
			search,
			currentPage,
			testCycleId,
		],
		queryFn: (): Promise<ApiResponse<AnimalsTest>> =>
			apiBase.get(
				`/api/ranchos/${ranchoId}/test-cycles/${testCycleId}/animals`,
				{
					pageSize,
					currentPage,
					search,
				},
			),
		enabled: !!testCycleId,
	});
};
