export const ReportsFinalAnalysisTableHeaders = [
	{
		label: 'Tag #',
		secondRow: '',
		includeRightBorder: false,
	},
	{
		label: 'EID',
		secondRow: '',
		includeRightBorder: true,
	},
	{
		label: '# of sick days',
		secondRow: 'during the test',
		includeRightBorder: false,
	},
	{
		label: 'Test',
		secondRow: 'Duration',
		includeRightBorder: false,
	},
	{
		label: 'Average',
		secondRow: 'Daily Gain',
		includeRightBorder: false,
	},
	{
		label: 'ADG',
		secondRow: 'Ratio',
		includeRightBorder: false,
	},
	{
		label: 'Test ADC lbs',
		secondRow: '(as fed)',
		includeRightBorder: false,
	},
	{
		label: 'Test ADC lbs',
		secondRow: '(DMI)',
		includeRightBorder: false,
	},
	{
		label: 'ADC (DMI)',
		secondRow: 'Ratio',
		includeRightBorder: false,
	},
	{
		label: 'Conversion',
		secondRow: 'DMI',
		includeRightBorder: false,
	},
	{
		label: 'AVG Daily COG',
		secondRow: 'lb of Gain',
		includeRightBorder: false,
	},
	{
		label: 'COG',
		secondRow: 'Ratio',
		includeRightBorder: false,
	},
];
