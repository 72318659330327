import { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ROUTE_PATH } from '../../../constants/route-paths';
import { useForm } from 'react-hook-form';
import {
	Box,
	Button,
	Divider,
	Flex,
	Link,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import { Icon, InputPassword, InputText } from '../../../components';
import { colors } from '../../../theme/colors';
import { EMAIL_VERIFICATION_TOKEN_LEN, inputStyles } from '../../../constants';
import { VerifyEmailModal } from '../../../modals';
import { useVerificationEmail } from '../../../query-hooks/auth/useVerificationEmail';
import { useSignUp, useVerificationEmailCheck } from '../../../query-hooks';

interface SignUpFormValues {
	username: string;
	email: string;
	password: string;
	confirmPassword: string;
	phone: string;
	isAgree: boolean;
	code: string;
}
const INITIAL_TIME_SEC = 20;
let INTERVAL: NodeJS.Timeout;

export const SignUp = () => {
	const [isTokenVerified, setIsTokenVerified] = useState(false);
	const [time, setTime] = useState(INITIAL_TIME_SEC);
	const [isActive, setIsActive] = useState(false);
	const { isOpen, onOpen, onClose } = useDisclosure();

	const {
		register,
		// control,
		handleSubmit,
		formState: { errors },
		reset,
		trigger,
		clearErrors,
		setError,
		watch,
	} = useForm<SignUpFormValues>();

	const watchFields = watch([
		'username',
		'email',
		'password',
		'confirmPassword',
		'phone',
	]);

	const tokenWatch = watch('code');

	const validateTokenForSignUp = async (): Promise<boolean> => {
		if (tokenWatch?.length === EMAIL_VERIFICATION_TOKEN_LEN) {
			const isTokenValid = await useVerificationEmailCheck({
				verificationCode: watch('code'),
				email: watch('email'),
			});
			if (isTokenValid) {
				clearErrors('code');
				setIsTokenVerified(true);
				return true;
			}
			setError('code', {
				message:
					'The verification code you entered is invalid. Please try again.',
			});
			setIsTokenVerified(false);
			return false;
		}
		setIsTokenVerified(false);
		return false;
	};

	const signUp = useSignUp();
	const onSubmit = async (values: SignUpFormValues) => {
		const isTokenValid = await validateTokenForSignUp();

		if (isTokenValid) {
			clearErrors('code');
			signUp.mutate(
				{
					userName: values.username,
					email: values.email,
					password: values.password,
					phoneNumber: values.phone,
					verificationCode: values.code,
				},
				{
					onSuccess: data => {
						if (data.success) {
							reset();
						}
					},
				},
			);
		}
	};

	const sendCode = useVerificationEmail();

	const sendVerificationCode = async () => {
		const email = watch('email');
		const username = watch('username');

		const isEmailValid = await trigger('email');
		const isUsernameValid = await trigger('username');

		if (isEmailValid && isUsernameValid) {
			clearErrors('email');
			clearErrors('username');
			sendCode.mutate(
				{ email: email, userName: username },
				{
					onSuccess(response) {
						if (response.success) {
							onOpen();
						}
					},
				},
			);
			return true;
		}
		if (!isEmailValid) {
			setError('email', { message: 'Invalid email' });
		}
		if (!isUsernameValid) {
			setError('username', { message: 'Invalid username' });
		}
		return false;
	};

	const startTimer = () => {
		setIsActive(true);
		setTime(INITIAL_TIME_SEC);
	};

	useEffect(() => {
		if (isActive && time > 0) {
			INTERVAL = setInterval(() => {
				setTime(prevTime => {
					return prevTime - 1;
				});
			}, 1000);
		} else if (time === 0) {
			clearInterval(INTERVAL);
			setIsActive(false);
		}

		return () => clearInterval(INTERVAL);
	}, [isActive, time]);

	useEffect(() => {
		validateTokenForSignUp();
	}, [tokenWatch]);

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Flex
				flexDirection={'column'}
				alignItems={'center'}
				justifyContent={'center'}
				gap={'40px'}
				width={'422px'}>
				<Flex
					flexDirection={'column'}
					alignItems={'center'}
					gap={'16px'}>
					<Text color={'darker'} variant={'B04'}>
						Create new account
					</Text>
					<Text color={'darker'} variant={'B02'}>
						Sign up an account
					</Text>
				</Flex>
				<VStack spacing={'24px'} width={'100%'}>
					<InputText
						{...register('username', {
							required: 'Username is required',
						})}
						errorMsg={errors.username?.message as string}
						label={'Your username'}
						variant={'flushed'}
						placeholder={'Enter your username'}
						autoComplete={''}
					/>
					<InputText
						{...register('email', {
							required: 'Email is required',
							pattern: {
								value: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
								message: 'Invalid email address',
							},
						})}
						errorMsg={errors.email?.message as string}
						label={'Your email'}
						variant={'flushed'}
						placeholder={'Enter your email'}
						autoComplete={'email'}
					/>
					<Flex width={'100%'}>
						<InputText
							label={'Verification code'}
							variant={'flushed'}
							type="number"
							placeholder={'Enter the verification code'}
							errorMsg={errors.phone?.message as string}
							autoComplete={''}
							{...register('code', {
								pattern: {
									value: /^[0-9]+$/,
									message:
										'Verification code must be digits only',
								},
							})}
						/>
						<Flex
							h={'41px'}
							mt={'20px'}
							backgroundColor={'secondary'}
							color={'primary'}
							alignItems={'center'}
							minWidth={'124px'}
							borderBottom={`1px solid ${colors.dark}`}>
							<>
								<Divider
									orientation="vertical"
									borderColor={'dark'}
									height={'41px'}
								/>
								{isTokenVerified ? (
									<Box m={'0 auto'}>
										<Icon
											name="check"
											width="19px"
											height="19px"
										/>
									</Box>
								) : (
									<>
										{isActive ? (
											<Text
												textAlign={'center'}
												minWidth={'124px'}
												variant={'B02'}>
												{`${time}s resend`}
											</Text>
										) : (
											<Button
												{...inputStyles}
												m={'0 auto'}
												borderBottom={'none'}
												color={'primary'}
												onClick={async () => {
													const shouldStartTimer =
														await sendVerificationCode();
													if (shouldStartTimer) {
														startTimer();
													}
												}}>
												Verify
											</Button>
										)}
									</>
								)}
							</>
						</Flex>
					</Flex>
					<InputPassword
						label={'Your password'}
						variant={'flushed'}
						placeholder={'Enter your password'}
						showRules={false}
						errorMsg={errors.password?.message as string}
						{...register('password', {
							required: 'Password is required',
						})}
					/>
					<InputPassword
						label={'Confirm password'}
						variant={'flushed'}
						placeholder={'Enter your password'}
						showRules={false}
						errorMsg={errors.confirmPassword?.message as string}
						{...register('confirmPassword', {
							required: 'Confirm password is required',
							validate: value =>
								value === watchFields[2] ||
								'Passwords do not match',
						})}
					/>
					<InputText
						label={'Your phone number'}
						type="number"
						variant={'flushed'}
						placeholder={'Enter your phone number'}
						errorMsg={errors.phone?.message as string}
						autoComplete={'tel'}
						{...register('phone')}
					/>
				</VStack>
				{/* <Flex justifyContent={'start'} width={'100%'} gap={'8px'}>
					<Controller
						name="isAgree"
						control={control}
						render={({
							field: { ref, onBlur, onChange, value },
						}) => (
							<CustomCheckbox
								ref={ref}
								isChecked={value}
								onBlur={onBlur}
								onChange={e => onChange(e.target.checked)}
							/>
						)}
					/>
					<Text
						color={'dark'}
						width={'100%'}
						fontSize={'12px'}
						fontFamily={'Roboto'}>
						I’ve read and agreed to{' '}
						<Link
							as={RouterLink}
							to={'#'}
							color={`${colors.primary}`}
							_hover={{ textDecoration: 'none' }}>
							User Agreement
						</Link>{' '}
						and{' '}
						<Link
							as={RouterLink}
							to={'#'}
							_hover={{ textDecoration: 'none' }}
							color={`${colors.primary}`}>
							Privacy Policy
						</Link>
					</Text>
				</Flex> */}
				<Button variant={'primary'} type={'submit'}>
					Sign Up
				</Button>
				<Flex gap={'8px'}>
					<Text color={'dark'} fontFamily={'Roboto'}>
						Already have an account?
					</Text>
					<Link
						as={RouterLink}
						to={ROUTE_PATH.sign_in}
						_hover={{ textDecoration: 'none' }}>
						<Text fontWeight={'700'}>Log in</Text>
					</Link>
				</Flex>
				<VerifyEmailModal isOpen={isOpen} onClose={onClose} />
			</Flex>
		</form>
	);
};
