import React, { useEffect, useRef, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	Input,
	SliderTrack,
	SliderFilledTrack,
	SliderThumb,
	Slider,
} from '@chakra-ui/react';
import { Icon } from '../../components';
import AvatarEditor from 'react-avatar-editor';

interface ChangeAvatarModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSave: (avatarUrl: string, file: File) => void;
	avatarUrl: string;
}

export const ChangeAvatarModal: React.FC<ChangeAvatarModalProps> = ({
	isOpen,
	onClose,
	onSave,
	avatarUrl,
}) => {
	const [selectedImage, setSelectedImage] = useState<string | null>(
		avatarUrl,
	);
	const [uploadedImage, setUploadedImage] = useState<File | null>(null);
	const [rotation, setRotation] = useState<number>(0);
	const [scale, setScale] = useState<number>(1);
	const editorRef = useRef<AvatarEditor>(null);

	const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setSelectedImage(reader.result as string);
				setUploadedImage(file);
			};
			reader.readAsDataURL(file);
		}
	};

	const rotateLeft = () => {
		setRotation(prev => prev - 90);
	};

	const rotateRight = () => {
		setRotation(prev => prev + 90);
	};

	const handleSave = () => {
		if (editorRef.current && uploadedImage) {
			const canvas = editorRef.current.getImageScaledToCanvas();
			canvas.toBlob(blob => {
				if (blob) {
					const croppedFile = new File([blob], uploadedImage.name, {
						type: uploadedImage.type,
					});
					const croppedImageUrl = URL.createObjectURL(croppedFile);
					onSave(croppedImageUrl, croppedFile);
					setUploadedImage(null);
					setRotation(0);
					setScale(1);
				}
			});
		}
		onClose();
	};

	useEffect(() => {
		if (avatarUrl) {
			setSelectedImage(avatarUrl);
		}
	}, [avatarUrl]);

	return (
		<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent minH="650px" minW="515px" borderRadius="24px">
				<ModalHeader
					display="flex"
					justifyContent="space-between"
					p="32px 32px 24px"
					borderBottom="1px solid #ECECEC">
					<Text variant="B03">Profile photo change:</Text>
					<Box onClick={onClose} cursor="pointer">
						<Icon name="close" width="24px" height="24px" />
					</Box>
				</ModalHeader>
				<Flex flexDirection="column" gap="32px" padding="32px">
					<Button as="label" variant="secondary">
						Upload photo
						<Box ml="8px">
							<Icon name="upload" width="24px" height="24px" />
						</Box>
						<Input
							type="file"
							accept="image/*"
							display="none"
							onChange={handleImageUpload}
						/>
					</Button>
					<Flex
						position="relative"
						backgroundColor="primary"
						borderRadius="6px"
						alignItems="center"
						justifyContent="center"
						overflow="hidden">
						{selectedImage && (
							<AvatarEditor
								ref={editorRef}
								image={selectedImage}
								width={200}
								height={200}
								border={50}
								borderRadius={200}
								color={[255, 255, 255, 0.6]}
								scale={scale}
								rotate={rotation}
							/>
						)}
					</Flex>
					<Flex gap="13px" paddingX="7px" alignItems="center">
						<Icon
							name="picture"
							width="32px"
							height="32px"
							strokeColor="none"
						/>
						<Slider
							defaultValue={1}
							min={0.8}
							max={2}
							step={0.1}
							value={scale}
							onChange={val => setScale(val)}>
							<SliderTrack
								bg="gray"
								height="3px"
								borderRadius="5px">
								<SliderFilledTrack bg="primary" />
							</SliderTrack>
							<SliderThumb
								boxSize={6}
								bg="primary"
								borderRadius="50%"
							/>
						</Slider>
						<Icon
							name="picture"
							width="40px"
							height="40px"
							strokeColor="none"
						/>
					</Flex>
					<Flex gap="64px" justifyContent="center">
						<Box cursor="pointer" onClick={rotateLeft}>
							<Icon
								name="left-refresh"
								width="40px"
								height="40px"
							/>
						</Box>
						<Box cursor="pointer" onClick={rotateRight}>
							<Icon
								name="right-refresh"
								width="40px"
								height="40px"
							/>
						</Box>
					</Flex>
					<Flex display="flex" gap="32px" p="0">
						<Button variant="secondary" onClick={onClose}>
							Cancel
						</Button>
						<Button
							variant="primary"
							onClick={handleSave}
							isDisabled={!uploadedImage}>
							Save
						</Button>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	);
};
