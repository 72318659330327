import React from 'react';
import { Th } from '@chakra-ui/react';
import { nowrapTableHeadersStyles } from '../../../Report/constants';

interface IWeightSessionHeaderProps {
	index: number;
}

export const WeightSessionHeader: React.FC<IWeightSessionHeaderProps> = ({
	index,
}) => {
	return (
		<>
			<Th minW="85px" style={nowrapTableHeadersStyles}>
				Test Weight {index}
			</Th>
			<Th minW="100px" style={nowrapTableHeadersStyles}>
				Wt Date {index}
			</Th>
			<Th minW="160px" style={nowrapTableHeadersStyles}>
				Notes {index}
			</Th>
			<Th borderRightColor="lightGray" borderRightWidth="1px">
				Actions
			</Th>
		</>
	);
};
