import { TestCycleStatus } from './enum';

export type TestCycleItem = {
	id: number;
	name: string;
	testGroup: string;
	customerName: string;
	customerId: string;
	status: TestCycleStatus;
	isTestRun: boolean;
};

export type TestCycles = {
	count: number;
	items: TestCycleItem[];
};

export type Survey = {
	customerName: string;
	representative: string;
	customerId: string;
	premiseId: string;
	premiseState: string;
	testNumber: number;
	testGroup: string;
	testHead: number;
	breed: string;
	weightStartLow: number;
	weightStartHigh: number;
	vGate: number;
	turnInDate: string;
	testStart: string;
	testFinish: string;
	testDietCost: number;
	testDryMatter: number;
	neg: number;
	nem: number;
	cleanOutEidTagFirst: string;
	cleanOutEidTagSecond: string;
};

export type TestCyclesPayload = {
	settingId: number;
	survey: Survey;
};

export enum TestRunningStatusChangeApiOperation {
	ACTIVATE = 'activate',
	DEACTIVATE = 'deactivate',
}

export interface ChangeTestCycleRunningStatusPayload {
	testCycleId: number;
	apiOperation: TestRunningStatusChangeApiOperation;
}
