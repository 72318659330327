import { useMutation } from '@tanstack/react-query';
import { ApiResponseEmpty } from '../../types';
import apiBase from '../../api/api.base';
import { Alerter } from '../../utils';

interface UseChangePasswordProps {
	successCb: VoidFunction;
}

export const useChangePassword = ({ successCb }: UseChangePasswordProps) => {
	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ oldPassword: string; newPassword: string }
	>({
		mutationFn: payload => apiBase.post('/api/user/password', payload),
		onSuccess: response => {
			if (response.success) {
				Alerter.success('Password changed.');
				successCb?.();
				return;
			} else {
				Alerter.error(response?.errors?.[0]?.message);
			}
		},
	});
};
