import React, { useState } from 'react';
import { Tr, Td, Input, IconButton, HStack } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { inputStyles } from '../../../../constants';
import {
	Icon,
	DeleteConfirmPopup,
	CustomTextarea,
} from '../../../../components';
import { colors } from '../../../../theme/colors';
import { CreateFeedRationPayload } from '../../../../types';
import {
	useUpdateFeedRation,
	useDeleteFeedRation,
} from '../../../../query-hooks';

interface IRationRowProps {
	asFedPercent: number;
	dryMatter: number;
	ingredient: string;
	note: string;
	id: number;
}

export const RationRow: React.FC<IRationRowProps> = ({
	asFedPercent,
	dryMatter,
	id,
	ingredient,
	note,
}) => {
	const [isEditMode, setIsEditMode] = useState(false);

	const { register, control, handleSubmit, reset } =
		useForm<CreateFeedRationPayload>();

	const updateFeedRationMutation = useUpdateFeedRation(id);
	const deleteFeedRationMutation = useDeleteFeedRation(id);

	const onSubmit = (data: CreateFeedRationPayload) => {
		updateFeedRationMutation.mutate(data, {
			onSuccess(data) {
				if (data.success) {
					setIsEditMode(false);
				}
			},
		});
	};

	const onDelete = () => {
		deleteFeedRationMutation.mutate();
	};

	return (
		<Tr>
			<Td minW="170px" verticalAlign="top">
				{isEditMode ? (
					<Input
						{...register('asFedPercent')}
						{...inputStyles}
						h={'24px'}
						fontSize="12px"
						lineHeight="14px"
						padding="4px 8px"
						type="number"
						maxW={'170px'}
						_focusVisible={{
							border: 'none',
							borderBottom: `1px solid ${colors.dark}`,
						}}
					/>
				) : (
					asFedPercent
				)}
			</Td>
			<Td minW="170px" verticalAlign="top">
				{isEditMode ? (
					<Input
						{...register('dryMatter')}
						{...inputStyles}
						h={'24px'}
						type="number"
						fontSize="12px"
						lineHeight="14px"
						padding="4px 8px"
						maxW={'170px'}
						_focusVisible={{
							border: 'none',
							borderBottom: `1px solid ${colors.dark}`,
						}}
					/>
				) : (
					dryMatter
				)}
			</Td>
			<Td minW="170px" verticalAlign="top">
				{isEditMode ? (
					<Controller
						name="ingredient"
						control={control}
						defaultValue=""
						render={({ field: { onChange, value } }) => (
							<CustomTextarea
								minHeight={24}
								padding="4px 8px"
								value={value}
								fontSize="12px"
								lineHeight="14px"
								onChange={onChange}
							/>
						)}
					/>
				) : (
					ingredient
				)}
			</Td>
			<Td minW="170px" verticalAlign="top">
				{isEditMode ? (
					<Controller
						name="note"
						control={control}
						defaultValue=""
						render={({ field: { onChange, value } }) => (
							<CustomTextarea
								minHeight={24}
								padding="4px 8px"
								value={value}
								fontSize="12px"
								lineHeight="14px"
								onChange={onChange}
							/>
						)}
					/>
				) : (
					note
				)}
			</Td>
			<Td verticalAlign="top" style={{ width: '90px' }}>
				<HStack justify="space-between" maxW="65px" mx="auto">
					{isEditMode ? (
						<IconButton
							aria-label="Save editing of ration"
							w="20px"
							h="20px"
							minW="20px"
							onClick={handleSubmit(onSubmit)}
							isLoading={updateFeedRationMutation.isPending}>
							<Icon
								name="save"
								width={'20px'}
								height={'20px'}
								color={colors.primary}
							/>
						</IconButton>
					) : (
						<IconButton
							aria-label="Edit ration"
							w="20px"
							h="20px"
							minW="20px"
							onClick={() => {
								reset({
									asFedPercent,
									dryMatter,
									ingredient,
									note,
								});
								setIsEditMode(true);
							}}>
							<Icon
								name="small-pen"
								width={'20px'}
								height={'20px'}
								color={colors.dark}
							/>
						</IconButton>
					)}
					<DeleteConfirmPopup
						title="Are you sure you want to delete that ration?"
						onConfirm={onDelete}
						confirmButtonProps={{
							isLoading: deleteFeedRationMutation.isPending,
						}}>
						<IconButton
							padding={0}
							aria-label="Delete note"
							w="20px"
							h="20px"
							minW="20px">
							<Icon
								name="trash"
								width={'20px'}
								height={'20px'}
								color={colors.error}
							/>
						</IconButton>
					</DeleteConfirmPopup>
				</HStack>
			</Td>
		</Tr>
	);
};
