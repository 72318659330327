import { useQuery } from '@tanstack/react-query';
import { ApiResponse, CurrentUser } from '../../types';

import { QueryKeys } from '../../constants';
import apiBase from '../../api/api.base';

export const useGetUsersDetails = () => {
	return useQuery({
		queryKey: [QueryKeys.GET_USERS_DETAILS],
		queryFn: (): Promise<ApiResponse<CurrentUser>> =>
			apiBase.get('/api/users', {}),
	});
};
