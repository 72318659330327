import { useMutation } from '@tanstack/react-query';
import { ApiResponseEmpty } from '../../types';
import { Alerter } from '../../utils';
import apiBase from '../../api/api.base';

export const useForgotPasswordEmailSend = () => {
	return useMutation<
		ApiResponseEmpty,
		unknown,
		{
			email: string;
		}
	>({
		mutationFn: payload =>
			apiBase.post('/api/auth/forgot-password/email/send', payload),
		onError: () => {
			Alerter.error('Something went wrong');
		},
	});
};
