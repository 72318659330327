export enum AnimalStatus {
	Active = 1,
	Inactive = 2,
	Archived = 3,
}
export enum ContemporaryGroup {
	None = 1,
	Natural = 2,
	Artificial = 3,
	Pasture = 4,
	Feedlot = 5,
	Organic = 6,
}
export enum DnaStatus {
	Yes = 1,
	No = 2,
}
export enum GenderStatus {
	Bull = 1,
	Cow = 2,
}
export enum TestCycleStatus {
	None = 1,
	Pending = 2,
	Active = 3,
	Completed = 4,
}
