export const onlyDigits = (str: string) => str.replace(/[^\d]/g, '');

export const onlyDigitsAndDot = (input: string): string => {
	const filteredValue = input.replace(/[^0-9.]/g, '');

	const dotIndex = filteredValue.indexOf('.');

	if (dotIndex !== -1) {
		const integerPart = filteredValue.slice(0, dotIndex + 1);
		const fractionalPart = filteredValue
			.slice(dotIndex + 1)
			.replace(/\./g, '');
		const result = integerPart + fractionalPart;
		return result;
	}

	return filteredValue;
};
