import React, { PropsWithChildren, useMemo } from 'react';
import { Box, Table, TableContainer, Tbody, Thead } from '@chakra-ui/react';
import { useGetReportAnimalWeight } from '../../../../query-hooks';
import { Loader } from '../../../../components';
import { EmptyDataMessage } from '../../components';
import { TableHeaders, TableRow } from './components';

export const AnimalWeights: React.FC<PropsWithChildren> = () => {
	const { data, isLoading } = useGetReportAnimalWeight();

	const extractedWeightSessionHeaders = useMemo(
		() => data?.value?.weightSessions?.map(el => el.sessionName) || [],
		[data?.value?.weightSessions],
	);

	if (isLoading) {
		return <Loader centerHeight="calc(100vh - 320px)" />;
	}

	if (!isLoading && !data?.value?.animals?.length) {
		return <EmptyDataMessage />;
	}

	return (
		<Box
			backgroundColor="light"
			boxShadow="0px 0px 15px rgba(0, 0, 0, 0.10)"
			borderRadius="12px"
			padding="16px">
			<TableContainer h="calc(100vh - 320px)" bg="white" overflowY="auto">
				<Table
					w="100%"
					minW="200px"
					variant="custom"
					sx={{
						'tr > th, tr > td': {
							backgroundColor: 'white',
						},
						'tr:first-of-type > th:first-of-type, tr:first-of-type > th:nth-of-type(2)':
							{
								backgroundColor: 'light',
							},
						'tr > th:first-of-type, tr > td:first-of-type': {
							position: 'sticky',
							left: 0,
						},
						'tr > th:nth-of-type(2), tr > td:nth-of-type(2)': {
							position: 'sticky',
							left: '114px',
						},
					}}>
					<Thead>
						<TableHeaders
							extraHeaders={extractedWeightSessionHeaders}
						/>
					</Thead>
					<Tbody>
						{data?.value?.animals?.map((el, index) => (
							<TableRow
								key={el.animalInTestId}
								weightSessions={data?.value?.weightSessions}
								arrIndex={index}
								{...el}
							/>
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</Box>
	);
};
