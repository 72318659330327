import React from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface IRationMetadataProps {
	totalFedPercent?: number;
	totalDryMatter?: number;
}

export const RationMetadata: React.FC<IRationMetadataProps> = ({
	totalDryMatter,
	totalFedPercent,
}) => {
	return (
		<Flex flexDirection={'column'} justifyContent={'flex-end'} flexGrow={1}>
			<Flex gap={'10px'} align="center" pt={4}>
				<Text color={'dark'} fontWeight="bold" textAlign={'right'}>
					Total
				</Text>
				<Text
					color={'dark'}
					minW={'120px'}
					textAlign={'center'}
					backgroundColor={
						(totalFedPercent || 0) > 1 ? 'yellow' : 'secondary'
					}>
					{totalFedPercent?.toFixed(2)}
				</Text>
				<Text
					color={'dark'}
					minW={'120px'}
					textAlign={'center'}
					backgroundColor={
						(totalDryMatter || 0) > 1 ? 'yellow' : 'secondary'
					}>
					{totalDryMatter?.toFixed(2)}
				</Text>
			</Flex>
		</Flex>
	);
};
