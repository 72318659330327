import React, { PropsWithChildren, useState } from 'react';
import {
	Box,
	Table,
	TableContainer,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { TableRow } from './components';
import { useGetReportAnimalInformation } from '../../../../query-hooks';
import { Loader, Pagination } from '../../../../components';
import { ReportsAnimalInfoTableHeaders } from './constants';
import { EmptyDataMessage } from '../../components';
import { trickyTableDividerStyles } from '../../../../constants';

export const AnimalInfo: React.FC<PropsWithChildren> = () => {
	const [page, setPage] = useState(1);

	const { data, isLoading } = useGetReportAnimalInformation({
		currentPage: page,
	});

	if (isLoading) {
		return <Loader centerHeight="calc(100vh - 350px)" />;
	}

	if (!isLoading && !data?.value?.animals?.length) {
		return <EmptyDataMessage />;
	}

	const totalCount = data?.value?.totalCount || 0;
	return (
		<React.Fragment>
			<Box
				backgroundColor="light"
				boxShadow="0px 0px 15px rgba(0, 0, 0, 0.10)"
				borderRadius="12px"
				padding="16px">
				<TableContainer
					h={
						totalCount > 10
							? 'calc(100vh - 350px)'
							: '"calc(100vh - 320px)"'
					}
					maxH="530px"
					bg="white"
					overflowY="auto">
					<Table
						w="100%"
						minW="200px"
						variant="custom"
						sx={{
							'tr > th, tr > td': {
								backgroundColor: 'white',
							},
							'tr > th:first-of-type, tr > td:first-of-type': {
								position: 'sticky',
								left: 0,
							},
							'tr > th:nth-of-type(2), tr > td:nth-of-type(2)': {
								position: 'sticky',
								left: '124px',
							},
						}}>
						<Thead>
							<Tr>
								{ReportsAnimalInfoTableHeaders.map(
									(el, index) => (
										<Th key={index}>
											<Text
												textAlign="center"
												color="darker"
												whiteSpace="nowrap"
												fontWeight={700}>
												{el}
											</Text>
											{index === 1 ? (
												<Box
													{...trickyTableDividerStyles}
												/>
											) : null}
										</Th>
									),
								)}
							</Tr>
						</Thead>
						<Tbody>
							{data?.value?.animals?.map((el, index) => (
								<TableRow key={index} {...el} />
							))}
						</Tbody>
					</Table>
				</TableContainer>
			</Box>
			<Pagination
				totalCount={totalCount}
				pageSize={10}
				currentPage={page}
				onPageChange={page => setPage(page)}
				mt={4}
			/>
		</React.Fragment>
	);
};
