import React from 'react';
import {
	Button,
	ButtonProps,
	FormControl,
	FormControlProps,
	FormLabel,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	MenuListProps,
	Portal,
} from '@chakra-ui/react';
import { labelStyles, selectStyles } from '../../constants';
import { ErrorMessage } from '../InputText/supplements';
import { Icon } from '../Icon/Icon';
import { colors } from '../../theme/colors';
import { SelectOption } from '../../types';
import './style.css';
interface ISelectProps {
	label?: string;
	options: SelectOption[];
	errorMsg?: string;
	formControlProps?: FormControlProps;
	placeholder: string;
	value?: string | null;
	onChange?: (value: string) => void;
	maxH?: string;
	w?: string;
	zIndex?: number;
	triggerProps?: ButtonProps;
	menuListProps?: MenuListProps;
}

export const CustomSelect: React.FC<ISelectProps> = ({
	errorMsg,
	label,
	formControlProps,
	options,
	placeholder,
	value,
	maxH,
	zIndex = 2,
	onChange,
	triggerProps,
	menuListProps,
}) => {
	return (
		<FormControl
			isInvalid={!!errorMsg}
			_readOnly={{
				'> label': {
					color: 'darkLight',
				},
			}}
			{...formControlProps}>
			{label ? <FormLabel {...labelStyles}>{label}</FormLabel> : null}
			<Menu placement="bottom">
				<MenuButton
					as={Button}
					rightIcon={
						<Icon
							name="down-arrow"
							width={'12px'}
							height={'12px'}
							color={`${colors.primary}`}
						/>
					}
					width={'100%'}
					textAlign="left"
					color={value ? 'dark' : 'gray'}
					fontWeight={value ? 400 : 500}
					{...selectStyles}
					height={'inherit'}
					zIndex={zIndex}
					{...triggerProps}>
					<p className={'ellipsis-paragraph'}>
						{value
							? options.find(option => option.value === value)
								?.label
							: placeholder}
					</p>
				</MenuButton>
				<MenuList
					maxH={maxH}
					overflow={'auto'}
					height={'inherit'}
					bg="white"
					minW={'422px'}
					border="1px solid #175D00"
					px={'8px'}
					zIndex={zIndex}
					{...menuListProps}>
					{options.map((option, index) => (
						<MenuItem
							key={index}
							value={option.value}
							onClick={() => onChange?.(option.value)}
							bg={value === option.value ? '#175d008b' : 'white'}
							borderRadius={
								value === option.value ? '4px' : 'none'
							}
							color={value === option.value ? 'white' : 'dark'}
							_notLast={{
								marginBottom: '8px',
							}}
							_hover={{
								bg: '#175d008b',
								borderRadius: '4px',
								color: 'white',
							}}>
							{option.label}
						</MenuItem>
					))}
				</MenuList>
			</Menu>
			<ErrorMessage>{errorMsg}</ErrorMessage>
		</FormControl>
	);
};
