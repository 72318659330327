import { BoxProps } from '@chakra-ui/react';

export const trickyTableDividerStyles: BoxProps = {
	position: 'absolute',
	w: '1px',
	h: 'calc(100% + 2px)',
	bg: 'primary2',
	right: '0',
	top: '-1px',
};
