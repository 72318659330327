import { useQuery } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { useParams } from 'react-router-dom';
import { QueryKeys } from '../../constants';
import { ApiResponse, AnimalTest } from '../../types';
import apiBase from '../../api/api.base';

interface IPayload {
	animalInTestCycleId: number;
	isEnabled?: boolean;
}

export const useGetTestCyclesAnimal = ({
	animalInTestCycleId,
	isEnabled,
}: IPayload) => {
	const { id: testCycleId } = useParams();
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);

	return useQuery<ApiResponse<AnimalTest>>({
		queryKey: [
			QueryKeys.GET_TEST_CYCLES_ANIMAL,
			testCycleId,
			animalInTestCycleId,
		],
		queryFn: () =>
			apiBase.get(
				`/api/ranchos/${ranchoId}/test-cycles/${testCycleId}/animals/${animalInTestCycleId}/get-info`,
				{},
			),
		enabled: isEnabled,
	});
};
