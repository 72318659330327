import React, { PropsWithChildren } from 'react';
import { Flex, Image, Text } from '@chakra-ui/react';
import logo from '../assets/images/logo.svg';
import { BinSettings, Icon } from '../components';
import { getDateByFormat } from '../utils';

export const HeaderLayout: React.FC<PropsWithChildren> = () => {
	return (
		<Flex
			h={'77px'}
			w={'100%'}
			padding={'16px 40px'}
			boxShadow={'0px 1px 4px 0px rgba(0, 0, 0, 0.25)'}>
			<Flex gap={'96px'} alignItems={'center'} w={'100%'}>
				<Image src={logo} alt={''} w={'64px'} h={'45px'} />
			</Flex>
			<Flex
				alignItems={'center'}
				justifyContent={'flex-end'}
				gap={'40px'}>
				<BinSettings />
				<Flex gap={'8px'} alignItems={'center'}>
					<Icon
						name={'calendar'}
						width="16px"
						height="16px"
						color={'dark'}
					/>

					<Text color={'dark'} sx={{ textWrap: 'nowrap' }}>
						{getDateByFormat(new Date(), 'ddd, MMMM Do')}
					</Text>
				</Flex>
			</Flex>
		</Flex>
	);
};
