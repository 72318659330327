import React from 'react';
import { Tr, Td, Text, Box } from '@chakra-ui/react';
import {
	ReportsAnimalTestItemType,
	ReportsAnimalWeightSessionItemType,
} from '../../../../../types';
import { getDateByFormat } from '../../../../../utils';
import { trickyTableDividerStyles } from '../../../../../constants';

interface ITableRowProps extends ReportsAnimalTestItemType {
	weightSessions: ReportsAnimalWeightSessionItemType[];
	arrIndex: number;
}

export const TableRow: React.FC<ITableRowProps> = ({
	weightSessions,
	arrIndex,
	eid,
	ranchTag,
}) => {
	return (
		<Tr>
			<Td p="8px">
				<Text color="darker" textAlign="center" minW="90px">
					{ranchTag}
				</Text>
			</Td>
			<Td p="8px" position="relative">
				<Text color="darker" textAlign="center" minW="90px">
					{eid || ''}
				</Text>
				<Box {...trickyTableDividerStyles} />
			</Td>
			{weightSessions?.map((el, index) => (
				<React.Fragment key={index}>
					<Td p="8px">
						<Text color="darker" textAlign="center" w="80px">
							{el.weights?.[arrIndex]?.weight || ''}
						</Text>
					</Td>
					<Td p="8px">
						<Text color="darker" textAlign="center">
							{getDateByFormat(el.date)}
						</Text>
					</Td>
					<Td
						p="8px"
						borderRightWidth="1px"
						borderRightColor="lightGray">
						<Text
							color="darker"
							textAlign="center"
							w="full"
							maxW="160px"
							isTruncated={true}>
							{el.weights?.[arrIndex]?.note || ''}
						</Text>
					</Td>
				</React.Fragment>
			))}
		</Tr>
	);
};
