export const handleAxiosError = (err: any) => {
	if (err && err.response && err?.response?.data) {
		return { data: err.response.data, success: false };
	} else {
		return {
			data: {
				success: false,
				status: err?.response?.status,
				errors: [
					{
						key: 'Request error',
						message: 'Request error',
					},
				],
			},
		};
	}
};
