import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { QueryKeys } from '../../constants';
import { ApiResponse } from '../../types';
import apiBase from '../../api/api.base';
import { Alerter } from '../../utils';
import { useParams } from 'react-router-dom';

export const useCreateTestCyclesAnimal = () => {
	const queryClient = useQueryClient();
	const { id } = useParams();

	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);

	return useMutation<ApiResponse<number>, unknown, { animalId: number }>({
		mutationFn: payload =>
			apiBase.post(
				`/api/ranchos/${ranchoId}/test-cycles/${id}/animals`,
				payload,
			),
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors?.[0]?.message);
				return;
			}
			Alerter.success(
				data.messages?.[0]?.message ||
					'Animal information was successfully added',
			);
			queryClient.invalidateQueries({
				queryKey: [QueryKeys.GET_TEST_CYCLES_ANIMALS],
			});
		},
	});
};
