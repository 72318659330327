import React from 'react';
import {
	Box,
	Button,
	Divider,
	Flex,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { colors } from '../../../theme/colors';
import { useGetTestCyclePreSurvey } from '../../../query-hooks/pre-survey/useGetTestCyclePreSurvey';
import { getDateByFormat } from '../../../utils';
import { useGetBinSettings } from '../../../query-hooks';
import { Icon } from '../../../components';
import {
	wrapperBox,
	labelStyles,
	preSurveyTextStyles,
} from './testDetails.styles';

interface TestDetailsModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const TestDetailsModal: React.FC<TestDetailsModalProps> = ({
	isOpen,
	onClose,
}) => {
	const { data: binSettings } = useGetBinSettings();
	const { data } = useGetTestCyclePreSurvey();
	const testData = data?.value;
	const binSetting = binSettings?.value?.find(
		el => el.id === testData?.settingId,
	)?.name;
	return (
		<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				maxH={'596px'}
				maxW={'1136px'}
				borderRadius={'24px'}
				p={'32px'}>
				<ModalHeader display={'flex'} justifyContent={'center'}>
					<Text variant={'B03'} color={'dark'} fontSize={'20px'}>
						Test Details
					</Text>
					<Button
						width={'40px'}
						height={'40px'}
						border={`1px solid ${colors.primary}`}
						p={'0px'}
						onClick={onClose}
						position={'absolute'}
						right={'16px'}
						borderRadius={'50%'}>
						<Icon
							name="close"
							color={colors.primary}
							width={'24px'}
							height={'24px'}
						/>
					</Button>
				</ModalHeader>
				<Flex flexDirection={'column'}>
					<Flex gap={'40px'} p={'32px'} paddingTop={'0px'}>
						<Flex
							flexBasis={'50%'}
							flexDirection={'column'}
							gap={'8px'}>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>Customer</Text>
								<Text {...preSurveyTextStyles}>
									{testData?.customerName}
								</Text>
							</Box>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>Representative</Text>
								<Text {...preSurveyTextStyles}>
									{testData?.representative}
								</Text>
							</Box>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>Customer Id</Text>
								<Text {...preSurveyTextStyles}>
									{testData?.customerId}
								</Text>
							</Box>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>Premise Id</Text>
								<Text {...preSurveyTextStyles}>
									{testData?.premiseId}
								</Text>
							</Box>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>Premise State</Text>
								<Text {...preSurveyTextStyles}>
									{testData?.premiseState}
								</Text>
							</Box>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>Test #</Text>
								<Text {...preSurveyTextStyles}>
									{testData?.testNumber}
								</Text>
							</Box>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>Test Group</Text>
								<Text {...preSurveyTextStyles}>
									{testData?.testGroup}
								</Text>
							</Box>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>Test Head #</Text>
								<Text {...preSurveyTextStyles}>
									{testData?.testHead}
								</Text>
							</Box>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>Bread</Text>
								<Text {...preSurveyTextStyles}>
									{testData?.breed}
								</Text>
							</Box>
							<Box {...wrapperBox} height={'52px'}>
								<Text {...labelStyles}>
									Weight Range (Start)
								</Text>
								<Flex
									flexDirection={'column'}
									gap={'8px'}
									width={'100%'}>
									<Flex alignItems={'center'}>
										<Text
											{...preSurveyTextStyles}
											w={'42px'}
											height={'22px'}>
											Low
										</Text>
										<Divider
											orientation="vertical"
											margin={'0px 8px 0px 8px'}
											height={'14px'}
										/>
										<Text
											{...preSurveyTextStyles}
											w={'34px'}
											textAlign={'center'}
											height={'22px'}>
											{testData?.weightStartLow}
										</Text>
										<Divider
											orientation="vertical"
											margin={'0px 8px 0px 8px'}
											height={'14px'}
										/>
									</Flex>
									<Flex alignItems={'center'}>
										<Text
											{...preSurveyTextStyles}
											w={'42px'}
											height={'22px'}>
											High
										</Text>
										<Divider
											orientation="vertical"
											margin={'0px 8px 0px 8px'}
											height={'14px'}
										/>
										<Text
											{...preSurveyTextStyles}
											w={'34px'}
											textAlign={'center'}
											height={'22px'}>
											{testData?.weightStartHigh}
										</Text>
										<Divider
											orientation="vertical"
											margin={'0px 8px 0px 8px'}
											height={'14px'}
										/>
									</Flex>
								</Flex>
							</Box>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>V-Gate setting</Text>
								<Flex
									alignItems={'center'}
									paddingLeft={'50px'}>
									<Divider
										orientation="vertical"
										margin={'0px 8px 0px 0px'}
										height={'14px'}
									/>
									<Text
										{...preSurveyTextStyles}
										w={'34px'}
										textAlign={'center'}>
										{testData?.vGate}
									</Text>
									<Divider
										orientation="vertical"
										margin={'0px 8px 0px 8px'}
										height={'14px'}
									/>
								</Flex>
							</Box>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>
									Turn-in Date (Warm-up)
								</Text>
								<Text {...preSurveyTextStyles}>
									{getDateByFormat(
										testData?.turnInDate,
										'MM/DD/YYYY hh a',
									)}
								</Text>
							</Box>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>Test Start</Text>
								<Text {...preSurveyTextStyles}>
									{getDateByFormat(testData?.testStart)}
								</Text>
							</Box>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>
									Test Finish (projected)
								</Text>
								<Text {...preSurveyTextStyles}>
									{getDateByFormat(testData?.testFinish)}
								</Text>
							</Box>
						</Flex>
						<Flex
							flexBasis={'50%'}
							flexDirection={'column'}
							gap={'8px'}>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>
									Test Diet cost/lb (AS FED)
								</Text>
								<Text
									{...preSurveyTextStyles}
									marginRight={'10px'}>
									{testData?.testDietCost}
								</Text>
								$
							</Box>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>Test Dry Matter</Text>
								<Text
									{...preSurveyTextStyles}
									marginRight={'10px'}>
									{testData?.testDryMatter}
								</Text>
								%
							</Box>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>NEg</Text>
								<Text {...preSurveyTextStyles}>
									{testData?.neg}
								</Text>
							</Box>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>NEm</Text>
								<Text {...preSurveyTextStyles}>
									{testData?.nem}
								</Text>
							</Box>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>
									Cleanout EID Tag #1
								</Text>
								<Text {...preSurveyTextStyles}>
									{testData?.cleanOutEidTagFirst}
								</Text>
							</Box>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>
									Cleanout EID Tag #2
								</Text>
								<Text {...preSurveyTextStyles}>
									{testData?.cleanOutEidTagSecond}
								</Text>
							</Box>
							<Box {...wrapperBox}>
								<Text {...labelStyles}>Bin Settings</Text>
								<Text {...preSurveyTextStyles}>
									{binSetting}
								</Text>
							</Box>
						</Flex>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	);
};
