import React, { PropsWithChildren } from 'react';
import {
	Button,
	Table,
	Text,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	useDisclosure,
	Box,
} from '@chakra-ui/react';
import { Icon } from '../../../../components';
import { AddStartWeightModal } from '../../../../modals';
import { useGetMeasurementSessions } from '../../../../query-hooks';
import { trickyTableDividerStyles } from '../../../../constants';
import { nowrapTableHeadersStyles } from '../../../Report/constants';
import { WeightRow } from './WeightRow';
import { WeightUpperHeader } from './WeightUpperHeader';
import { WeightSessionHeader } from './WeightSessionHeader';

export const Weights: React.FC<PropsWithChildren> = () => {
	const {
		isOpen: isAddAnimalModalOpen,
		onOpen: onAddAnimalModalOpen,
		onClose: onAddAnimalModalClose,
	} = useDisclosure();

	const { data } = useGetMeasurementSessions();

	return (
		<Box position={'relative'}>
			<Box
				backgroundColor={'light'}
				boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
				borderRadius={'12px'}
				padding={'16px'}
				minH={'calc(100vh - 300px)'}
				w={'calc(100vw - 212px)'}>
				<TableContainer
					overflow={'auto'}
					h={'calc(100vh - 300px)'}
					bg="white"
					w={'calc(100vw - 204px)'}>
					<Table
						minW="200px"
						variant="custom"
						sx={{
							'tr > th, tr > td': {
								backgroundColor: 'white',
							},
							'tr:first-of-type > th:first-of-type, tr:first-of-type > th:nth-of-type(2)':
								{
									backgroundColor: 'light',
								},
							'tr > th:first-of-type, tr > td:first-of-type': {
								position: 'sticky',
								left: 0,
							},
							'tr > th:nth-of-type(2), tr > td:nth-of-type(2)': {
								position: 'sticky',
								left: '114px',
							},
						}}>
						<Thead>
							<Tr>
								{/* 2 spaces always reserved */}
								<Th
									style={{ backgroundColor: 'light' }}
									zIndex={10}
								/>
								<Th
									style={{ backgroundColor: 'light' }}
									position="relative"
									zIndex={10}>
									<Box {...trickyTableDividerStyles} />
								</Th>
								{/* map through upperheaders with delete buttons (as much as old tables) */}
								{data?.value?.weightSessions.map(elem => (
									<WeightUpperHeader
										key={elem.sessionId}
										sessionId={elem.sessionId}
										sessionName={elem.sessionName}
									/>
								))}
							</Tr>
							<Tr>
								{/* 2 items fixed */}
								<Th
									style={{
										...nowrapTableHeadersStyles,
										minWidth: '114px',
									}}
									zIndex={10}>
									Tag #
								</Th>
								<Th
									position="relative"
									style={{
										...nowrapTableHeadersStyles,
										minWidth: '114px',
									}}
									zIndex={10}>
									EID <Box {...trickyTableDividerStyles} />
								</Th>
								{/* create headers (in old implementations for separate tables) */}
								{data?.value?.weightSessions.map(
									(elem, index) => (
										<WeightSessionHeader
											key={elem.sessionId}
											index={index + 1}
										/>
									),
								)}
							</Tr>
						</Thead>
						<Tbody>
							{/* as much as we have in left sticky columns items, map rows */}
							{data?.value.animals.map((animal, index) => (
								<Tr key={animal.ranchTag}>
									{/* at start, fixed */}
									<Td p="8px" zIndex={10}>
										<Text
											color="darker"
											textAlign="center"
											minW="90px">
											{animal.ranchTag}
										</Text>
									</Td>
									<Td p="8px" position="relative" zIndex={10}>
										<Text
											color="darker"
											textAlign="center"
											minW="90px">
											{animal.eid || ''}
										</Text>
										<Box {...trickyTableDividerStyles} />
									</Td>
									{/* lay row of repeating data [table-1-item-1, table-1-item-2, table-2-item-1, table-2-item-2,...] */}
									{data?.value?.weightSessions.map(elem => (
										<WeightRow
											key={
												elem.weights[index]
													.animalInTestId
											}
											date={elem.date}
											note={elem.weights[index].note}
											weight={elem.weights[index].weight}
											sessionId={elem.sessionId}
											animalInTestId={
												animal.animalInTestId
											}
										/>
									))}
								</Tr>
							))}
						</Tbody>
					</Table>
				</TableContainer>
			</Box>

			<Button
				variant={'primary'}
				position={'absolute'}
				bottom={'55px'}
				right={'65px'}
				width={'195px'}
				gap={'8px'}
				onClick={onAddAnimalModalOpen}>
				<Icon name="add" width={'20px'} height={'20px'} color="light" />
				Add Weight #
			</Button>
			<AddStartWeightModal
				onClose={onAddAnimalModalClose}
				isOpen={isAddAnimalModalOpen}
				sessionsLength={data?.value?.weightSessions?.length}
			/>
		</Box>
	);
};
