import { useMutation } from '@tanstack/react-query';
import { ApiResponseEmpty } from '../../types';
import apiBase from '../../api/api.base';
import { Alerter } from '../../utils';

export const useVerificationEmail = () => {
	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ userName: string; email: string }
	>({
		mutationFn: payload =>
			apiBase.post('/api/auth/verification/email', payload),
		onSuccess: response => {
			if (!response.success) {
				Alerter.error(response.errors?.[0]?.message);
				return;
			}
		},
	});
};
