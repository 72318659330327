import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
	Button,
	Flex,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { colors } from '../../theme/colors';
import { CustomDatePicker, Icon, Loader } from '../../components';
import { useGetReportDailyErrors } from '../../query-hooks';
import { convertStringToDate, toPayloadDate } from '../../utils';
import { TestDatesSelector } from '../../types';

interface DailyErrorReportModalProps {
	isOpen: boolean;
	onClose: () => void;
	testDates?: TestDatesSelector;
}

export const DailyErrorReportModal: React.FC<DailyErrorReportModalProps> = ({
	isOpen,
	onClose,
	testDates,
}) => {
	const { testFinish, testStart } = testDates || {};

	const [date, setDate] = useState<Date>(convertStringToDate(testFinish));

	const isInitialDateUpdatedRef = useRef(false);

	const { data, isLoading } = useGetReportDailyErrors({
		date: toPayloadDate(date),
		enabled: isOpen && isInitialDateUpdatedRef.current,
	});
	const errors = data?.value?.errors || [];
	const warnings = data?.value?.warnings || [];
	const dataDate = data?.value?.date;
	const totalCount = data?.value?.totalCount || 0;

	const { minDate, maxDate } = useMemo(() => {
		const minDate = testStart ? convertStringToDate(testStart) : undefined;
		const maxDate = testFinish
			? convertStringToDate(testFinish)
			: undefined;

		return { minDate, maxDate };
	}, [testFinish, testStart]);

	useEffect(() => {
		if (isOpen && testFinish && !isInitialDateUpdatedRef.current) {
			setDate(convertStringToDate(testFinish));
			isInitialDateUpdatedRef.current = true;
		}
		return () => {
			isInitialDateUpdatedRef.current = false;
		};
	}, [testFinish, isOpen]);

	return (
		<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent minW={'844px'} borderRadius={'24px'} p={'32px'}>
				<ModalHeader
					display={'flex'}
					justifyContent={'center'}
					mb={'24px'}>
					<Text variant={'B03'} color={'dark'} fontSize={'20px'}>
						Daily Error Report
					</Text>
					<Button
						width={'40px'}
						height={'40px'}
						border={`1px solid ${colors.primary}`}
						p={'0px'}
						onClick={onClose}
						position={'absolute'}
						top={'40px'}
						right={'16px'}
						borderRadius={'50%'}>
						<Icon
							name="close"
							color={colors.primary}
							width={'24px'}
							height={'24px'}
						/>
					</Button>
				</ModalHeader>
				<Flex w={'300px'} ps={'12px'}>
					<CustomDatePicker
						showTime={false}
						calendarIconName="calendar-tiny"
						className="fake-button-left-icon"
						placeholder="Start Date"
						value={date}
						onChange={val => setDate(val || new Date())}
						minDate={minDate}
						maxDate={maxDate || new Date()}
						shouldCloseOnSelect={true}
					/>
				</Flex>
				<TableContainer
					display={'flex'}
					flexDirection={'column'}
					borderBottom={'1px solid #89AB7D'}
					maxH={'543px'}
					minH={'543px'}
					overflowY={'auto'}>
					<Table
						variant="custom"
						sx={{
							td: { borderBottom: 'none !important' },
							th: {
								borderBottom: 'none !important',
								position: 'sticky',
								top: 0,
								background: 'white',
								zIndex: 1,
							},
							tr: { borderBottom: 'none !important' },
						}}>
						<Thead>
							<Tr>
								<Th>Error Code</Th>
								<Th>Date</Th>
								<Th>Number of Errors</Th>
								<Th>Total # of Feed Events</Th>
								<Th>Error as a % of Total</Th>
							</Tr>
						</Thead>
						<Tbody>
							{isLoading ? (
								<Tr>
									<Td colSpan={8}>
										<Loader centerHeight="440px" />
									</Td>
								</Tr>
							) : errors.length ? (
								errors.map((error, index) => (
									<Tr key={index}>
										<Td w={'110px'}>{error.code}</Td>
										<Td w={'110px'}>{dataDate}</Td>
										<Td w={'160px'}>
											{error.numberOfOccurrences}
										</Td>
										<Td w={'200px'}>
											{error.feedEventsNumber}
										</Td>
										<Td w={'200px'}>{error.errorRate}</Td>
									</Tr>
								))
							) : (
								<Tr>
									<Td colSpan={5} h={'400px'}>
										<Text>No data for this date</Text>
									</Td>
								</Tr>
							)}
						</Tbody>
					</Table>
				</TableContainer>
				<Text fontWeight={700} color={'dark'} m={'12px 0'}>
					Warning Code
				</Text>
				<TableContainer
					display={'flex'}
					flexDirection={'column'}
					borderBottom={'1px solid #89AB7D'}
					maxH={'100px'}
					minH={'100px'}
					overflowY={'auto'}>
					<Table
						variant="custom"
						sx={{
							td: { borderBottom: 'none !important' },
							th: { borderBottom: 'none !important' },
							tr: { borderBottom: 'none !important' },
						}}>
						<Tbody>
							{isLoading ? (
								<Tr>
									<Td colSpan={8}>
										<Loader centerHeight="440px" />
									</Td>
								</Tr>
							) : warnings.length ? (
								warnings.map((warning, index) => (
									<Tr key={index}>
										<Td w={'110px'}>{warning.code}</Td>
										<Td w={'110px'}>{dataDate}</Td>
										<Td w={'160px'}>
											{warning.numberOfOccurrences}
										</Td>
										<Td w={'200px'}>
											{warning.feedEventsNumber}
										</Td>
										<Td w={'200px'}>{warning.errorRate}</Td>
									</Tr>
								))
							) : (
								<Tr>
									<Td colSpan={5}>
										<Text>No data for this date</Text>
									</Td>
								</Tr>
							)}
						</Tbody>
					</Table>
				</TableContainer>
				<Flex
					flexDirection={'column'}
					flexGrow={'1'}
					justifyContent={'flex-end'}>
					<Flex gap={'10px'} align="center" pt={'8px'}>
						<Text color={'dark'} fontWeight="bold">
							Feed Number of Errors
						</Text>
						<Text
							color={'white'}
							backgroundColor={'primary'}
							minW={'80px'}
							textAlign={'center'}
							p={'2px 8px'}>
							{totalCount}
						</Text>
					</Flex>
				</Flex>
			</ModalContent>
		</Modal>
	);
};
