import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

interface ICustomSwitchProps {
	isChecked: boolean;
	setIsChecked: () => void;
	leftLabel?: string;
	rightLabel?: string;
	trackSize?: number;
	isDisabled?: boolean;
}
export const CustomSwitch: React.FC<ICustomSwitchProps> = ({
	isChecked,
	setIsChecked,
	leftLabel = 'On',
	rightLabel = 'Off',
	trackSize = 67,
	isDisabled,
}) => {
	const handleToggle = () => {
		setIsChecked();
	};

	return (
		<Box cursor={isDisabled ? 'not-allowed' : 'pointer'}>
			<Flex
				h="34px"
				borderRadius="20px"
				p="2px"
				bg={'primary'}
				transition=".3s"
				maxW="fit-content"
				position="relative"
				onClick={handleToggle}
				opacity={isDisabled ? 0.5 : 1}
				pointerEvents={isDisabled ? 'none' : undefined}>
				<Text
					zIndex={2}
					fontSize="12px"
					lineHeight="30px"
					textAlign="center"
					transition=".3s"
					fontWeight={500}
					color={isChecked ? 'dark' : 'white'}
					w={`${trackSize}px`}>
					{leftLabel}
				</Text>
				<Text
					zIndex={2}
					fontSize="12px"
					lineHeight="30px"
					textAlign="center"
					fontWeight={500}
					color={isChecked ? 'white' : 'dark'}
					w={`${trackSize}px`}>
					{rightLabel}
				</Text>
				<Box
					zIndex={1}
					position="absolute"
					left={isChecked ? '2px' : `${trackSize + 2}px`}
					transition=".3s"
					w={`${trackSize}px`}
					h="30px"
					borderRadius="20px"
					bg="white"
				/>
			</Flex>
		</Box>
	);
};
