import React, { useState } from 'react';
import {
	Button,
	Flex,
	Table,
	TableContainer,
	Tbody,
	Th,
	Thead,
	Tr,
	Td,
	Center,
	Text,
} from '@chakra-ui/react';
import { colors } from '../../../../theme/colors';
import { Icon, Pagination, Loader } from '../../../../components';
import { useGetTestNotes } from '../../../../query-hooks';
import { TestNoteRow } from './TestNoteRow';
import { NewNoteRow } from './NewNoteRow';

interface TestNotes {
	number: number;
	date: Date;
	notes: string;
	isEdit: boolean;
}

export const TestNotes: React.FC = () => {
	const [addNewNoteOpen, setAddNewNoteOpen] = useState(false);
	const [page, setPage] = useState(1);
	const { data, isLoading } = useGetTestNotes(page);

	const items = data?.value?.items;

	return (
		<TableContainer
			backgroundColor={'light'}
			boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
			borderRadius={'12px'}
			display={'flex'}
			flexDirection={'column'}
			overflowY="auto"
			minH={'calc(100vh - 271px)'}
			minW={'calc(100vw - 304px)'}
			p={'16px'}>
			<Table
				variant="custom"
				mb={'32px'}
				sx={{
					td: { borderBottom: 'none' },
					th: { borderBottom: 'none' },
				}}>
				<Thead>
					<Tr>
						<Th w={'100px'}>#</Th>
						<Th w={'140px'}>Date</Th>
						<Th>Notes</Th>
						<Th w={'120px'}>Actions</Th>
					</Tr>
				</Thead>
				<Tbody>
					{addNewNoteOpen ? (
						<NewNoteRow onCancel={() => setAddNewNoteOpen(false)} />
					) : null}
					{isLoading ? (
						<Tr>
							<Td colSpan={2}>
								<Loader centerHeight="400px" />
							</Td>
						</Tr>
					) : items?.length === 0 ? (
						<Tr>
							<Td colSpan={4}>
								<Center h="300px">
									<Text variant="B02" color="darkLight">
										No data yet
									</Text>
								</Center>
							</Td>
						</Tr>
					) : (
						items?.map((row, index) => (
							<TestNoteRow
								key={row.id}
								id={row.id}
								index={index}
								note={row.note}
								date={row.date}
							/>
						))
					)}
				</Tbody>
			</Table>
			<Flex
				flexDirection={'column'}
				flexGrow={'1'}
				justifyContent={'flex-end'}>
				<Flex justify="center" mb={4}>
					<Button
						onClick={() => {
							setAddNewNoteOpen(true);
						}}
						color={'primary'}>
						<Icon
							name="add"
							width={'20px'}
							height={'20px'}
							color={colors.primary}
						/>
						Add Info
					</Button>
				</Flex>
			</Flex>
			<Pagination
				totalCount={data?.value?.count || 0}
				currentPage={page}
				pageSize={10}
				onPageChange={val => setPage(val)}
			/>
		</TableContainer>
	);
};
