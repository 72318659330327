import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../constants';
import { ApiResponse, AnimalsTestCyclesRetags } from '../../types';
import apiBase from '../../api/api.base';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

export const useGetTestCyclesAnimalsChangeEidNote = (page: number) => {
	const { id: testCycleId } = useParams();
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useQuery({
		queryKey: [QueryKeys.GET_TEST_CYCLES_ANIMALS_CHANGE_EID_NOTE, page],
		queryFn: (): Promise<ApiResponse<AnimalsTestCyclesRetags>> =>
			apiBase.get(
				`/api/ranchos/${ranchoId}/test-cycles/${testCycleId}/animals/change-eid-note`,
				{ PageSize: 10, CurrentPage: page },
			),
	});
};
