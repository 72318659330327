import React from 'react';
import { Tr, Td } from '@chakra-ui/react';
import { format } from 'date-fns';
interface ITestNoteRowProps {
	note: string;
	date: string;
	id: number;
}

export const TestNoteRowModal: React.FC<ITestNoteRowProps> = ({
	date,
	note,
	id,
}) => {
	return (
		<Tr>
			<Td w="100px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{id}
			</Td>
			<Td w="140px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{format(date, 'M/d/yyyy')}
			</Td>
			<Td minW="170px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{note}
			</Td>
		</Tr>
	);
};
