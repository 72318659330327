import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const addBinSettingSchema = yup.object().shape({
	name: yup.string().required('Name is required'),
	rfidSettings: yup.array().of(
		yup.object().shape({
			nameFormat: yup.string().required('Name is required'),
			numberOffset: yup.number(),
			numberRange: yup.number(),
		}),
	),
	scaleSettings: yup.array().of(
		yup.object().shape({
			nameFormat: yup.string().required('Name is required'),
			binRfidNumber: yup.number(),
			binNumber: yup.number(),
		}),
	),
});

export const addBinSettingResolver = yupResolver(addBinSettingSchema);
