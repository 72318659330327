import {
	Button,
	Flex,
	FormLabel,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { ApiResponse, BinSetting } from '../../types';
import { labelHorizontalStyles } from '../../constants';
import { CustomSelect } from '../../components';
import { useUpdateBinPreSurveySetting } from '../../query-hooks/pre-survey/useUpdateBinPreSurveySetting';

interface ChangeBinSettingsModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
	binSettings?: ApiResponse<BinSetting[]>;
	currentSettingId?: number;
	onSuccessCb: VoidFunction;
}

export const ChangeBinSettingsModal: React.FC<ChangeBinSettingsModalProps> = ({
	isOpen,
	onClose,
	binSettings,
	currentSettingId,
	onSuccessCb,
}) => {
	const [selectedBinSetting, setSelectedBinSetting] = useState<
		number | undefined
	>(currentSettingId);

	useEffect(() => {
		if (currentSettingId && binSettings?.value) {
			const currentSettingObj = binSettings.value.find(
				it => it.id === Number(currentSettingId),
			);
			if (currentSettingObj) {
				setSelectedBinSetting(currentSettingObj.id);
			}
		}
	}, [currentSettingId, binSettings]);

	// Update bin setting
	const updateBinSettingMutation = useUpdateBinPreSurveySetting({
		successCb: () => {
			onSuccessCb();
			onClose();
		},
	});
	const handleBinSettingUpdate = (settingId?: number) => {
		if (!settingId) {
			return;
		}
		updateBinSettingMutation.mutate({ settingId: settingId });
	};
	// ======================

	return (
		<Modal
			blockScrollOnMount={false}
			isOpen={isOpen}
			onClose={onClose}
			isCentered={true}>
			<ModalOverlay />
			<ModalContent minH={'282px'} minW={'486'} borderRadius={'24px'}>
				<ModalHeader
					display={'flex'}
					justifyContent={'center'}
					p={'32px 32px 8px'}>
					<Text variant={'B02'} fontSize={'20px'} color={'darker'}>
						Change Bin Setting
					</Text>
				</ModalHeader>
				<Flex flexDirection={'column'} padding={'32px'}>
					<FormLabel {...labelHorizontalStyles}>
						Bin Settings
					</FormLabel>
					{binSettings?.value.length ? (
						<CustomSelect
							value={String(selectedBinSetting)}
							onChange={e => {
								setSelectedBinSetting(+e);
							}}
							options={binSettings.value.map(it => {
								return {
									label: it.name,
									value: String(it.id),
								};
							})}
							placeholder={''}
						/>
					) : null}
				</Flex>
				<Flex gap={'24px'} p={'32px'}>
					<Button variant={'secondary'} onClick={onClose}>
						Cancel
					</Button>
					<Button
						variant={'primary'}
						isDisabled={!selectedBinSetting}
						isLoading={updateBinSettingMutation.isPending}
						onClick={() => {
							handleBinSettingUpdate(selectedBinSetting);
						}}>
						Change
					</Button>
				</Flex>
			</ModalContent>
		</Modal>
	);
};
