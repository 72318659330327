import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Box,
	Table,
	TableContainer,
	Tbody,
	Td,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { useGetReportAnimalBehavior } from '../../../../query-hooks';
import { Loader, Pagination } from '../../../../components';
import { EmptyDataMessage } from '../../components';
import { TableHeaders, TableRow } from './components';
import { convertStringToDate, toPayloadDate } from '../../../../utils';
import { TestDatesSelector } from '../../../../types';

interface IAnimalBehaviorProps {
	testDates?: TestDatesSelector;
}

export const AnimalBehavior: React.FC<IAnimalBehaviorProps> = ({
	testDates,
}) => {
	const [page, setPage] = useState(1);

	const [startDate, setStartDate] = useState<Date | undefined>(undefined);
	const [endDate, setEndDate] = useState<Date | undefined>(undefined);
	const [queryDates, setQueryDates] = useState<{
		startDate?: Date;
		endDate?: Date;
	}>({
		startDate: undefined,
		endDate: undefined,
	});

	const isInitialDatesUpdatedRef = useRef(false);

	useEffect(() => {
		if (testDates) {
			const dateOfTestStart = convertStringToDate(testDates.testStart);
			const dateOfTestFinish = convertStringToDate(testDates.testFinish);

			setStartDate(dateOfTestStart);
			setEndDate(dateOfTestFinish);
			setQueryDates({
				startDate: dateOfTestStart,
				endDate: dateOfTestFinish,
			});

			isInitialDatesUpdatedRef.current = true;
		}
	}, [testDates]);

	const onSetStartDate = useCallback(
		(date?: Date) => {
			setStartDate(date);

			if (endDate && date) {
				setQueryDates({ startDate: date, endDate });
				setPage(1);
			}

			if (!date) {
				setQueryDates({ startDate: undefined, endDate: undefined });
				setEndDate(undefined);
				setPage(1);
			}
		},
		[endDate],
	);

	const onSetEndDate = useCallback(
		(date?: Date) => {
			setEndDate(date);
			if (startDate && date) {
				setQueryDates({ startDate, endDate: date });
				setPage(1);
			}

			if (!date) {
				setQueryDates({ startDate: undefined, endDate: undefined });
				setStartDate(undefined);
				setPage(1);
			}
		},
		[startDate],
	);

	const { data, isLoading } = useGetReportAnimalBehavior({
		currentPage: page,
		startDate: queryDates.startDate
			? toPayloadDate(queryDates.startDate)
			: undefined,
		endDate: queryDates.endDate
			? toPayloadDate(queryDates.endDate)
			: undefined,
		isEnabled: isInitialDatesUpdatedRef.current,
	});

	const totalCount = data?.value?.totalCount || 0;
	const items = data?.value?.items || [];

	const animalBehaviorDates = useMemo(
		() =>
			data?.value?.items?.map(el =>
				el.dailyAnimalBehavior.map(el => el.date),
			)?.[0] || [],
		[data?.value?.items],
	);

	const isDateFiltersFilled = useMemo(
		() => !!queryDates.startDate && !!queryDates.endDate,
		[queryDates],
	);

	const { minDate, maxDate } = useMemo(() => {
		const { testStart, testFinish } = testDates || {};
		const minDate = testStart ? convertStringToDate(testStart) : undefined;
		const maxDate = testFinish
			? convertStringToDate(testFinish)
			: undefined;

		return { minDate, maxDate };
	}, [testDates]);

	return (
		<React.Fragment>
			<Box
				backgroundColor="light"
				boxShadow="0px 0px 15px rgba(0, 0, 0, 0.10)"
				borderRadius="12px"
				padding="16px">
				<TableContainer
					h={
						totalCount > 10
							? 'calc(100vh - 350px)'
							: '"calc(100vh - 320px)"'
					}
					maxH="615px"
					bg="white"
					overflowY="auto">
					<Table
						w="100%"
						minW="200px"
						variant="custom"
						sx={{
							'tr > th, tr > td': {
								backgroundColor: 'white',
							},

							'tr > th:first-of-type, tr > td:first-of-type': {
								position: 'sticky',
								left: 0,
							},
							'tr > th:nth-of-type(2), tr > td:nth-of-type(2)': {
								position: 'sticky',
								left: '174px',
							},
						}}>
						<Thead>
							<TableHeaders
								dates={animalBehaviorDates}
								startDate={startDate}
								endDate={endDate}
								setStartDate={onSetStartDate}
								setEndDate={onSetEndDate}
								minDate={minDate}
								maxDate={maxDate}
							/>
						</Thead>
						<Tbody>
							{isLoading ? (
								<Tr>
									<Td colSpan={8}>
										<Loader centerHeight="440px" />
									</Td>
								</Tr>
							) : items.length ? (
								items.map((el, index) => (
									<TableRow key={index} {...el} />
								))
							) : (
								<Tr>
									<Td colSpan={8}>
										<EmptyDataMessage
											message={
												isDateFiltersFilled
													? 'No data for these dates'
													: 'No data yet'
											}
											containerProps={{
												h: '440px',
												w: 'full',
												boxShadow: 'none',
												bg: 'white',
											}}
										/>
									</Td>
								</Tr>
							)}
						</Tbody>
					</Table>
				</TableContainer>
			</Box>
			<Pagination
				totalCount={totalCount}
				pageSize={10}
				currentPage={page}
				onPageChange={page => setPage(page)}
				mt={4}
			/>
		</React.Fragment>
	);
};
