export interface AddTestFormValues {
	customerName: string;
	representative: string;
	customerId: string;
	premiseId: string;
	premiseState: string;
	testNumber: number;
	testGroup: string;
	testHead: number;
	breed: string;
	weightStartLow: number;
	weightStartHigh: number;
	vGate: string;
	turnInDate: Date;
	testStart: Date;
	testFinish: Date;
	testDietCost: number;
	testDryMatter: number;
	neg: number;
	nem: number;
	cleanOutEidTagFirst: string;
	cleanOutEidTagSecond: string;
	binSettings: string;
}

type KeysOfInterface = keyof AddTestFormValues;

export const addTestFormValuesKeys: Array<KeysOfInterface> = [
	'customerName',
	'representative',
	'customerId',
	'premiseId',
	'premiseState',
	'testNumber',
	'testGroup',
	'testHead',
	'breed',
	'weightStartLow',
	'weightStartHigh',
	'vGate',
	'turnInDate',
	'testStart',
	'testFinish',
	'testDietCost',
	'testDryMatter',
	'neg',
	'nem',
	'cleanOutEidTagFirst',
	'cleanOutEidTagSecond',
	'binSettings',
];

export interface AddTestPayloadValues {
	settingId: number;
	survey: Omit<
		AddTestFormValues,
		'turnInDate' | 'testStart' | 'testFinish' | 'binSettings'
	> & {
		turnInDate: string;
		testStart: string;
		testFinish: string;
	};
}
