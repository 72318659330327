import React, { useState } from 'react';
import {
	Box,
	Button,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Text,
	useDisclosure,
	Center,
} from '@chakra-ui/react';
import { Icon, Pagination, Loader } from '../../components';
import { AddTestModal } from '../../modals';
import { useGetTestCycles } from '../../query-hooks';
import { TestRow } from './TestRow';

export const Tests: React.FC = () => {
	const [page, setPage] = useState(1);

	const { data, isLoading } = useGetTestCycles(page);

	const {
		isOpen: isAddTestModalOpen,
		onOpen: onAddTestModalOpen,
		onClose: onAddTestModalClose,
	} = useDisclosure();

	const items = data?.value.items;

	return (
		<Box position={'relative'} width={'100%'}>
			<TableContainer
				backgroundColor={'light'}
				boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
				borderRadius={'12px'}
				padding={'16px'}
				minW={'calc(100vw - 256px)'}
				minHeight={'100%'}
				overflowY={'auto'}
				display={'flex'}
				flexDirection={'column'}
				justifyContent={'space-between'}>
				<Table variant="custom">
					<Thead>
						<Tr>
							<Th>Test #</Th>
							<Th>Test group</Th>
							<Th>Customer</Th>
							<Th>Customer ID</Th>
							<Th>Status</Th>
							<Th>Run Test</Th>
							<Th>Actions</Th>
							<Th>Details</Th>
						</Tr>
					</Thead>
					<Tbody>
						{/* */}
						{isLoading ? (
							<Tr>
								<Td colSpan={8}>
									<Loader centerHeight="400px" />
								</Td>
							</Tr>
						) : items?.length ? (
							items?.map(item => (
								<TestRow key={item.id} {...item} />
							))
						) : (
							<Tr>
								<Td colSpan={8}>
									<Center h="300px">
										<Text variant="B02" color="darkLight">
											No data yet
										</Text>
									</Center>
								</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
				<Pagination
					totalCount={data?.value?.count || 0}
					pageSize={10}
					currentPage={page}
					onPageChange={page => setPage(page)}
					mt={4}
				/>
			</TableContainer>
			<Button
				onClick={onAddTestModalOpen}
				variant={'primary'}
				position={'fixed'}
				bottom={'70px'}
				right={'65px'}
				width={'165px'}
				gap={'8px'}>
				{' '}
				<Icon name="add" width={'20px'} height={'20px'} color="light" />
				Add
			</Button>

			<AddTestModal
				onClose={onAddTestModalClose}
				isOpen={isAddTestModalOpen}
			/>
		</Box>
	);
};
