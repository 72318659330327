import React, { useState } from 'react';
import {
	Button,
	Flex,
	Table,
	TableContainer,
	Tbody,
	Th,
	Thead,
	Tr,
	useDisclosure,
	Td,
	Center,
	Text,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { AddAnimalInfoModal } from '../../../../modals';
import { colors } from '../../../../theme/colors';
import { Icon, Pagination, Loader } from '../../../../components';
import {
	useGetRanchoAnimalsTestOptions,
	useGetSickDays,
} from '../../../../query-hooks';
import { SickDayRow } from './SickDayRow';
import { NewSickDayRow } from './NewSickDayRow';

export const AnimalSickDays: React.FC = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const { id } = useParams();
	const [selectedTagData, setSelectedTagData] = useState<null | {
		tag?: string;
		animalId: number;
	}>(null);
	const [page, setPage] = useState(1);

	const { data, isLoading } = useGetSickDays(page);

	const { data: ranchoAnimalsOptionsData } = useGetRanchoAnimalsTestOptions({
		search: undefined,
		isEnabled: isOpen,
		testCycleId: +(id || 0),
	});

	const handleSelectTag = (id: number) => {
		setSelectedTagData({
			animalId: id,
			tag: ranchoAnimalsOptionsData?.value.find(
				elem => elem.animalInTestId === id,
			)?.ranchTag,
		});
		onClose();
	};

	const items = data?.value?.items;

	return (
		<>
			<TableContainer
				backgroundColor={'light'}
				boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
				borderRadius={'12px'}
				display={'flex'}
				flexDirection={'column'}
				minH={'calc(100vh - 271px)'}
				minW={'calc(100vw - 304px)'}
				p={'16px'}>
				<Table
					variant="custom"
					mb={'32px'}
					sx={{
						td: { borderBottom: 'none' },
						th: { borderBottom: 'none' },
					}}>
					<Thead>
						<Tr>
							<Th w={'100px'}>#</Th>
							<Th w={'100px'}>Tag #</Th>
							<Th w={'140px'}>Date</Th>
							<Th>
								Notes: reason for the day being disqualified.
								Sickness, Doctoring, etc.
							</Th>
							<Th w={'120px'}>Actions</Th>
						</Tr>
					</Thead>
					<Tbody>
						{selectedTagData ? (
							<NewSickDayRow
								onCancel={() => setSelectedTagData(null)}
								animalId={selectedTagData.animalId}
								ranchoTag={selectedTagData.tag}
							/>
						) : null}
						{isLoading ? (
							<Tr>
								<Td colSpan={5}>
									<Loader centerHeight="400px" />
								</Td>
							</Tr>
						) : items?.length === 0 ? (
							<Tr>
								<Td colSpan={5}>
									<Center h="300px">
										<Text variant="B02" color="darkLight">
											No data yet
										</Text>
									</Center>
								</Td>
							</Tr>
						) : (
							items?.map(row => (
								<SickDayRow key={row.id} {...row} />
							))
						)}
					</Tbody>
				</Table>
				<Flex
					flexDirection={'column'}
					flexGrow={'1'}
					justifyContent={'flex-end'}>
					<Flex justify="center" mb={4}>
						<Button onClick={onOpen} color={'primary'}>
							<Icon
								name="add"
								width={'20px'}
								height={'20px'}
								color={colors.primary}
							/>
							Add Info
						</Button>
					</Flex>
				</Flex>
				<Pagination
					totalCount={data?.value?.count || 0}
					currentPage={page}
					pageSize={10}
					onPageChange={val => setPage(val)}
				/>
			</TableContainer>
			<AddAnimalInfoModal
				title="Add Info"
				label="Tag #"
				placeholder="Select Tag #"
				isOpen={isOpen}
				onClose={onClose}
				options={ranchoAnimalsOptionsData?.value?.map(elem => ({
					label: elem.ranchTag,
					value: elem.animalInTestId.toString(),
				}))}
				onSubmit={(id: number) => handleSelectTag(id)}
			/>
		</>
	);
};
