export const getDurationFromMinutes = (totalMinutes: number): string => {
	if (totalMinutes < 1) {
		return 'less a minute';
	}

	const days = Math.floor(totalMinutes / (60 * 24));
	const hours = Math.floor((totalMinutes % (60 * 24)) / 60);
	const minutes = totalMinutes % 60;

	const timeString = [
		days > 0 ? `${days}d` : '',
		hours > 0 ? `${hours}h` : '',
		minutes > 0 ? `${minutes}m` : '',
	]
		.filter(Boolean)
		.join(' ');

	return timeString.trim();
};
