//* each in object to flex customization after
export const AnimalInfoHeaders = [
	{
		label: 'Actions',
	},
	{
		label: 'Ranch Tag',
	},
	{
		label: 'EID',
	},
	{
		label: 'Reg #',
	},
	{
		label: 'BD',
	},
	{
		label: 'Sex',
	},
	{
		label: 'DNA collected?',
	},
	{
		label: 'Sire',
	},
	{
		label: 'Dam',
	},
	{
		label: 'EID full',
	},
	{
		label: 'EID truncated',
	},
	{
		label: 'EID Notes',
	},
	{
		label: 'Status',
	},
	{
		label: 'Contemporary Group',
	},
];

export const DisabledSelectTriggerProps = {
	lineHeight: '15px',
	isDisabled: true,
	cursor: 'default',
	_disabled: {
		color: 'darkLight',
		borderBottomColor: 'lightGray',
		opacity: 1,
		'> span > svg': {
			color: 'darkLight',
		},
	},
};
