import { useMutation } from '@tanstack/react-query';
import { ApiResponseEmpty } from '../../types';
import apiBase from '../../api/api.base';
import { Alerter } from '../../utils';

export const useUpdateUserDetails = () => {
	return useMutation<ApiResponseEmpty, unknown, FormData>({
		mutationFn: payload => apiBase.post('/api/user', payload),
		onSuccess: response => {
			if (response.success) {
				Alerter.success(
					'Profile details has been changed successfully',
				);
				return;
			} else {
				Alerter.error(response?.errors?.[0]?.message);
			}
		},
	});
};
