import { useMutation } from '@tanstack/react-query';
import API from '../../api/api.base';
import { CreateBinSettingPayload, ApiResponse } from '../../types';
import { Alerter } from '../../utils';
import { queryClient, QueryKeys } from '../../constants';

export const useCreateBinSetting = () => {
	return useMutation<ApiResponse<number>, unknown, CreateBinSettingPayload>({
		mutationFn: payload => API.post('/api/app/settings', payload),
		onSuccess: response => {
			if (response.success) {
				Alerter.success('Setting successfully created');
				queryClient.invalidateQueries({
					queryKey: [QueryKeys.GET_BIN_SETTINGS],
				});
			} else {
				Alerter.error(response.errors?.[0]?.message);
			}
		},
	});
};
