import { useQuery } from '@tanstack/react-query';
import API from '../../api/api.base';
import { QueryKeys } from '../../constants';
import { ApiResponse, BinSetting } from '../../types';

export const useGetBinSettings = () => {
	return useQuery<ApiResponse<BinSetting[]>>({
		queryKey: [QueryKeys.GET_BIN_SETTINGS],
		queryFn: () => API.get('/api/app/settings', {}),
	});
};
