import React, { useMemo } from 'react';
import {
	Button,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
} from '@chakra-ui/react';
import { colors } from '../../../theme/colors';
import { Icon } from '../../../components';
import { useGetFeedRation } from '../../../query-hooks';
import { RationMetadata } from './RationMetadata';
import { TestFeedRationModalRow } from './TestFeedRationModalRow';

interface TestFeedRationModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const TestFeedRationModal: React.FC<TestFeedRationModalProps> = ({
	isOpen,
	onClose,
}) => {
	const { data, isLoading } = useGetFeedRation();

	const totalFedPercent = useMemo(
		() =>
			data?.value?.items?.reduce(
				(acc, curr) => acc + curr.asFedPercent,
				0,
			),
		[data],
	);

	const totalDryMatter = useMemo(
		() =>
			data?.value?.items?.reduce((acc, curr) => acc + curr.dryMatter, 0),
		[data],
	);

	return (
		<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				minH={'853px'}
				minW={'740px'}
				borderRadius={'24px'}
				p={'32px'}>
				<ModalHeader display={'flex'} justifyContent={'center'}>
					<Text variant={'B03'} color={'dark'} fontSize={'20px'}>
						Test Feed Ration
					</Text>
					<Button
						width={'40px'}
						height={'40px'}
						border={`1px solid ${colors.primary}`}
						p={'0px'}
						onClick={onClose}
						position={'absolute'}
						right={'16px'}
						borderRadius={'50%'}>
						<Icon
							name="close"
							color={colors.primary}
							width={'24px'}
							height={'24px'}
						/>
					</Button>
				</ModalHeader>
				<TableContainer
					flexDirection={'column'}
					h="653px"
					maxH={'653px'}
					borderBottom={'1px solid #89AB7D'}
					overflowY={'auto'}>
					<Table
						variant="custom"
						sx={{
							td: { borderBottom: 'none !important' },
							th: { borderBottom: 'none !important' },
							tr: {
								borderBottom: 'none !important',
								verticalAlign: 'top',
							},
						}}>
						<Thead>
							<Tr>
								<Th>As fed %</Th>
								<Th>DM %</Th>
								<Th>Ingredient</Th>
								<Th>Feed Notes</Th>
							</Tr>
						</Thead>
						<Tbody>
							{data?.value?.items?.map((row, index) => (
								<TestFeedRationModalRow
									key={row.id}
									asFedPercent={row.asFedPercent}
									dryMatter={row.dryMatter}
									ingredient={row.ingredient}
									note={row.note}
								/>
							))}
						</Tbody>
					</Table>
				</TableContainer>
				<RationMetadata
					totalFedPercent={totalFedPercent}
					totalDryMatter={totalDryMatter}
				/>
			</ModalContent>
		</Modal>
	);
};
