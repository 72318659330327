import { useMutation } from '@tanstack/react-query';
import apiBase from '../../api/api.base';
import { ApiResponseEmpty } from '../../types';
import { Alerter } from '../../utils';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

export const useDeleteAnimals = () => {
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationFn: (animalId: number) =>
			apiBase.delete(
				`/api/ranchos/${ranchoId}/animals/${animalId}/delete`,
			),
		onSuccess: response => {
			if (!response.success) {
				Alerter.error(response?.errors?.[0]?.message);
			}
		},
	});
};
