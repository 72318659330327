import { useMutation, useQueryClient } from '@tanstack/react-query';
import apiBase from '../../api/api.base';
import { ApiResponseEmpty } from '../../types';
import { Alerter } from '../../utils';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { QueryKeys } from '../../constants';

export const useDeleteTestCycle = () => {
	const queryClient = useQueryClient();

	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationFn: (cycleId: number) =>
			apiBase.delete(
				`/api/ranchos/${ranchoId}/test-cycles/${cycleId}/delete`,
			),
		onSuccess: response => {
			if (!response.success) {
				Alerter.error(response?.errors?.[0]?.message);
			}
			queryClient.invalidateQueries({
				queryKey: [QueryKeys.GET_TEST_CYCLES],
			});
			Alerter.success('Test cycle deleted successfully');
		},
	});
};
