import { useMutation } from '@tanstack/react-query';
import API from '../../api/api.base';
import { EditUsedBinSettingPayload, ApiResponse } from '../../types';
import { Alerter } from '../../utils';
import { queryClient, QueryKeys } from '../../constants';

export const useUpdateUsedBinSetting = (settingId?: number) => {
	return useMutation<ApiResponse<number>, unknown, EditUsedBinSettingPayload>(
		{
			mutationFn: payload =>
				API.put(`/api/app/settings/${settingId}/update/used`, payload),
			onSuccess: response => {
				if (response.success) {
					Alerter.success(
						'Bin Setting has been changed successfully',
					);
					queryClient.invalidateQueries({
						queryKey: [QueryKeys.GET_BIN_SETTINGS],
					});
				} else {
					Alerter.error(response.errors?.[0]?.message);
				}
			},
		},
	);
};
