import React, { useMemo } from 'react';
import {
	Box,
	Button,
	Divider,
	Flex,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputLeftAddon,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { CustomDatePicker, CustomSelect, Icon } from '../../components';
import {
	formControlHorizontalStyles,
	labelVerticalStyles,
	inputVerticalStyles,
	QueryKeys,
	inputVerticalStylesReadOnly,
} from '../../constants';
import { colors } from '../../theme/colors';
import {
	useGetBinSettings,
	useGetAvailableTestIntervals,
} from '../../query-hooks';
import {
	AddTestFormValues,
	addTestFormValuesKeys,
	AddTestPayloadValues,
} from './types';
import { useCreateTest } from '../../query-hooks/animal-test/useCreateTest';

interface AddTestModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const AddTestModal: React.FC<AddTestModalProps> = ({
	isOpen,
	onClose,
}) => {
	const queryClient = useQueryClient();
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
		reset,
	} = useForm<AddTestFormValues>();

	// Test cycle create
	const onSuccessCb = () => {
		onClose();
		queryClient.invalidateQueries({
			queryKey: [QueryKeys.GET_TEST_CYCLES],
		});
		reset();
	};

	const createTest = useCreateTest({ successCb: onSuccessCb });

	const onSubmit: SubmitHandler<AddTestFormValues> = data => {
		const payload: AddTestPayloadValues = {
			survey: {
				...data,
				testStart: dayjs(data.testStart).format('YYYY-MM-DD'),
				turnInDate: dayjs(data.turnInDate).format('YYYY-MM-DD'),
				testFinish: dayjs(data.testFinish).format('YYYY-MM-DD'),
			},
			settingId: Number(data.binSettings),
		};
		createTest.mutate(payload);
	};
	// ==================================

	const watchFields = watch([...addTestFormValuesKeys]);

	const isDisabled = !watchFields.every(field => field);

	const { data } = useGetBinSettings();
	const { data: intervalsData } = useGetAvailableTestIntervals(0);

	const allowedIntervals = useMemo(
		() =>
			intervalsData?.value.intervals.map(elem => ({
				start: new Date(elem.from),
				end: new Date(elem.to),
			})),
		[intervalsData],
	);

	return (
		<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				pr={'16px'}
				borderRadius={'24px'}
				w={'100%'}
				maxW={'520px'}>
				<ModalHeader
					display={'flex'}
					justifyContent={'center'}
					p={'32px 32px 8px 32px'}>
					<Text variant={'B03'} color={'dark'}>
						User pre-survey
					</Text>
					{/*<Button*/}
					{/*	width={'40px'}*/}
					{/*	height={'40px'}*/}
					{/*	border={`1px solid ${colors.primary}`}*/}
					{/*	p={'0px'}*/}
					{/*	onClick={onClose}*/}
					{/*	position={'absolute'}*/}
					{/*	right={'16px'}*/}
					{/*	borderRadius={'50%'}>*/}
					{/*	<Icon*/}
					{/*		name="close"*/}
					{/*		color={colors.primary}*/}
					{/*		width={'24px'}*/}
					{/*		height={'24px'}*/}
					{/*	/>*/}
					{/*</Button>*/}
				</ModalHeader>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Flex
						flexDirection={'column'}
						maxH={'864px'}
						overflow={'auto'}
						overflowX={'hidden'}
						minH={'824px'}>
						{/*<Flex gap={'40px'}  paddingTop={'0px'}>*/}
						<Flex flexDirection={'column'} p={'32px'} gap={'24px'}>
							<Flex gap={'24px'}>
								<Flex
									width={'532px'}
									flexDirection={'column'}
									gap={'19px'}>
									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											Customer
										</FormLabel>
										<Input
											{...inputVerticalStyles}
											{...register('customerName', {
												required: true,
											})}
											placeholder={'Enter Customer'}
										/>
									</FormControl>
									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											Customer ID
										</FormLabel>
										<Input
											{...inputVerticalStyles}
											{...register('customerId', {
												required: true,
											})}
											placeholder={'Enter Customer ID'}
										/>
									</FormControl>

									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											Premise State
										</FormLabel>
										<Input
											{...inputVerticalStyles}
											{...register('premiseState', {
												required: true,
											})}
											placeholder={'Enter Premise State'}
										/>
									</FormControl>

									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											Test Group
										</FormLabel>
										<Input
											{...inputVerticalStyles}
											{...register('testGroup', {
												required: true,
											})}
											placeholder={'Enter Test Group'}
										/>
									</FormControl>

									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											Breed
										</FormLabel>
										<Input
											{...inputVerticalStyles}
											{...register('breed', {
												required: true,
											})}
											placeholder={'Enter Breed'}
										/>
									</FormControl>
								</Flex>

								<Flex
									width={'532px'}
									flexDirection={'column'}
									gap={'19px'}>
									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											Representative
										</FormLabel>
										<Input
											{...inputVerticalStyles}
											{...register('representative', {
												required: true,
											})}
											placeholder={'Enter Representative'}
										/>
									</FormControl>
									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											Premise ID
										</FormLabel>
										<Input
											{...inputVerticalStyles}
											{...register('premiseId', {
												required: true,
											})}
											placeholder={'Enter Premise ID'}
										/>
									</FormControl>
									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											Test #
										</FormLabel>
										<Input
											{...inputVerticalStyles}
											type="number"
											{...register('testNumber', {
												required: true,
											})}
											placeholder={'Enter Test #'}
										/>
									</FormControl>
									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											Test Head #
										</FormLabel>
										<Input
											{...inputVerticalStyles}
											type="number"
											{...register('testHead', {
												required: true,
											})}
											placeholder={'Enter Test Head #'}
										/>
									</FormControl>
									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											V-Gate setting
										</FormLabel>
										<Input
											{...inputVerticalStyles}
											padding={0}
											type="number"
											{...register('vGate', {
												required: true,
											})}
											placeholder={'Enter V-Gate Setting'}
										/>
									</FormControl>
								</Flex>
							</Flex>

							<FormControl {...formControlHorizontalStyles}>
								<FormLabel {...labelVerticalStyles}>
									Weight Range (Start)
								</FormLabel>
								<Flex
									flexDirection={'column'}
									gap={'19px'}
									width={'100%'}>
									<Flex gap={'24px'}>
										<Input
											{...inputVerticalStylesReadOnly}
											isReadOnly={true}
											defaultValue={'Low'}
										/>
										<Input
											{...inputVerticalStyles}
											{...register('weightStartLow', {
												required: true,
											})}
											type="number"
											placeholder={'Enter Info'}
										/>
									</Flex>
									<Flex gap={'24px'}>
										<Input
											{...inputVerticalStylesReadOnly}
											isReadOnly={true}
											defaultValue={'High'}
										/>
										<Input
											{...inputVerticalStyles}
											{...register('weightStartHigh', {
												required: true,
											})}
											type="number"
											placeholder={'Enter Info'}
										/>
									</Flex>
								</Flex>
							</FormControl>

							<Flex gap={'24px'}>
								<Flex
									width={'532px'}
									flexDirection={'column'}
									gap={'19px'}>
									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											Turn-in Date (Warm-up)
										</FormLabel>
										<Controller
											name="turnInDate"
											control={control}
											render={({
												field: { onChange, value },
											}) => (
												<CustomDatePicker
													showTime={true}
													className="pre-survey-datepicker"
													onChange={onChange}
													value={value}
													errorMsg={
														errors.turnInDate
															?.message as string
													}
													includeDateIntervals={
														allowedIntervals
													}
													placeholder={
														'Enter Turn-in Date'
													}
												/>
											)}
										/>
									</FormControl>
									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											Test Start
										</FormLabel>
										<Controller
											name="testStart"
											control={control}
											render={({
												field: { onChange, value },
											}) => (
												<CustomDatePicker
													showTime={false}
													className="pre-survey-datepicker"
													onChange={onChange}
													value={value}
													errorMsg={
														errors.testStart
															?.message as string
													}
													includeDateIntervals={
														allowedIntervals
													}
													placeholder={
														'Enter Test Start Date'
													}
												/>
											)}
										/>
									</FormControl>
									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											Test Diet cost/lb (AS FED)
										</FormLabel>
										<InputGroup>
											{watch('testDietCost') ? (
												<InputLeftAddon
													p={'0 2px 0 0'}
													border={'none'}
													borderBottom={`1px solid ${colors.gray}`}
													borderRadius={'0'}>
													$
												</InputLeftAddon>
											) : (
												''
											)}
											<Input
												{...inputVerticalStyles}
												marginRight={'10px'}
												{...register('testDietCost', {
													required: true,
												})}
												type="number"
												placeholder={'$0,00'}
											/>
										</InputGroup>
									</FormControl>
									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											NEg
										</FormLabel>
										<Input
											{...inputVerticalStyles}
											{...register('neg', {
												required: true,
											})}
											type="number"
											placeholder={'Enter NEg'}
										/>
									</FormControl>
									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											Cleanout EID Tag #1
										</FormLabel>
										<Input
											{...inputVerticalStyles}
											{...register(
												'cleanOutEidTagFirst',
												{
													required: true,
												},
											)}
											placeholder={
												'Enter Cleanout EID Tag #1'
											}
										/>
									</FormControl>
								</Flex>

								<Flex
									width={'532px'}
									flexDirection={'column'}
									gap={'19px'}>
									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											Bin Settings
										</FormLabel>
										{data?.value.length ? (
											<Controller
												name="binSettings"
												control={control}
												render={({
													field: { onChange, value },
												}) => (
													<CustomSelect
														value={value}
														onChange={onChange}
														options={data.value.map(
															it => {
																return {
																	label: it.name,
																	value: String(
																		it.id,
																	),
																};
															},
														)}
														triggerProps={{
															padding: '2px',
															height: '40px',
															fontSize: '16px',
															maxW: '207px',
														}}
														placeholder={
															'Select or search Bin Settings'
														}
													/>
												)}
											/>
										) : null}
									</FormControl>
									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											Test Finish (projected)
										</FormLabel>
										<Controller
											name="testFinish"
											control={control}
											render={({
												field: { onChange, value },
											}) => (
												<CustomDatePicker
													showTime={false}
													className="pre-survey-datepicker"
													onChange={onChange}
													value={value}
													errorMsg={
														errors.testFinish
															?.message as string
													}
													includeDateIntervals={
														allowedIntervals
													}
													placeholder={
														'Enter Test Finish Date'
													}
												/>
											)}
										/>
									</FormControl>

									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											Test Dry Matter
										</FormLabel>
										<InputGroup>
											{watch('testDryMatter') ? (
												<InputLeftAddon
													p={'0 2px 0 0'}
													border={'none'}
													borderBottom={`1px solid ${colors.gray}`}
													borderRadius={'0'}>
													%
												</InputLeftAddon>
											) : (
												''
											)}
											<Input
												{...inputVerticalStyles}
												marginRight={'10px'}
												type="number"
												{...register('testDryMatter', {
													required: true,
												})}
												placeholder={'%0,00'}
											/>
										</InputGroup>
									</FormControl>

									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											NEm
										</FormLabel>
										<Input
											{...inputVerticalStyles}
											type="number"
											{...register('nem', {
												required: true,
											})}
											placeholder={'Enter NEm'}
										/>
									</FormControl>

									<FormControl
										{...formControlHorizontalStyles}>
										<FormLabel {...labelVerticalStyles}>
											Cleanout EID Tag #2
										</FormLabel>
										<Input
											{...inputVerticalStyles}
											{...register(
												'cleanOutEidTagSecond',
												{
													required: true,
												},
											)}
											placeholder={
												'Enter Cleanout EID Tag #2'
											}
										/>
									</FormControl>
								</Flex>
							</Flex>
						</Flex>
						{/*</Flex>*/}
						<Flex gap={'24px'} p={'30px'}>
							<Button variant={'secondary'} onClick={onClose}>
								Cancel
							</Button>
							<Button
								variant={'primary'}
								isDisabled={isDisabled}
								type={'submit'}
								isLoading={createTest.isPending}
								// onClick={() => {
								// 	handleBinSettingUpdate(selectedBinSetting);
								// }}
							>
								Save
							</Button>
						</Flex>
					</Flex>
				</form>
			</ModalContent>
		</Modal>
	);
};
