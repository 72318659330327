import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../constants';
import { ApiResponse, AnimalRanchoTestSelectorItem } from '../../types';
import apiBase from '../../api/api.base';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

interface IPayload {
	search?: string;
	isEnabled?: boolean;
	testCycleId?: number;
}

export const useGetRanchoAnimalsTestOptions = ({
	search,
	isEnabled,
}: IPayload) => {
	const { id: testCycleId } = useParams();
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useQuery<ApiResponse<AnimalRanchoTestSelectorItem[]>>({
		queryKey: [QueryKeys.GET_RANCHO_ANIMALS_TEST_OPTIONS, search],
		queryFn: () =>
			apiBase.get(
				`/api/selectors/ranchos/${ranchoId}/test-cycles/${testCycleId}/animals`,
				{ search },
			),
		refetchOnWindowFocus: false,
		enabled: isEnabled,
	});
};
