import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { ApiResponse } from '../../types';
import apiBase from '../../api/api.base';
import { Alerter } from '../../utils';
import { useParams } from 'react-router-dom';

interface UseCreateTestArgs {
	successCb?: VoidFunction;
}

export const useUpdateBinPreSurveySetting = ({
	successCb,
}: UseCreateTestArgs) => {
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	const { id } = useParams();
	return useMutation<ApiResponse<number>, unknown, { settingId: number }>({
		mutationFn: payload =>
			apiBase.patch(
				`/api/ranchos/${ranchoId}/test-cycles/${id}/setting`,
				payload,
			),
		onSuccess: response => {
			if (!response.success) {
				Alerter.error(response.errors?.[0]?.message);
				return;
			}
			successCb?.();
			Alerter.success('Bin Setting has been changed successfully');
		},
	});
};
