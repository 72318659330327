import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../../constants';
import {
	UpdateAnimalTestPayload,
	ApiResponseEmpty,
	ApiResponse,
	AnimalTest,
} from '../../types';
import apiBase from '../../api/api.base';
import { Alerter } from '../../utils';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

export const useUpdateTestCyclesAnimal = () => {
	const queryClient = useQueryClient();
	const { id: testCycleId } = useParams();

	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);

	return useMutation<ApiResponseEmpty, unknown, UpdateAnimalTestPayload>({
		mutationFn: payload => {
			const { animalInTestCycleId, ...bodyPayload } = payload;
			return apiBase.patch(
				`/api/ranchos/${ranchoId}/test-cycles/${testCycleId}/animals/${animalInTestCycleId}/update`,
				bodyPayload,
			);
		},
		onSuccess: (data, variables) => {
			if (!data.success) {
				Alerter.error(data.errors?.[0]?.message);
				return;
			}
			Alerter.success(
				data.messages?.[0]?.message || 'Animal information was updated',
			);
			const {
				animalInTestCycleId,
				contemporary,
				eidFull,
				eidNotes,
				eidNumber,
				eidTruncated,
			} = variables;

			queryClient.setQueryData<ApiResponse<AnimalTest> | undefined>(
				[
					QueryKeys.GET_TEST_CYCLES_ANIMAL,
					testCycleId,
					animalInTestCycleId,
				],
				oldData => {
					if (!oldData) {
						return oldData;
					}

					const restPayload = {
						eid: eidNumber,
						eidFull,
						eidTruncated,
						eidNote: eidNotes,
						contemporary,
					};

					return {
						...oldData,
						value: {
							...oldData.value,
							...restPayload,
						},
					};
				},
			);
			queryClient.invalidateQueries({
				queryKey: [QueryKeys.GET_TEST_CYCLES_ANIMALS],
			});
		},
	});
};
