import React from 'react';
import {
	Button,
	Flex,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
} from '@chakra-ui/react';
import { CustomDatePicker, CustomSelect, InputText } from '../../components';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { AnimalPayload, DnaStatus, GenderStatus } from '../../types';
import { useCreateAnimals } from '../../query-hooks';
import { Alerter } from '../../utils';
import { format } from 'date-fns';

interface AddAnimalModalProps {
	isOpen: boolean;
	onClose: () => void;
	refetchAnimals: (refetch: boolean) => void;
}
interface AddAnimalFormValues {
	ranchTag: string;
	regNumber: number;
	birthday: Date;
	sex: string;
	dna: string;
	sire: string;
	dam: string;
}

export const AddAnimalModal: React.FC<AddAnimalModalProps> = ({
	isOpen,
	onClose,
	refetchAnimals,
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
		reset,
	} = useForm<AddAnimalFormValues>({
		defaultValues: {
			ranchTag: '',
			regNumber: undefined,
			birthday: new Date(),
			sex: '',
			dna: '',
			sire: '',
			dam: '',
		},
	});

	const createAnimalsMutation = useCreateAnimals();

	const watchFields = watch([
		'ranchTag',
		'regNumber',
		'birthday',
		'sex',
		'dna',
		'sire',
		'dam',
	]);
	const isDisabled = !watchFields.every(field => field);

	const onSubmit: SubmitHandler<AddAnimalFormValues> = data => {
		const birthdayDate = new Date(data.birthday);
		const formattedBirthday = format(birthdayDate, 'yyyy-MM-dd');
		const dna = DnaStatus[data.dna as keyof typeof DnaStatus];
		const sex = GenderStatus[data.sex as keyof typeof GenderStatus];

		const payload: AnimalPayload = {
			...data,
			birthday: formattedBirthday,
			dna: dna,
			sex: sex,
		};

		createAnimalsMutation.mutate(payload, {
			onSuccess: response => {
				if (response.success) {
					Alerter.success('The animal was added successfully');
					refetchAnimals(true);
					reset();
					onClose();
				}
			},
		});
	};

	const handleClose = () => {
		reset();
		onClose();
	};

	return (
		<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent minH={'472px'} minW={'486'} borderRadius={'24px'}>
				<ModalHeader
					display={'flex'}
					justifyContent={'center'}
					p={'32px 32px 8px'}>
					<Text
						variant={'B02'}
						fontSize={'20px'}
						fontWeight={600}
						color={'darker'}>
						Add Animal
					</Text>
				</ModalHeader>
				<Flex flexDirection={'column'} padding={'32px'}>
					<form onSubmit={handleSubmit(onSubmit)}>
						<Flex flexDirection={'column'} gap={'24px'}>
							<VStack
								spacing={'24px'}
								display={'grid'}
								gridTemplateColumns={'1fr 1fr'}>
								<InputText
									label={'Ranch Tag'}
									variant={'flushed'}
									placeholder={'Enter Ranch Tag'}
									errorMsg={
										errors.ranchTag?.message as string
									}
									{...register('ranchTag', {
										required: 'Ranch Tag  is required',
									})}></InputText>
								<InputText
									label={'Reg #'}
									variant={'flushed'}
									type="number"
									placeholder={'Enter Reg #'}
									errorMsg={
										errors.regNumber?.message as string
									}
									{...register('regNumber', {
										required: 'Reg # is required',
									})}></InputText>
								<Controller
									name="birthday"
									control={control}
									render={({
										field: { onChange, value },
									}) => (
										<CustomDatePicker
											label="BD"
											showTime={false}
											placeholder="Enter BD"
											className="animals-bd"
											onChange={onChange}
											value={value}
											errorMsg={
												errors.birthday
													?.message as string
											}
										/>
									)}
								/>
								<Controller
									name="sex"
									control={control}
									render={({
										field: { onChange, value },
									}) => (
										<CustomSelect
											label="Sex"
											options={[
												{
													label: 'B',
													value: 'Bull',
												},
												{
													label: 'C',
													value: 'Cow',
												},
											]}
											zIndex={3}
											placeholder={'Choose sex'}
											onChange={onChange}
											value={value}
											errorMsg={
												errors.sex?.message as string
											}
										/>
									)}
								/>
								<Controller
									name="dna"
									control={control}
									render={({
										field: { onChange, value },
									}) => (
										<CustomSelect
											label={'DNA collected?'}
											options={[
												{
													label: 'yes',
													value: 'Yes',
												},
												{
													label: 'no',
													value: 'No',
												},
											]}
											placeholder={'Choose DNA collected'}
											errorMsg={
												errors.dna?.message as string
											}
											onChange={onChange}
											value={value}
										/>
									)}
								/>
								<InputText
									label={'Sire'}
									variant={'flushed'}
									placeholder={'Enter Sire'}
									h={'50px'}
									errorMsg={errors.sire?.message as string}
									{...register('sire', {
										required: 'Sire is required',
									})}></InputText>
							</VStack>
							<InputText
								label={'Dam'}
								variant={'flushed'}
								placeholder={'Enter Dam'}
								errorMsg={errors.dam?.message as string}
								{...register('dam', {
									required: 'Dam is required',
								})}></InputText>
						</Flex>

						<Flex display={'flex'} gap={'32px'} p={'0'} mt={'40px'}>
							<Button
								variant={'secondary'}
								fontFamily={'Roboto'}
								onClick={handleClose}>
								Cancel
							</Button>
							<Button
								fontFamily={'Roboto'}
								variant={'primary'}
								type={'submit'}
								isDisabled={isDisabled}>
								Save
							</Button>
						</Flex>
					</form>
				</Flex>
			</ModalContent>
		</Modal>
	);
};
