import { ApiResponseEmpty } from '../../types';
import apiBase from '../../api/api.base';

type VerifyTokenPayload = {
	email: string;
	verificationCode: string;
};

export const useVerificationEmailCheck = async (
	payload: VerifyTokenPayload,
): Promise<boolean> => {
	const response: ApiResponseEmpty = await apiBase.post(
		'/api/auth/verification/email/check',
		payload,
	);

	if (!response.success) {
		return false;
	}
	return true;
};
