import { useMutation } from '@tanstack/react-query';
import { AnimalPayload, ApiResponse } from '../../types';
import apiBase from '../../api/api.base';
import { Alerter } from '../../utils';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

export const useUpdateAnimals = () => {
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useMutation<ApiResponse<string>, unknown, AnimalPayload>({
		mutationFn: payload =>
			apiBase.post(`/api/ranchos/${ranchoId}/animals/update`, payload),
		onSuccess: response => {
			if (!response.success) {
				Alerter.error(response.errors?.[0]?.message);
				return;
			}
		},
	});
};
