import React from 'react';
import { Box, HStack, IconButton, Td, Tr } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import {
	CustomDatePicker,
	CustomTextarea,
	Icon,
	InputText,
} from '../../../../../components';
import { colors } from '../../../../../theme/colors';
import { onlyDigitsAndDot, toPayloadDate } from '../../../../../utils';
import { useCreateDailyFeedDelivery } from '../../../../../query-hooks';
import {
	DailyFeedDeliveryFormType,
	dailyFeedDeliveryResolver,
} from '../../../../../validation';

interface INewDailyFeedDeliveryRowProps {
	onCancel: VoidFunction;
}

export const NewDailyFeedDeliveryRow: React.FC<
	INewDailyFeedDeliveryRowProps
> = ({ onCancel }) => {
	const createDailyFeedDeliveryMutation = useCreateDailyFeedDelivery();

	const {
		control,
		formState: { errors },
		handleSubmit,
	} = useForm<DailyFeedDeliveryFormType>({
		resolver: dailyFeedDeliveryResolver,
	});

	const onSubmit = (data: DailyFeedDeliveryFormType) => {
		const { note, date, feedDispensed } = data;
		createDailyFeedDeliveryMutation.mutate(
			{
				note: note || '',
				date: toPayloadDate(date),
				feedDispensed: feedDispensed ? +feedDispensed : 0,
			},
			{
				onSuccess(data) {
					if (data.success) {
						onCancel();
					}
				},
			},
		);
	};

	return (
		<Tr>
			<Td w="100px" verticalAlign="top"></Td>
			<Td w="140px" verticalAlign="top">
				<HStack align="flex-start">
					<Controller
						control={control}
						name="date"
						render={({ field }) => (
							<CustomDatePicker
								showTime={false}
								value={field.value}
								onChange={field.onChange}
								className="row-field-low"
								placeholder="Select a date"
								errorMsg={errors.date?.message as string}
							/>
						)}
					/>
					<Box boxSize="16px" pt="4px">
						<Icon name="calendar" width={'16px'} height={'16px'} />
					</Box>
				</HStack>
			</Td>
			<Td w="180px" verticalAlign="top">
				<Controller
					name="feedDispensed"
					control={control}
					defaultValue=""
					render={({ field: { onChange, value } }) => (
						<InputText
							onChange={ev => {
								const inputValue = onlyDigitsAndDot(
									ev.target.value,
								);
								onChange(inputValue);
							}}
							value={value}
							placeholder="Enter feed dispensed"
							height="20px"
							py="4px"
							h="24px"
							fontSize="12px"
							lineHeight="16px"
							_placeholder={{
								fontSize: '12px',
								lineHeight: '16px',
								fontWeight: 500,
								color: 'darkLight',
							}}
							_focusVisible={{
								borderBottomColor: 'primary',
							}}
							errorMsg={errors.feedDispensed?.message as string}
						/>
					)}
				/>
			</Td>
			<Td minW="170px" verticalAlign="top">
				<Controller
					name="note"
					control={control}
					defaultValue=""
					render={({ field: { onChange, value } }) => (
						<CustomTextarea
							minHeight={24}
							placeholder="Enter Notes"
							padding="4px 8px"
							value={value}
							fontSize="12px"
							lineHeight="14px"
							onChange={onChange}
							errorMsg={errors.note?.message as string}
						/>
					)}
				/>
			</Td>
			<Td verticalAlign="top" style={{ width: '90px' }}>
				<HStack justify="space-between" maxW="65px" mx="auto">
					<IconButton
						aria-label="Save daily feed delivery"
						w="20px"
						h="20px"
						minW="20px"
						isLoading={createDailyFeedDeliveryMutation.isPending}
						onClick={handleSubmit(onSubmit)}>
						<Icon
							type="submit"
							name="save"
							width="20px"
							height="20px"
							color={colors.primary}
						/>
					</IconButton>
					<IconButton
						aria-label="Cancel creating daily feed delivery"
						w="20px"
						h="20px"
						minW="20px"
						onClick={onCancel}>
						<Icon
							name="trash"
							width="20px"
							height="20px"
							color={colors.error}
						/>
					</IconButton>
				</HStack>
			</Td>
		</Tr>
	);
};
