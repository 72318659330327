import React, { useEffect } from 'react';
import {
	Box,
	Button,
	Flex,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
} from '@chakra-ui/react';
import {
	CustomDatePicker,
	CustomSelect,
	InputText,
	Loader,
} from '../../components';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useUpdateAnimals, useGetAnimalsGetInfo } from '../../query-hooks';
import { AnimalPayload, DnaStatus, GenderStatus } from '../../types';
import { format } from 'date-fns';
import { Alerter } from '../../utils';

interface EditAnimalModalProps {
	isOpen: boolean;
	onClose: () => void;
	animalId?: number;
	refetchAnimals?: (refetch: boolean) => void;
}
interface EditAnimalFormValues {
	ranchTag: string;
	regNumber: number;
	birthday: Date;
	sex: string;
	dna: string;
	sire: string;
	dam: string;
}

export const EditAnimalModal: React.FC<EditAnimalModalProps> = ({
	isOpen,
	onClose,
	animalId,
	refetchAnimals,
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
		reset,
	} = useForm<EditAnimalFormValues>();

	const { data: animalsGetInfoData, isLoading } =
		useGetAnimalsGetInfo(animalId);

	const animalsGetInfo = animalsGetInfoData?.value;

	const editAnimalsMutation = useUpdateAnimals();

	const onSubmit: SubmitHandler<EditAnimalFormValues> = data => {
		const birthdayDate = new Date(data.birthday);
		const formattedBirthday = format(birthdayDate, 'yyyy-MM-dd');
		const dna = DnaStatus[data.dna as keyof typeof DnaStatus];
		const sex = GenderStatus[data.sex as keyof typeof GenderStatus];

		const payload: AnimalPayload = {
			...data,
			birthday: formattedBirthday,
			dna: dna,
			sex: sex,
		};
		editAnimalsMutation.mutate(payload, {
			onSuccess: response => {
				if (response.success) {
					Alerter.success(
						'The animal’s information has been edited successfully',
					);
					refetchAnimals && refetchAnimals(true);
					onClose();
				}
			},
		});
	};

	const watchFields = watch([
		'ranchTag',
		'regNumber',
		'birthday',
		'sex',
		'dna',
		'sire',
		'dam',
	]);
	const isDisabled = !watchFields.every(field => field);

	const getGenderLabel = (genderValue: GenderStatus) => {
		switch (genderValue) {
			case GenderStatus.Bull:
				return 'Bull';
			case GenderStatus.Cow:
				return 'Cow';
			default:
				return 'Bull';
		}
	};

	const getDnaLabel = (dnaValue: DnaStatus) => {
		switch (dnaValue) {
			case DnaStatus.Yes:
				return 'Yes';
			case DnaStatus.No:
				return 'No';
			default:
				return 'Yes';
		}
	};

	useEffect(() => {
		if (animalsGetInfo) {
			reset({
				ranchTag: animalsGetInfo.ranchTag,
				regNumber: animalsGetInfo.regNumber,
				birthday: new Date(animalsGetInfo.birthday),
				sex: getGenderLabel(animalsGetInfo.sex),
				dna: getDnaLabel(animalsGetInfo.dna),
				sire: animalsGetInfo.sire,
				dam: animalsGetInfo.dam,
			});
		}
	}, [animalsGetInfo, reset]);

	return (
		<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent minH={'472px'} minW={'486'} borderRadius={'24px'}>
				<ModalHeader
					display={'flex'}
					justifyContent={'center'}
					p={'32px 32px 24px'}>
					<Text
						variant={'B03'}
						fontWeight={600}
						fontSize={'20px'}
						color={'darker'}>
						Edit Animal
					</Text>
				</ModalHeader>
				{isLoading ? (
					<Box margin={'auto'}>
						<Loader centerProps={{ width: '100%' }} />
					</Box>
				) : (
					<Flex
						flexDirection={'column'}
						gap={'40px'}
						padding={'32px'}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<Flex flexDirection={'column'} gap={'24px'}>
								<VStack
									spacing={'24px'}
									display={'grid'}
									gridTemplateColumns={'1fr 1fr'}>
									<InputText
										label={'Ranch Tag'}
										isDisabled={true}
										variant={'flushed'}
										placeholder={'Enter Ranch Tag'}
										errorMsg={
											errors.ranchTag?.message as string
										}
										{...register('ranchTag', {
											required: 'Ranch Tag  is required',
										})}></InputText>
									<InputText
										label={'Reg #'}
										variant={'flushed'}
										type="number"
										placeholder={'Enter Reg #'}
										errorMsg={
											errors.regNumber?.message as string
										}
										{...register('regNumber', {
											required: 'Reg # is required',
										})}></InputText>
									<Controller
										name="birthday"
										control={control}
										render={({
											field: { onChange, value },
										}) => (
											<CustomDatePicker
												label="BD"
												showTime={false}
												className="animals-bd"
												onChange={onChange}
												value={value || new Date()}
												errorMsg={
													errors.birthday
														?.message as string
												}
											/>
										)}
									/>
									<Controller
										name="sex"
										control={control}
										render={({
											field: { onChange, value },
										}) => (
											<CustomSelect
												label="Sex"
												options={[
													{
														label: 'B',
														value: 'Bull',
													},
													{
														label: 'C',
														value: 'Cow',
													},
												]}
												zIndex={3}
												placeholder={'Choose sex'}
												onChange={onChange}
												value={value || 'Bull'}
												errorMsg={
													errors.sex
														?.message as string
												}
											/>
										)}
									/>
									<Controller
										name="dna"
										control={control}
										render={({
											field: { onChange, value },
										}) => (
											<CustomSelect
												label={'DNA collected?'}
												options={[
													{
														label: 'yes',
														value: 'Yes',
													},
													{
														label: 'no',
														value: 'No',
													},
												]}
												placeholder={
													'Choose DNA collected'
												}
												errorMsg={
													errors.dna
														?.message as string
												}
												onChange={onChange}
												value={value || 'Yes'}
											/>
										)}
									/>
									<InputText
										label={'Sire'}
										variant={'flushed'}
										placeholder={'Enter Sire'}
										h={'50px'}
										errorMsg={
											errors.sire?.message as string
										}
										{...register('sire', {
											required: 'Sire is required',
										})}></InputText>
								</VStack>
								<InputText
									label={'Dam'}
									variant={'flushed'}
									placeholder={'Enter Dam'}
									errorMsg={errors.dam?.message as string}
									{...register('dam', {
										required: 'Dam is required',
									})}></InputText>
							</Flex>

							<Flex
								display={'flex'}
								gap={'32px'}
								p={'0'}
								mt={'40px'}>
								<Button
									variant={'secondary'}
									fontFamily={'Roboto'}
									onClick={onClose}>
									Cancel
								</Button>
								<Button
									variant={'primary'}
									fontFamily={'Roboto'}
									type={'submit'}
									isDisabled={isDisabled}>
									Save
								</Button>
							</Flex>
						</form>
					</Flex>
				)}
			</ModalContent>
		</Modal>
	);
};
