import React, { PropsWithChildren } from 'react';
import {
	Box,
	Table,
	TableContainer,
	Tbody,
	Th,
	Thead,
	Tr,
	chakra,
} from '@chakra-ui/react';
import { TableRow } from './components';
import { useGetReportFinalAnalysis } from '../../../../query-hooks';
import { Loader } from '../../../../components';
import { EmptyDataMessage } from '../../components';
import { nowrapTableHeadersStyles } from '../../constants';
import { trickyTableDividerStyles } from '../../../../constants';
import { ReportsFinalAnalysisTableHeaders } from './constants';

export const FinalAnalysis: React.FC<PropsWithChildren> = () => {
	const { data, isLoading } = useGetReportFinalAnalysis();

	if (isLoading) {
		return <Loader centerHeight="calc(100vh - 320px)" />;
	}

	if (!isLoading && !data?.value?.length) {
		return <EmptyDataMessage />;
	}

	const items = data?.value || [];

	return (
		<Box
			backgroundColor="light"
			boxShadow="0px 0px 15px rgba(0, 0, 0, 0.10)"
			borderRadius="12px"
			padding="16px">
			<TableContainer h="calc(100vh - 320px)" bg="white" overflowY="auto">
				<Table
					w="100%"
					minW="200px"
					variant="custom"
					sx={{
						'tr > th, tr > td': {
							backgroundColor: 'white',
						},
						'tr > th:first-of-type, tr > td:first-of-type': {
							position: 'sticky',
							left: 0,
						},
						'tr > th:nth-of-type(2), tr > td:nth-of-type(2)': {
							position: 'sticky',
							left: '114px',
						},
					}}>
					<Thead>
						<Tr>
							{ReportsFinalAnalysisTableHeaders.map(
								(el, index) => (
									<Th
										key={index}
										position={
											el.includeRightBorder
												? 'relative'
												: 'static'
										}
										style={{
											...nowrapTableHeadersStyles,
											paddingLeft: '20px',
											paddingRight: '20px',
										}}>
										{el.label}
										{el.secondRow ? (
											<React.Fragment>
												<chakra.br /> {el.secondRow}
											</React.Fragment>
										) : null}
										{el.includeRightBorder ? (
											<Box
												{...trickyTableDividerStyles}
											/>
										) : null}{' '}
									</Th>
								),
							)}
						</Tr>
					</Thead>
					<Tbody>
						{items.map((el, index) => (
							<TableRow key={index} {...el} />
						))}
					</Tbody>
				</Table>
			</TableContainer>
		</Box>
	);
};
