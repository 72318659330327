export const ReportsAnimalInfoTableHeaders = [
	'Ranch Tag',
	'EID',
	'Reg #',
	'BD',
	'Sex',
	'DNA collected?',
	'Sire',
	'Dam',
	'EID full',
	'EID truncated',
	'EID Notes',
	'Status',
	'Contemporary Group',
];
