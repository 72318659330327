import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
	Box,
	Button,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	useDisclosure,
	Center,
	Text,
	IconButton,
} from '@chakra-ui/react';
import { DeleteConfirmPopup, Icon, Loader, Pagination } from '../../components';
import { colors } from '../../theme/colors';
import { AddAnimalModal, EditAnimalModal } from '../../modals';
import { useDeleteAnimals, useGetAnimals } from '../../query-hooks';
import { DnaStatus, GenderStatus } from '../../types';
import { Alerter } from '../../utils';

const pageSize = 5;

export const Animals: React.FC<PropsWithChildren> = () => {
	const [refetchAnimals, setRefetchAnimals] = useState(false);
	const [selectedAnimalId, setSelectedAnimalId] = useState<number | null>(
		null,
	);
	const [page, setPage] = useState(1);

	const {
		isOpen: isAddAnimalModalOpen,
		onOpen: onAddAnimalModalOpen,
		onClose: onAddAnimalModalClose,
	} = useDisclosure();
	const {
		isOpen: isEditAnimalModalOpen,
		onOpen: onEditAnimalModalOpen,
		onClose: onEditAnimalModalClose,
	} = useDisclosure();

	const { data: animalsData, isLoading, refetch } = useGetAnimals(page);

	const animals = animalsData?.value?.items || [];
	const totalCount = animalsData?.value?.count || 0;

	const { mutate: deleteAnimals, isPending } = useDeleteAnimals();

	const deleteAnimal = (animalId: number) => {
		deleteAnimals(animalId, {
			onSuccess: response => {
				if (response.success) {
					Alerter.success('The animal was deleted');
					if (animals.length === 1 && page > 1) {
						setPage(page - 1);
					} else {
						refetch();
					}
				}
			},
		});
	};

	const onPageChange = (page: number) => {
		setPage(page);
	};

	const handleEditAnimal = (id: number) => {
		setSelectedAnimalId(id);
		onEditAnimalModalOpen();
	};

	useEffect(() => {
		if (refetchAnimals) {
			refetch();
		}
		setRefetchAnimals(false);
	}, [refetchAnimals, refetch]);

	if (isLoading) {
		return <Loader centerProps={{ width: '100%' }} />;
	}

	return (
		<Box position={'relative'} width={'100%'}>
			<TableContainer
				backgroundColor={'light'}
				boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
				borderRadius={'12px'}
				padding={'16px'}
				w={'100%'}
				minHeight={'100%'}
				overflowY={'auto'}
				display={'flex'}
				flexDirection={'column'}
				justifyContent={'space-between'}>
				<Table variant="custom">
					<Thead>
						<Tr>
							<Th>Ranch Tag</Th>
							<Th>Reg #</Th>
							<Th>BD</Th>
							<Th>Sex</Th>
							<Th>DNA collected?</Th>
							<Th>Sire</Th>
							<Th>Dam</Th>
							<Th>Actions</Th>
						</Tr>
					</Thead>
					<Tbody>
						{animals?.length ? (
							animals.map(animal => (
								<Tr key={animal.id}>
									<Td verticalAlign={'top'}>
										{animal.ranchTag}
									</Td>
									<Td verticalAlign={'top'}>
										{animal.regNumber}
									</Td>
									<Td verticalAlign={'top'}>
										{animal.birthday}
									</Td>
									<Td verticalAlign={'top'}>
										{GenderStatus[animal.sex]}
									</Td>
									<Td verticalAlign={'top'}>
										{DnaStatus[animal.dna]}
									</Td>
									<Td
										textTransform={'unset'}
										maxW={'196px'}
										verticalAlign={'top'}>
										{animal.sire}
									</Td>
									<Td
										verticalAlign={'top'}
										textTransform={'unset'}
										maxW={'196px'}>
										{animal.dam}
									</Td>
									<Td verticalAlign={'top'}>
										<Button
											padding={0}
											onClick={() =>
												handleEditAnimal(animal.id)
											}>
											<Icon
												name="small-pen"
												width={'20px'}
												height={'20px'}
												color={`${colors.dark}`}
											/>
										</Button>
										<DeleteConfirmPopup
											title="Are you sure you want to delete that animal?"
											onConfirm={() =>
												deleteAnimal(animal.id)
											}
											confirmButtonProps={{
												isLoading: isPending,
											}}>
											<IconButton
												padding={0}
												aria-label="Delete animal"
												w="20px"
												h="20px"
												minW="20px">
												<Icon
													name="trash"
													width={'20px'}
													height={'20px'}
													color={colors.error}
												/>
											</IconButton>
										</DeleteConfirmPopup>
									</Td>
								</Tr>
							))
						) : (
							<Tr>
								<Td colSpan={8}>
									<Center h="300px">
										<Text variant="B02" color="darkLight">
											No data yet
										</Text>
									</Center>
								</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
				<Pagination
					totalCount={totalCount}
					pageSize={pageSize}
					currentPage={page}
					onPageChange={onPageChange}
				/>
			</TableContainer>

			<Button
				variant={'primary'}
				position={'fixed'}
				fontWeight={400}
				bottom={'70px'}
				right={'65px'}
				width={'165px'}
				gap={'8px'}
				onClick={onAddAnimalModalOpen}>
				<Icon name="add" width={'20px'} height={'20px'} color="light" />
				Add Animal
			</Button>
			<AddAnimalModal
				onClose={onAddAnimalModalClose}
				refetchAnimals={setRefetchAnimals}
				isOpen={isAddAnimalModalOpen}
			/>
			<EditAnimalModal
				onClose={onEditAnimalModalClose}
				refetchAnimals={setRefetchAnimals}
				isOpen={isEditAnimalModalOpen}
				animalId={selectedAnimalId as number}
			/>
		</Box>
	);
};
