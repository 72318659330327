import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const editAnimalInfoSchema = yup.object().shape({
	eidNumber: yup.string(),
	eidFull: yup.string(),
	eidTruncated: yup.string(),
	eidNotes: yup.string(),
	contemporary: yup.string(),
});

export type EditAnimalInfoFormType = yup.InferType<typeof editAnimalInfoSchema>;

export const editAnimalInfoResolver = yupResolver(editAnimalInfoSchema);
