import React, { useState } from 'react';
import {
	Button,
	Flex,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import dayjs from 'dayjs';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { CustomDatePicker } from '../../components';
import { useCreateMeasurementSession } from '../../query-hooks';

interface AddAnimalModalProps {
	isOpen: boolean;
	onClose: () => void;
	// onAddWeight: (wtDate: Date) => void;
	sessionsLength?: number;
}
interface AddStartWeightValues {
	wtDate: Date;
}

export const AddStartWeightModal: React.FC<AddAnimalModalProps> = ({
	isOpen,
	onClose,
	// onAddWeight,
	sessionsLength,
}) => {
	const [isCalenderOpen, setIsCalenderOpen] = useState(false);
	const toggleCalender = (open: boolean) => setIsCalenderOpen(open);

	const createMeasurementSessionMutation = useCreateMeasurementSession();

	const {
		handleSubmit,
		control,
		watch,
		formState: { errors },
	} = useForm<AddStartWeightValues>();

	const isDisabled = watch('wtDate');

	const onSubmit: SubmitHandler<AddStartWeightValues> = data => {
		// onAddWeight(data.wtDate);
		createMeasurementSessionMutation.mutate(
			{
				date: dayjs(data.wtDate).format('YYYY-MM-DD'),
			},
			{
				onSettled(data) {
					if (data?.success) {
						onClose();
					}
				},
			},
		);
	};

	return (
		<Modal
			blockScrollOnMount={false}
			isOpen={isOpen}
			onClose={onClose}
			autoFocus={false}>
			<ModalOverlay />
			<ModalContent
				minW={'538px'}
				minH={'288px'}
				alignItems={'center'}
				borderRadius={'12px'}>
				<ModalHeader
					display={'flex'}
					justifyContent={'center'}
					p={'32px 32px 24px'}>
					<Text variant={'B03'} color={'dark'}>
						Add {sessionsLength === 0 ? 'Start' : ''} Weight
					</Text>
				</ModalHeader>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Controller
						name="wtDate"
						control={control}
						render={({ field: { onChange, value } }) => (
							<CustomDatePicker
								showTime={false}
								onChange={onChange}
								toggleCalender={toggleCalender}
								label={'Wt Date'}
								value={value}
								errorMsg={errors.wtDate?.message as string}
							/>
						)}
					/>
					<Flex gap={'24px'} p={'32px'} minW={'422px'}>
						<Button variant={'secondary'} onClick={onClose}>
							Cancel
						</Button>
						<Button
							variant={'primary'}
							type={'submit'}
							isDisabled={!isDisabled}
							isLoading={
								createMeasurementSessionMutation.isPending
							}>
							Add
						</Button>
					</Flex>
				</form>
			</ModalContent>
		</Modal>
	);
};
