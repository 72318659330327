/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import {
	Button,
	Flex,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	Center,
} from '@chakra-ui/react';
import { colors } from '../../../theme/colors';
import {
	CustomDatePicker,
	CustomSort,
	Icon,
	Pagination,
	Loader,
} from '../../../components';
import { useGetAnalysisData } from '../../../query-hooks';
import { AllFilesRow } from './AllFilesRow';
import { convertStringToDate } from '../../../utils';
import { TestDatesSelector } from '../../../types';

interface AllFilesCombinedModalProps {
	isOpen: boolean;
	onClose: () => void;
	testDates?: TestDatesSelector;
}

export const AllFilesCombinedModal: React.FC<AllFilesCombinedModalProps> = ({
	isOpen,
	onClose,
	testDates,
}) => {
	const [page, setPage] = useState(1);
	const [startDate, setStartDate] = useState<undefined | Date>();
	const [endDate, setEndDate] = useState<undefined | Date>();
	const [sortBy, setSortBy] = useState(1);
	const [orderBy, setOrderBy] = useState(1); // 1 - asc, 2 - desc
	const [queryDates, setQueryDates] = useState<{
		startDate?: Date;
		endDate?: Date;
	}>({
		startDate: undefined,
		endDate: undefined,
	});

	const isInitialDatesUpdatedRef = useRef(false);

	const resetState = () => {
		setPage(1);
		setStartDate(undefined);
		setEndDate(undefined);
		setSortBy(1);
		setOrderBy(1);
		setQueryDates({ startDate: undefined, endDate: undefined });
		isInitialDatesUpdatedRef.current = false;
	};

	const { data, isLoading } = useGetAnalysisData({
		isEnabled: isOpen && isInitialDatesUpdatedRef.current,
		page,
		startDate: queryDates.startDate,
		endDate: queryDates.endDate,
		sortBy,
		orderBy,
	});

	const onSetStartDate = useCallback(
		(date?: Date) => {
			setStartDate(date);

			if (endDate && date) {
				setQueryDates({ startDate: date, endDate });
				setPage(1);
			}

			if (!date) {
				setQueryDates({ startDate: undefined, endDate: undefined });
				setEndDate(undefined);
				setPage(1);
			}
		},
		[endDate],
	);

	const onSetEndDate = useCallback(
		(date?: Date) => {
			setEndDate(date);
			if (startDate && date) {
				setQueryDates({ startDate, endDate: date });
				setPage(1);
			}

			if (!date) {
				setQueryDates({ startDate: undefined, endDate: undefined });
				setStartDate(undefined);
				setPage(1);
			}
		},
		[startDate],
	);

	useEffect(() => {
		if (testDates && isOpen && !isInitialDatesUpdatedRef.current) {
			const dateOfTestStart = convertStringToDate(testDates.testStart);
			const dateOfTestFinish = convertStringToDate(testDates.testFinish);

			setStartDate(dateOfTestStart);
			setEndDate(dateOfTestFinish);
			setQueryDates({
				startDate: dateOfTestStart,
				endDate: dateOfTestFinish,
			});

			isInitialDatesUpdatedRef.current = true;
		}
		return () => {
			resetState();
		};
	}, [testDates, isOpen]);

	const { minDate, maxDate } = useMemo(() => {
		const { testStart, testFinish } = testDates || {};
		const minDate = testStart ? convertStringToDate(testStart) : undefined;
		const maxDate = testFinish
			? convertStringToDate(testFinish)
			: undefined;

		return { minDate, maxDate };
	}, [testDates]);

	return (
		<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				minW={'calc(100vw - 200px)'}
				borderRadius={'24px'}
				p={'32px'}>
				<ModalHeader display={'flex'} justifyContent={'center'}>
					<Text variant={'B03'} color={'dark'} fontSize={'20px'}>
						All Files Combined
					</Text>
					<Button
						width={'40px'}
						height={'40px'}
						border={`1px solid ${colors.primary}`}
						p={'0px'}
						onClick={onClose}
						position={'absolute'}
						right={'16px'}
						top={'40px'}
						borderRadius={'50%'}>
						<Icon
							name="close"
							color={colors.primary}
							width={'24px'}
							height={'24px'}
						/>
					</Button>
				</ModalHeader>
				<Flex alignItems={'center'} gap={'16px'} mb={'24px'}>
					<CustomSort
						placeholder="Sort"
						value={sortBy}
						options={[
							{
								label: 'Sort by Date',
								value: 1,
							},
							{
								label: 'Sort by Tag',
								value: 2,
							},
							{
								label: 'Sort by Bin',
								value: 3,
							},
						]}
						onChange={value => setSortBy(value as number)}
					/>
					<Button
						w={'48px'}
						h={'48px'}
						p={0}
						transform={`rotate(${orderBy === 2 ? '0' : '180deg'})`}
						onClick={() =>
							setOrderBy(prev => (prev === 1 ? 2 : 1))
						}>
						<Icon
							name="sort-arrow"
							color={colors.dark}
							width={'24px'}
							height={'24px'}
						/>
					</Button>
					<Flex gap={'8px'}>
						<CustomDatePicker
							showTime={false}
							calendarIconName="calendar-tiny"
							className="fake-button-left-icon fake-button-left-icon--no-margin-top"
							placeholder="Start Date"
							isClearable={true}
							value={startDate}
							onChange={onSetStartDate}
							minDate={minDate}
							maxDate={endDate || maxDate || new Date()}
							shouldCloseOnSelect={true}
						/>
						<CustomDatePicker
							placeholder="End Date"
							calendarIconName="calendar-tiny"
							className="fake-button-left-icon fake-button-left-icon--no-margin-top"
							isClearable={true}
							showTime={false}
							value={endDate}
							onChange={onSetEndDate}
							minDate={startDate || minDate || undefined}
							maxDate={maxDate || new Date()}
							shouldCloseOnSelect={true}
						/>
					</Flex>
				</Flex>
				<Flex minH={'calc(100vh - 400px)'} overflowY={'auto'}>
					<TableContainer
						display={'flex'}
						flexDirection={'column'}
						minW={'56px'}
						borderRight={'1px solid #89AB7D'}>
						<Table
							variant="custom"
							sx={{
								td: { borderBottom: 'none !important' },
								th: { borderBottom: 'none !important' },
								tr: { borderBottom: 'none !important' },
							}}>
							<Thead>
								<Tr>
									<Th>Tag</Th>
								</Tr>
							</Thead>
							<Tbody>
								{data?.value?.binsAnalysisResponse.map(
									(tag, index) => (
										<Tr key={index}>
											<Td>{tag.tag}</Td>
										</Tr>
									),
								)}
							</Tbody>
						</Table>
					</TableContainer>
					<TableContainer
						display={'flex'}
						flexDirection={'column'}
						maxW={'calc(100vw - 200px)'}>
						<Table
							variant="custom"
							sx={{
								td: {
									borderBottom: 'none !important',
									whiteSpace: 'nowrap !important',
								},
								th: {
									borderBottom: 'none !important',
									whiteSpace: 'nowrap',
								},
								tr: { borderBottom: 'none !important' },
							}}>
							<Thead>
								<Tr>
									<Th>Bin</Th>
									<Th>SEQ No.</Th>
									<Th>Final Consumption Start</Th>
									<Th>Final Consumption Stop</Th>
									<Th>Final Consumption Consumpt.</Th>
									<Th>Final Consumption Rate</Th>
									<Th>RFID Hits Duration</Th>
									<Th>RFID Hits Total</Th>
									<Th>RFID Hits Avg.</Th>
									<Th>Time Gaps Prior</Th>
									<Th>Time Gaps Post</Th>
									<Th>Weights Gaps Prior</Th>
									<Th>Weights Gaps Post</Th>
									{/* <Th>Alert</Th> */}
									<Th>Codes</Th>
									{/* <Th>Warning</Th> */}
									<Th>Code 110 Start</Th>
									<Th>Code 110 Stop</Th>
									<Th>Code 120 Exp. Consump</Th>
								</Tr>
							</Thead>
							<Tbody>
								{isLoading ? (
									<Tr>
										<Td colSpan={9}>
											<Loader centerHeight="400px" />
										</Td>
									</Tr>
								) : data?.value.binsAnalysisResponse?.length ===
								  0 ? (
									<Tr>
										<Td colSpan={9}>
											<Center h="300px">
												<Text
													variant="B02"
													textAlign={'center'}
													color="darkLight">
													No data yet
												</Text>
											</Center>
										</Td>
									</Tr>
								) : (
									data?.value.binsAnalysisResponse.map(
										(row, index) => (
											<AllFilesRow key={index} {...row} />
										),
									)
								)}
							</Tbody>
						</Table>
					</TableContainer>
				</Flex>
				<Pagination
					mt={4}
					totalCount={data?.value?.count || 0}
					currentPage={page}
					pageSize={15}
					onPageChange={val => setPage(val)}
				/>
			</ModalContent>
		</Modal>
	);
};
