import React, { CSSProperties } from 'react';
import { Box, Td, Tr } from '@chakra-ui/react';
import { trickyTableDividerStyles } from '../../../../../constants';

interface IFakeTableRowProps {
	rowStyles?: CSSProperties;
}

export const FakeTableRow: React.FC<IFakeTableRowProps> = ({ rowStyles }) => {
	return (
		<Tr
			style={{
				height: '47px',
				...rowStyles,
			}}>
			<Td p="8px" style={{ width: '114px' }}></Td>
			<Td p="8px" position="relative" style={{ width: '114px' }}>
				<Box {...trickyTableDividerStyles} />
			</Td>
		</Tr>
	);
};
