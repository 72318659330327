import React, { PropsWithChildren } from 'react';
import {
	Box,
	Text,
	HStack,
	StackDivider,
	Flex,
	IconButton,
} from '@chakra-ui/react';
import { Icon } from '../../components';
import { DeleteRowButton } from './DeleteRowButton';

interface IInputsWrapperProps extends PropsWithChildren {
	title: string;
	onDelete: VoidFunction;
	index: number;
	target: string;
	isEdit: boolean;
	isDisabled?: boolean;
}

export const InputsWrapper: React.FC<IInputsWrapperProps> = ({
	children,
	title,
	onDelete,
	index,
	target,
	isEdit,
	isDisabled,
}) => {
	return (
		<Box>
			<Text fontSize="16px" color="black" fontWeight={600} mb="8px">
				{title}
			</Text>
			<HStack
				spacing="8px"
				divider={<StackDivider borderColor="lightGray" />}>
				<Flex gap="16px">{children}</Flex>
				<Box>
					{isEdit ? (
						<DeleteRowButton
							isDisabled={index === 0 || !!isDisabled}
							target={target}
							onConfirm={onDelete}
						/>
					) : (
						<IconButton
							aria-label="Delete row"
							isDisabled={index === 0 || isDisabled}
							color={index === 0 ? 'gray' : 'error'}
							minW="auto"
							boxSize="24px"
							border="1px solid"
							borderColor="transparent"
							borderRadius="50%"
							onClick={onDelete}
							_hover={{
								bg: 'secondary',
								borderColor: 'primary',
							}}>
							<Icon
								name="trash"
								width="16px"
								height="16px"
								color="currentColor"
							/>
						</IconButton>
					)}
				</Box>
			</HStack>
		</Box>
	);
};
