import React from 'react';
import {
	Box,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	Flex,
	Button,
	Heading,
} from '@chakra-ui/react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useCreateBinSetting } from '../../query-hooks';
import { CreateBinSettingPayload } from '../../types';
import { InputText } from '../../components';
import { BlockWrapper } from './BlockWrapper';
import { InputsWrapper } from './InputsWrapper';
import { addBinSettingResolver } from '../../validation';

interface IAddBinSettingModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
}

export const AddBinSettingModal: React.FC<IAddBinSettingModalProps> = ({
	isOpen,
	onClose,
}) => {
	const createBinSettingMutation = useCreateBinSetting();

	const {
		control,
		register,
		handleSubmit,
		reset,
		formState: { errors },
	} = useForm<CreateBinSettingPayload>({
		defaultValues: {
			rfidSettings: [{ nameFormat: '', numberOffset: 0, numberRange: 0 }],
			scaleSettings: [{ nameFormat: '', binNumber: 0, binRfidNumber: 0 }],
		},
		// @ts-ignore
		resolver: addBinSettingResolver,
	});

	const {
		fields: rfidFields,
		append: rfidAppend,
		remove: rfidRemove,
	} = useFieldArray({
		control,
		name: 'rfidSettings',
	});

	const {
		fields: scaleFields,
		append: scaleAppend,
		remove: scaleRemove,
	} = useFieldArray({
		control,
		name: 'scaleSettings',
	});

	const onSubmit = (data: CreateBinSettingPayload) => {
		console.log(data, 'submit data');
		createBinSettingMutation.mutate(data, {
			onSuccess(data) {
				if (data.success) {
					onClose();
					reset({});
				}
			},
		});
	};

	return (
		<Modal
			blockScrollOnMount={false}
			isOpen={isOpen}
			onClose={onClose}
			size="2xl">
			<ModalOverlay />
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalContent
					borderRadius="8px"
					border="1px solid"
					borderColor="primary"
					bg="light"
					p="32px">
					<Flex
						gap="40px"
						flexDirection="column"
						p="32px"
						borderRadius="12px"
						boxShadow="0px 0px 15px 0px rgba(0, 0, 0, 0.10)"
						bg="light">
						<ModalHeader display={'flex'} alignItems="center" p={0}>
							<Heading
								variant="h2"
								color={'dark'}
								w="100%"
								fontWeight={700}
								textAlign="center">
								Add Bin Setting
							</Heading>
						</ModalHeader>
						<InputText
							{...register('name')}
							label={'Bin Setting’s Name'}
							variant={'flushed'}
							placeholder={'Enter Bin Setting’s Name'}
							width="60%"
							errorMsg={errors.name?.message}
						/>
						<Flex
							flexDirection="column"
							gap="40px"
							pr="16px"
							maxH="460px"
							overflowY="auto">
							<BlockWrapper
								buttonLabel="Add Rfid bin"
								title="Rfid bin"
								onAdd={() =>
									rfidAppend({
										nameFormat: '',
										numberOffset: 0,
										numberRange: 0,
									})
								}>
								{rfidFields.map((elem, index) => (
									<InputsWrapper
										index={index}
										key={elem.id}
										isEdit={false}
										target="Rfid bin"
										title={`Rfid bin ${index + 1}`}
										onDelete={() => rfidRemove(index)}>
										<InputText
											{...register(
												`rfidSettings.${index}.nameFormat`,
											)}
											label={'Format name'}
											variant={'flushed'}
											placeholder={'Enter Format name'}
											errorMsg={
												errors?.rfidSettings?.[index]
													?.nameFormat?.message
											}
										/>
										<InputText
											{...register(
												`rfidSettings.${index}.numberOffset`,
											)}
											label="Number offset"
											type="number"
											placeholder="0"
										/>
										<InputText
											{...register(
												`rfidSettings.${index}.numberRange`,
											)}
											label="Number range"
											type="number"
											placeholder="0"
										/>
									</InputsWrapper>
								))}
							</BlockWrapper>
							<BlockWrapper
								title="Scale"
								buttonLabel="Add Scale"
								onAdd={() =>
									scaleAppend({
										nameFormat: '',
										binNumber: 0,
										binRfidNumber: 0,
									})
								}>
								{scaleFields.map((elem, index) => (
									<InputsWrapper
										isEdit={false}
										index={index}
										key={elem.id}
										target="Scale"
										title={`Scale ${index + 1}`}
										onDelete={() => scaleRemove(index)}>
										<InputText
											{...register(
												`scaleSettings.${index}.nameFormat`,
											)}
											label={'Format name'}
											variant={'flushed'}
											placeholder={'Enter Format name'}
											errorMsg={
												errors?.scaleSettings?.[index]
													?.nameFormat?.message
											}
										/>
										<InputText
											{...register(
												`scaleSettings.${index}.binNumber`,
											)}
											label="Bin Number"
											type="number"
										/>
										<InputText
											{...register(
												`scaleSettings.${index}.binRfidNumber`,
											)}
											label="Rfid bin number"
											type="number"
										/>
									</InputsWrapper>
								))}
							</BlockWrapper>
						</Flex>

						<Flex gap={'16px'}>
							<Button variant={'secondary'} onClick={onClose}>
								Cancel
							</Button>
							<Button
								variant={'primary'}
								type={'submit'}
								isLoading={createBinSettingMutation.isPending}>
								Save
							</Button>
						</Flex>
					</Flex>
				</ModalContent>
			</form>
		</Modal>
	);
};
