import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../constants';
import { ApiResponse, TestCycles } from '../../types';
import apiBase from '../../api/api.base';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

export const useGetTestCycles = (page: number) => {
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useQuery({
		queryKey: [QueryKeys.GET_TEST_CYCLES, page],
		queryFn: (): Promise<ApiResponse<TestCycles>> =>
			apiBase.get(`/api/ranchos/${ranchoId}/test-cycles`, {
				CurrentPage: page,
				PageSize: 10,
			}),
	});
};
