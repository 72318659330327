import React from 'react';
import { Th, IconButton } from '@chakra-ui/react';
import { colors } from '../../../../theme/colors';
import { Icon, DeleteConfirmPopup } from '../../../../components';
import { useDeleteMeasurementSession } from '../../../../query-hooks';

interface IWeightUpperHeaderProps {
	sessionName: string;
	sessionId: number;
}

export const WeightUpperHeader: React.FC<IWeightUpperHeaderProps> = ({
	sessionId,
	sessionName,
}) => {
	const deleteMeasurementSessionMutation = useDeleteMeasurementSession();

	return (
		<>
			<Th
				colSpan={3}
				style={{
					fontSize: '16px',
					lineHeight: '24px',
					paddingTop: '16px',
					paddingBottom: '16px',
				}}>
				{sessionName}
			</Th>
			<Th borderRightWidth={'1px'} borderRightColor="lightGray">
				<DeleteConfirmPopup
					title="Are you sure you want to delete this measurement session?"
					onConfirm={() =>
						deleteMeasurementSessionMutation.mutate(sessionId)
					}
					confirmButtonProps={{
						isLoading: deleteMeasurementSessionMutation.isPending,
					}}>
					<IconButton padding={0} aria-label="Delete day">
						<Icon
							name={'trash'}
							width="20px"
							height="20px"
							color={colors.error}
						/>
					</IconButton>
				</DeleteConfirmPopup>
			</Th>
		</>
	);
};
