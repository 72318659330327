import React from 'react';
import { Text, Flex, Button } from '@chakra-ui/react';
import { Icon } from '../../../../components';
import { colors } from '../../../../theme/colors';

interface IRationMetaDataProps {
	onAddRow: VoidFunction;
	totalFedPercent: number;
	totalDryMatter: number;
}

export const RationMetaData: React.FC<IRationMetaDataProps> = ({
	onAddRow,
	totalDryMatter,
	totalFedPercent,
}) => {
	return (
		<Flex
			flexDirection={'column'}
			flexGrow={'1'}
			justifyContent={'flex-end'}>
			<Flex justify="center" mb={4}>
				<Button onClick={onAddRow} color={'primary'}>
					<Icon
						name="add"
						width={'20px'}
						height={'20px'}
						color={colors.primary}
					/>
					Add Info
				</Button>
			</Flex>
			<Flex gap={'10px'} align="center" pt={4}>
				<Text
					color={'dark'}
					fontWeight="bold"
					minW={'100px'}
					textAlign={'right'}>
					Total
				</Text>
				<Text
					color={'dark'}
					minW={'120px'}
					textAlign={'center'}
					backgroundColor={
						totalFedPercent > 1 ? 'yellow' : 'secondary'
					}>
					{totalFedPercent?.toFixed(2)}
				</Text>
				<Text
					color={'dark'}
					minW={'120px'}
					textAlign={'center'}
					backgroundColor={
						totalDryMatter > 1 ? 'yellow' : 'secondary'
					}>
					{totalDryMatter?.toFixed(2)}
				</Text>
			</Flex>
		</Flex>
	);
};
