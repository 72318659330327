import React, { PropsWithChildren } from 'react';
import { Center, Flex, Text, Image } from '@chakra-ui/react';
import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import logo from '../../src/assets/images/logo.svg';
import backgroundBottom from '/images/background-bottom.png';
import backgroundTop from '/images/background-top.png';
import { RootStore } from '../redux/createStore';
import { ROUTE_PATH } from '../constants/route-paths';

export const AuthLayout: React.FC<PropsWithChildren> = () => {
	const { token } = useSelector((state: RootStore) => state.auth);
	const { accessToken } = token;

	if (accessToken) {
		return <Navigate to={ROUTE_PATH.animals} replace={true} />;
	}

	return (
		<Flex minH={'100dvh'} pos={'relative'}>
			<Image
				pos="absolute"
				top={'0'}
				left={'0'}
				src={backgroundTop}
				alt={''}
				w={'996px'}
				h={'671px'}
				zIndex={'-1'}
			/>
			<Image
				pos="absolute"
				bottom={'0'}
				right={'90'}
				src={backgroundBottom}
				alt={''}
				w={'970px'}
				h={'542px'}
				zIndex={'-1'}
			/>
			<Flex
				maxW={'1440px'}
				mx={'auto'}
				flexWrap={'wrap'}
				justifyContent={'center'}
				gap={'188px'}
				alignItems={'center'}>
				<Flex
					flexDirection={'column'}
					alignItems={'center'}
					justifyContent={'center'}
					gap={'80px'}
					width={'590px'}>
					<Flex
						flexDirection={'column'}
						alignItems={'center'}
						gap={'22px'}>
						<Image src={logo} alt={''} w={'292px'} h={'202px'} />
					</Flex>
					<Text color={'dark'} variant={'B04'} textAlign={'center'}>
						Insights in every bite
					</Text>
				</Flex>
				<Center flex={1} pt={'20px'}>
					<Outlet />
				</Center>
			</Flex>
		</Flex>
	);
};
