import { useQuery } from '@tanstack/react-query';
import API from '../../api/api.base';
import { QueryKeys } from '../../constants';
import { ApiResponse, BinAnalysisResponse } from '../../types';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootStore } from '../../redux/createStore';

export const useGetAnalysisData = (payload: {
	isEnabled: boolean;
	page: number;
	startDate: Date | undefined;
	endDate: Date | undefined;
	sortBy: number;
	orderBy: number;
}) => {
	const { id: testCycleId } = useParams();
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useQuery<
		ApiResponse<{
			count: number;
			binsAnalysisResponse: BinAnalysisResponse[];
		}>
	>({
		queryKey: [
			QueryKeys.GET_ANALYSIS_DATA,
			payload.page,
			payload.startDate,
			payload.endDate,
			payload.sortBy,
			payload.orderBy,
		],
		queryFn: () =>
			API.get(
				`/api/ranchos/${ranchoId}/test-cycles/${testCycleId}/files-combined`,
				{
					PageSize: 15,
					CurrentPage: payload.page,
					StartDate: payload.startDate
						? dayjs(payload.startDate).format('YYYY-MM-DD')
						: undefined,
					EndDate: payload.endDate
						? dayjs(payload.endDate).format('YYYY-MM-DD')
						: undefined,
					SortColumn: payload.sortBy,
					Order: payload.orderBy,
				},
			),
		enabled: payload.isEnabled,
	});
};
