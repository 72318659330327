import React, { PropsWithChildren, useState } from 'react';
import {
	Table,
	TableContainer,
	Tbody,
	Th,
	Thead,
	Tr,
	Box,
	chakra,
} from '@chakra-ui/react';
import { useGetReportAnimalIntakes } from '../../../../query-hooks';
import { Loader, Pagination } from '../../../../components';
import { EmptyDataMessage } from '../../components';
import { nowrapTableHeadersStyles } from '../../constants';
import { trickyTableDividerStyles } from '../../../../constants';
import { getDateByFormat } from '../../../../utils';
import { FakeTableRow, TableRow, TotalTableRow } from './components';
import { colors } from '../../../../theme/colors';

export const AnimalIntakes: React.FC<PropsWithChildren> = () => {
	const [page, setPage] = useState(1);

	const { data, isLoading } = useGetReportAnimalIntakes({
		currentPage: page,
	});

	if (isLoading) {
		return <Loader centerHeight="calc(100vh - 350px)" />;
	}

	if (!isLoading && !data?.value?.totalCount) {
		return <EmptyDataMessage />;
	}

	const totalCount = data?.value?.totalCount || 0;

	return (
		<React.Fragment>
			<Box
				backgroundColor="light"
				boxShadow="0px 0px 15px rgba(0, 0, 0, 0.10)"
				borderRadius="12px"
				padding="16px">
				<TableContainer
					h={
						totalCount > 10
							? 'calc(100vh - 350px)'
							: '"calc(100vh - 320px)"'
					}
					maxH="630px"
					bg="white"
					overflowY="auto">
					<Table
						w="100%"
						minW="200px"
						variant="custom"
						sx={{
							'tr > th, tr > td': {
								backgroundColor: 'white',
							},
							'tr > th:first-of-type, tr > td:first-of-type': {
								position: 'sticky',
								left: 0,
							},
							'tr > th:nth-of-type(2), tr > td:nth-of-type(2)': {
								position: 'sticky',
								left: '114px',
							},
						}}>
						<Thead>
							<Tr>
								<Th style={nowrapTableHeadersStyles}>Tag #</Th>
								<Th
									position="relative"
									style={nowrapTableHeadersStyles}>
									EID <Box {...trickyTableDividerStyles} />
								</Th>
								{data?.value?.fullDaysInfo?.map((el, index) => (
									<Th
										key={index}
										minW="85px"
										style={nowrapTableHeadersStyles}>
										{getDateByFormat(el.date, 'M/D/YY')}{' '}
										<chakra.br />{' '}
										<chakra.span color="primary">
											Day {index + 1}
										</chakra.span>
									</Th>
								))}
							</Tr>
						</Thead>
						<Tbody>
							{data?.value?.intakes?.map((el, index) => (
								<TableRow key={index} {...el} />
							))}
							{Array.from({
								length:
									10 - (data?.value?.intakes?.length || 0),
							}).map((_, index) => (
								<FakeTableRow key={index} />
							))}
							<FakeTableRow
								rowStyles={{
									height: '26px',
									borderBottom: `1px solid ${colors.primary2}`,
								}}
							/>
							<TotalTableRow
								items={data?.value?.fullDaysInfo || []}
							/>
						</Tbody>
					</Table>
				</TableContainer>
			</Box>
			<Pagination
				totalCount={totalCount}
				pageSize={10}
				currentPage={page}
				onPageChange={page => setPage(page)}
				mt={4}
			/>
		</React.Fragment>
	);
};
