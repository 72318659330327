import React from 'react';
import { Flex, Text, FlexProps } from '@chakra-ui/react';

interface IEmptyDataMessageProps {
	containerProps?: FlexProps;
	message?: string;
}

export const EmptyDataMessage: React.FC<IEmptyDataMessageProps> = ({
	containerProps,
	message = 'No data yet',
}) => {
	return (
		<Flex
			backgroundColor="light"
			boxShadow="0px 0px 15px rgba(0, 0, 0, 0.10)"
			borderRadius="12px"
			padding="16px"
			h="calc(100vh - 250px)"
			w="calc(100vw - 254px)"
			justify="center"
			align="center"
			{...containerProps}>
			<Text variant="B05" color="dark" fontWeight={500}>
				{message}
			</Text>
		</Flex>
	);
};
