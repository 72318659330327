import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../constants';
import { ApiResponse, ReportsAnimalsIntakesResponse } from '../../types';
import apiBase from '../../api/api.base';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

interface IPayload {
	currentPage: number;
}

export const useGetReportAnimalIntakes = ({ currentPage }: IPayload) => {
	const { id: testCycleId } = useParams();
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useQuery({
		queryKey: [
			QueryKeys.GET_REPORTS_ANIMAL_INTAKES,
			currentPage,
			testCycleId,
			ranchoId,
		],
		queryFn: (): Promise<ApiResponse<ReportsAnimalsIntakesResponse>> =>
			apiBase.get(
				`/api/ranchos/${ranchoId}/test-cycles/${testCycleId}/animal-intakes`,
				{ currentPage },
			),
		enabled: !!testCycleId,
	});
};
