import React, { useState } from 'react';
import {
	Table,
	TableContainer,
	Tbody,
	Th,
	Thead,
	Tr,
	Td,
	Center,
	Text,
} from '@chakra-ui/react';
import { Loader, Pagination } from '../../components';
import { useGetReports } from '../../query-hooks';
import { FinalReportRow } from './FinalReportRow';

export const FinalReport: React.FC = () => {
	const [page, setPage] = useState(1);
	const { data, isLoading } = useGetReports({
		CurrentPage: page,
		PageSize: 10,
	});

	const reports = data?.value?.reports;

	return (
		<TableContainer
			backgroundColor={'light'}
			boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
			borderRadius={'12px'}
			padding={'16px'}
			w={'100%'}
			minHeight={'100%'}
			display={'flex'}
			flexDirection={'column'}
			justifyContent={'space-between'}>
			{isLoading ? (
				<Loader centerHeight="400px" />
			) : (
				<Table variant="custom">
					<Thead>
						<Tr>
							<Th>Report #</Th>
							<Th>Test group</Th>
							<Th>Customer</Th>
							<Th>Customer ID</Th>
							<Th>Details</Th>
						</Tr>
					</Thead>
					<Tbody>
						{reports?.length ? (
							data?.value?.reports?.map(row => (
								<FinalReportRow key={row.reportId} {...row} />
							))
						) : (
							<Tr>
								<Td colSpan={5}>
									<Center h="300px">
										<Text variant="B02" color="darkLight">
											No data yet
										</Text>
									</Center>
								</Td>
							</Tr>
						)}
					</Tbody>
				</Table>
			)}
			<Pagination
				totalCount={data?.value?.totalCount || 0}
				currentPage={page}
				pageSize={10}
				onPageChange={val => setPage(val)}
			/>
		</TableContainer>
	);
};
