import React from "react";
import { Tr, Td } from '@chakra-ui/react';

interface IAnimalRemoveNoteRowProps {
	id: number;
	note: string;
	ranchTag: string;
}

export const AnimalToRemoveModalRow: React.FC<IAnimalRemoveNoteRowProps> = ({
	id,
	note,
	ranchTag,
}) => {
	return (
		<Tr>
			<Td w="100px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{id}
			</Td>
			<Td w="100px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{ranchTag}
			</Td>
			<Td minW="170px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{note}
			</Td>
		</Tr>
	);
};
