import React, { useEffect } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	Heading,
	Button,
	Flex,
} from '@chakra-ui/react';
import { useForm, useFieldArray } from 'react-hook-form';
import { InputText } from '../../components';
import { BlockWrapper } from '../AddBinSetting/BlockWrapper';
import { InputsWrapper } from '../AddBinSetting/InputsWrapper';
import {
	useUpdateUnusedBinSetting,
	useUpdateUsedBinSetting,
} from '../../query-hooks';
import { BinSetting, EditBinSettingPayload } from '../../types';
import { addBinSettingResolver } from '../../validation';

interface IEditBinSettingModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
	data: BinSetting | null;
}

export const EditBinSettingModal: React.FC<IEditBinSettingModalProps> = ({
	isOpen,
	onClose,
	data,
}) => {
	const {
		control,
		register,
		handleSubmit,
		reset,
		formState: { errors },
		// @ts-ignore
	} = useForm<EditBinSettingPayload>({ resolver: addBinSettingResolver });

	const updateUnusedSettingMutation = useUpdateUnusedBinSetting(data?.id);
	const updateUsedSettingMutation = useUpdateUsedBinSetting(data?.id);

	const isUsedSetting = !!data?.isUsed;

	useEffect(() => {
		if (isOpen && data) {
			reset({
				name: data.name,
				rfidSettings: data.rfidSettings,
				scaleSettings: data.scaleSettings,
			});
		} else {
			reset({});
		}
	}, [isOpen, reset]);

	const {
		fields: rfidFields,
		append: rfidAppend,
		remove: rfidRemove,
	} = useFieldArray({
		control,
		name: 'rfidSettings',
	});

	const {
		fields: scaleFields,
		append: scaleAppend,
		remove: scaleRemove,
	} = useFieldArray({
		control,
		name: 'scaleSettings',
	});

	const onSubmit = (payload: EditBinSettingPayload) => {
		if (data?.isUsed) {
			console.log(payload, 'submit data used');
			updateUsedSettingMutation.mutate(
				{
					name: payload.name,
					rfidSettings: payload.rfidSettings.map(elem => ({
						id: elem.id,
						name: elem.nameFormat,
					})),
					scaleSettings: payload.scaleSettings.map(elem => ({
						id: elem.id,
						name: elem.nameFormat,
					})),
				},
				{
					onSuccess(data) {
						if (data.success) {
							onClose();
							reset({});
						}
					},
				},
			);
		} else {
			updateUnusedSettingMutation.mutate(payload, {
				onSuccess(data) {
					if (data.success) {
						onClose();
						reset({});
					}
				},
			});
			console.log(payload, 'submit data un-used');
		}
	};

	return (
		<Modal
			blockScrollOnMount={false}
			isOpen={isOpen}
			onClose={onClose}
			size="2xl">
			<ModalOverlay />
			<form onSubmit={handleSubmit(onSubmit)}>
				<ModalContent
					borderRadius="8px"
					border="1px solid"
					borderColor="primary"
					bg="light"
					p="32px">
					<Flex
						gap="40px"
						flexDirection="column"
						p="32px"
						borderRadius="12px"
						boxShadow="0px 0px 15px 0px rgba(0, 0, 0, 0.10)"
						bg="light">
						<ModalHeader display={'flex'} alignItems="center" p={0}>
							<Heading
								variant="h2"
								color={'dark'}
								w="100%"
								fontWeight={700}
								textAlign="center">
								Edit Bin Setting
							</Heading>
						</ModalHeader>
						<InputText
							{...register('name')}
							label={'Bin Setting’s Name'}
							variant={'flushed'}
							placeholder={'Enter Bin Setting’s Name'}
							width="60%"
							errorMsg={errors.name?.message}
						/>
						<Flex
							flexDirection="column"
							gap="40px"
							pr="16px"
							maxH="460px"
							overflowY="auto">
							<BlockWrapper
								buttonLabel="Add Rfid bin"
								title="Rfid bin"
								isDisabled={isUsedSetting}
								onAdd={() =>
									rfidAppend({
										id: 0,
										nameFormat: '',
										numberOffset: 0,
										numberRange: 0,
									})
								}>
								{rfidFields.map((elem, index) => (
									<InputsWrapper
										index={index}
										key={elem.id}
										isEdit={true}
										isDisabled={isUsedSetting}
										target="Rfid bin"
										title={`Rfid bin ${index + 1}`}
										onDelete={() => rfidRemove(index)}>
										<InputText
											{...register(
												`rfidSettings.${index}.nameFormat`,
											)}
											label={'Format name'}
											variant={'flushed'}
											placeholder={'Enter Format name'}
											errorMsg={
												errors?.rfidSettings?.[index]
													?.nameFormat?.message
											}
											isDisabled={isUsedSetting}
										/>
										<InputText
											{...register(
												`rfidSettings.${index}.numberOffset`,
											)}
											label="Number offset"
											type="number"
											placeholder="0"
											isDisabled={isUsedSetting}
										/>
										<InputText
											{...register(
												`rfidSettings.${index}.numberRange`,
											)}
											label="Number range"
											type="number"
											placeholder="0"
											isDisabled={isUsedSetting}
										/>
									</InputsWrapper>
								))}
							</BlockWrapper>
							<BlockWrapper
								title="Scale"
								buttonLabel="Add Scale"
								isDisabled={isUsedSetting}
								onAdd={() =>
									scaleAppend({
										id: 0,
										nameFormat: '',
										binNumber: 0,
										binRfidNumber: 0,
									})
								}>
								{scaleFields.map((elem, index) => (
									<InputsWrapper
										index={index}
										key={elem.id}
										isEdit={true}
										isDisabled={isUsedSetting}
										target="Scale"
										title={`Scale ${index + 1}`}
										onDelete={() => scaleRemove(index)}>
										<InputText
											{...register(
												`scaleSettings.${index}.nameFormat`,
											)}
											label={'Format name'}
											variant={'flushed'}
											placeholder={'Enter Format name'}
											errorMsg={
												errors?.scaleSettings?.[index]
													?.nameFormat?.message
											}
											isDisabled={isUsedSetting}
										/>
										<InputText
											{...register(
												`scaleSettings.${index}.binNumber`,
											)}
											label="Bin Number"
											type="number"
											isDisabled={isUsedSetting}
										/>
										<InputText
											{...register(
												`scaleSettings.${index}.binRfidNumber`,
											)}
											label="Rfid bin number"
											type="number"
											isDisabled={isUsedSetting}
										/>
									</InputsWrapper>
								))}
							</BlockWrapper>
						</Flex>

						<Flex gap={'16px'}>
							<Button variant={'secondary'} onClick={onClose}>
								Cancel
							</Button>
							<Button
								variant={'primary'}
								type={'submit'}
								isLoading={
									updateUnusedSettingMutation.isPending ||
									updateUsedSettingMutation.isPending
								}>
								Save
							</Button>
						</Flex>
					</Flex>
				</ModalContent>
			</form>
		</Modal>
	);
};
