import React from 'react';
import { Tr, Td } from '@chakra-ui/react';
import { ProcessCode, BinAnalysisResponse } from '../../../types';

export const AllFilesRow: React.FC<BinAnalysisResponse> = ({
	averageRfid,
	binNumber,
	code110Start,
	code110Stop,
	consumption,
	date,
	duration,
	expConsumption,
	id,
	post,
	postWeight,
	prior,
	priorWeight,
	processCodes,
	rateWeight,
	seq,
	startWeight,
	stopWeight,
	tag,
	totalRfid,
}) => {
	return (
		<Tr>
			<Td>{binNumber}</Td>
			<Td>{seq}</Td>
			<Td>{startWeight}</Td>
			<Td>{stopWeight}</Td>
			<Td>{consumption}</Td>
			<Td>{rateWeight}</Td>
			<Td>{duration}</Td>
			<Td>{totalRfid}</Td>
			<Td>{averageRfid}</Td>
			<Td>{prior}</Td>
			<Td>{post}</Td>
			<Td>{priorWeight}</Td>
			<Td>{postWeight}</Td>
			{/* <Td>Alert</Td> */}
			{/* @ts-ignore */}
			<Td>{processCodes.map(elem => ProcessCode?.[elem]).join(', ')}</Td>
			{/* <Td>Warning</Td> */}
			<Td>{code110Start}</Td>
			<Td>{code110Stop}</Td>
			<Td>{expConsumption}</Td>
		</Tr>
	);
};
