import React, { useCallback, useState } from 'react';
import {
	Box,
	Button,
	Flex,
	Table,
	TableContainer,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	useDisclosure,
	Center,
	Text,
} from '@chakra-ui/react';
import { AddAnimalInfoModal } from '../../../../modals';
import { colors } from '../../../../theme/colors';
import { Icon, Loader, Pagination } from '../../../../components';
import {
	useGetAnimalRemoveNotes,
	useCreateAnimalRemoveNotes,
	useGetRanchoAnimalsTestOptions,
} from '../../../../query-hooks';
import { AnimalToRemoveRow } from './AnimalToRemoveRow';
import { transformAnimalIdToOptions } from '../../../../utils';

export const AnimalToRemove: React.FC = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [page, setPage] = useState(1);
	const createAnimalRemoveNoteMutation = useCreateAnimalRemoveNotes();
	const { data, isLoading } = useGetAnimalRemoveNotes({
		search: undefined,
		page,
	});
	const { data: ranchoAnimalsTestOptionsData } =
		useGetRanchoAnimalsTestOptions({
			search: undefined,
			isEnabled: isOpen,
		});

	const onCreateAnimalRemoveNote = useCallback(
		(animalId: number, note: string) => {
			createAnimalRemoveNoteMutation.mutate(
				{ animalId, note },
				{
					onSuccess: data => {
						if (!data.success) {
							return;
						}
						onClose();
					},
				},
			);
		},
		[createAnimalRemoveNoteMutation, onClose],
	);

	const items = data?.value?.items;

	return (
		<Box width={'100%'}>
			<TableContainer
				backgroundColor={'light'}
				boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
				borderRadius={'12px'}
				display={'flex'}
				flexDirection={'column'}
				minH={'calc(100vh - 271px)'}
				minW={'calc(100vw - 304px)'}
				p={'16px'}>
				{isLoading ? (
					<Loader />
				) : (
					<Table
						variant="custom"
						mb={'32px'}
						sx={{
							td: { borderBottom: 'none' },
							th: { borderBottom: 'none' },
						}}>
						<Thead>
							<Tr>
								<Th w="100px">#</Th>
								<Th w="100px">Tag #</Th>
								<Th>
									Notes: reason for the day being
									disqualified. Sickness, Doctoring, etc.
								</Th>
								<Th w="140px">Actions</Th>
							</Tr>
						</Thead>
						<Tbody>
							{items?.length ? (
								items?.map(row => (
									<AnimalToRemoveRow
										animalInTestCycleId={
											row.animalInTestCycleId
										}
										key={row.id}
										id={row.id}
										ranchTag={row.ranchTag}
										note={row.note}
									/>
								))
							) : (
								<Tr>
									<Td colSpan={4}>
										<Center h="300px">
											<Text
												variant="B02"
												color="darkLight">
												No data yet
											</Text>
										</Center>
									</Td>
								</Tr>
							)}
						</Tbody>
					</Table>
				)}
				<Flex
					flexDirection={'column'}
					flexGrow={'1'}
					justifyContent={'flex-end'}>
					<Flex justify="center" mb={4}>
						<Button onClick={onOpen} color={'primary'}>
							<Icon
								name="add"
								width={'20px'}
								height={'20px'}
								color={colors.primary}
							/>
							Add Info
						</Button>
					</Flex>
				</Flex>
				<Pagination
					totalCount={data?.value?.count || 0}
					currentPage={page}
					pageSize={10}
					onPageChange={val => setPage(val)}
				/>
			</TableContainer>
			<AddAnimalInfoModal
				title="Add Info"
				label="Tag #"
				placeholder="Select Tag #"
				includeNotes={true}
				isOpen={isOpen}
				onClose={onClose}
				onSubmit={(id, note) => onCreateAnimalRemoveNote(id, note!)}
				isLoading={createAnimalRemoveNoteMutation.isPending}
				options={transformAnimalIdToOptions(
					ranchoAnimalsTestOptionsData?.value || [],
				)}
			/>
		</Box>
	);
};
