import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../constants';
import { ApiResponse, ReportsFinalAnalysisItemType } from '../../types';
import apiBase from '../../api/api.base';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

export const useGetReportFinalAnalysis = () => {
	const { id: testCycleId } = useParams();
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useQuery({
		queryKey: [QueryKeys.GET_REPORTS_FINAL_ANALYSIS, ranchoId, testCycleId],
		queryFn: (): Promise<ApiResponse<ReportsFinalAnalysisItemType[]>> =>
			apiBase.get(
				`/api/ranchos/${ranchoId}/test-cycles/${testCycleId}/final-analysis`,
				{},
			),
		enabled: !!testCycleId,
	});
};
