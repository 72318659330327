import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SignInResponse, CurrentUser, RefreshResponse } from '../../types';

const initialState: SignInResponse = {
	token: { accessToken: '', accessTokenExpiration: '', refreshToken: '' },
	user: {
		id: 0,
		email: '',
		phoneNumber: '',
		userName: '',
		avatarUrl: '',
		avatarThumbnailUrl: '',
		ranchoId: 0,
		roles: [],
	},
};

const authReducer = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setAuthStatus: (state, action: PayloadAction<SignInResponse>) => {
			state.user = action.payload.user;
			state.token = action.payload.token;
		},
		refreshToken: (state, action: PayloadAction<RefreshResponse>) => {
			state.token = action.payload;
		},
		setAuthUser: (state, action: PayloadAction<CurrentUser>) => {
			state.user = action.payload;
		},
		logout: () => {
			return initialState;
		},
	},
});

export const { setAuthStatus, logout, refreshToken, setAuthUser } =
	authReducer.actions;

export default authReducer.reducer;
