import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import API from '../../api/api.base';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { ApiResponseEmpty, DeleteRemoveNotePayload } from '../../types';
import { QueryKeys, queryClient } from '../../constants';
import { Alerter } from '../../utils';

export const useDeleteAnimalRemoveNote = () => {
	const { id } = useParams();
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useMutation<ApiResponseEmpty, unknown, DeleteRemoveNotePayload>({
		mutationFn: ({ animalInTestCycleId, noteId }) =>
			API.delete(
				`/api/ranchos/${ranchoId}/test-cycles/${id}/animals/${animalInTestCycleId}/remove-notes/${noteId}`,
			),
		onSuccess: response => {
			if (response.success) {
				Alerter.success(
					response.messages?.[0]?.message ||
						'Animal remove note was successfully deleted',
				);
				queryClient.invalidateQueries({
					queryKey: [QueryKeys.GET_REMOVE_NOTES],
				});
			} else {
				Alerter.error(response?.errors?.[0]?.message);
			}
		},
	});
};
