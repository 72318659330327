import React from 'react';
import { Td, Text, Tr } from '@chakra-ui/react';
import { AnimalIntakeDayItemType } from '../../../../../types';

interface ITotalTableRowProps {
	items: AnimalIntakeDayItemType[];
}

export const TotalTableRow: React.FC<ITotalTableRowProps> = ({ items }) => {
	return (
		<Tr>
			<Td p="8px">
				<Text
					color="darker"
					textAlign="center"
					minW="90px"
					fontWeight={700}>
					Pen Totals
				</Text>
			</Td>
			<Td p="8px" style={{ width: '114px' }} />
			{items.map((el, index) => (
				<Td key={index}>{el.intake}</Td>
			))}
		</Tr>
	);
};
