import React from 'react';
import {
	Text,
	IconButton,
	Flex,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	Button,
	useDisclosure,
} from '@chakra-ui/react';
import { Icon } from '../../components';
import { shadows } from '../../theme/shadows';

interface IDeleteRowButtonProps {
	isDisabled: boolean;
	onConfirm: VoidFunction;
	target: string;
}

export const DeleteRowButton: React.FC<IDeleteRowButtonProps> = ({
	isDisabled,
	onConfirm,
	target,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
			<PopoverTrigger>
				<IconButton
					aria-label="Delete row"
					isDisabled={isDisabled}
					color={isDisabled ? 'gray' : 'error'}
					minW="auto"
					boxSize="24px">
					<Icon
						name="trash"
						width="16px"
						height="16px"
						color="currentColor"
					/>
				</IconButton>
			</PopoverTrigger>
			<PopoverContent
				minH={'288px'}
				maxW={'296px'}
				borderRadius={'24px'}
				border={'1px solid #175D00'}
				boxShadow={shadows.dark}>
				<PopoverHeader
					display={'flex'}
					whiteSpace={'normal'}
					textAlign={'center'}
					justifyContent={'space-between'}
					p={'32px 32px 24px'}
					borderBottom={'1px solid #ECECEC'}>
					<Text variant={'B03'}>
						Are you sure you want to delete that {target}?
					</Text>
				</PopoverHeader>
				<PopoverBody>
					<Flex flexDirection={'column'} gap={'24px'} p={'32px'}>
						<Button variant={'secondary'} onClick={onClose}>
							Cancel
						</Button>
						<Button
							variant={'delete'}
							onClick={onConfirm}
							rightIcon={
								<Icon
									name={'trash'}
									width="24px"
									height="24px"
									color={'secondary'}
								/>
							}>
							Delete
						</Button>
					</Flex>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};
