import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import API from '../../api/api.base';
import { QueryKeys } from '../../constants';
import { ApiResponse, SickDay } from '../../types';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

export const useGetSickDays = (page: number) => {
	const { id } = useParams();
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useQuery<ApiResponse<{ count: number; items: SickDay[] }>>({
		queryKey: [QueryKeys.GET_SICK_DAYS, page],
		queryFn: () =>
			API.get(
				`/api/ranchos/${ranchoId}/test-cycles/${id}/animals/sick-days`,
				{ PageSize: 10, CurrentPage: page },
			),
	});
};
