import { ComponentStyleConfig } from '@chakra-ui/react';

export const Tabs: ComponentStyleConfig = {
	variants: {
		custom: {
			tab: {
				color: 'dark',
				border: '1px solid',
				borderColor: 'lightGray',
				borderTop: 'none',
				minW: '124px',
				minH: '48px',
				padding: '6px 8px',
				noOfLines: 2,
				fontSize: '12px',
				_selected: {
					color: 'primary',
					borderBottomColor: 'primary',
					borderBottom: '3px solid',
					borderRadius: '3px',
				},
				_hover: {
					color: 'primary',
					borderBottomColor: 'primary',
					borderBottom: '3px solid',
					borderRadius: '3px',
				},
			},
			tablist: {},
			tabpanel: {
				p: '0 0 16px 0',
			},
		},
	},
};
