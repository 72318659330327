import React, { useState } from 'react';
import { Tr, Td } from '@chakra-ui/react';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import { useForm, Controller } from 'react-hook-form';
import { CustomDatePicker } from '../../../components';
import { useUpdateSickDay, useDeleteSickDay } from '../../../query-hooks';
import { SickDay } from '../../../types';
import { convertStringToDate } from '../../../utils';
interface IAnimalSickDaysRowProps {
	id: number;
	note: string;
	ranchTag: string;
	date: string;
}
export const AnimalSickDaysRowModal: React.FC<IAnimalSickDaysRowProps> = ({
	date,
	id,
	note,
	ranchTag,
}) => {
	return (
		<Tr>
			<Td w="100px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{id}
			</Td>
			<Td w="100px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{ranchTag}
			</Td>
			<Td w="140px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{format(date, 'M/d/yyyy')}
			</Td>
			<Td minW="170px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{note}
			</Td>
		</Tr>
	);
};
