import React from 'react';
import { IconButton, Td, Tr } from '@chakra-ui/react';
import { Icon } from '../../components';
import { ROUTE_PATH } from '../../constants/route-paths';
import { useNavigate } from 'react-router-dom';
import { Report } from '../../types';

export const FinalReportRow: React.FC<Report> = ({
	reportId,
	customerId,
	customerName,
	testGroup,
}) => {
	const navigate = useNavigate();
	const onClickDetails = (id: number) =>
		navigate(`${ROUTE_PATH.final_report}/${id}`);
	return (
		<Tr>
			<Td maxW={'100px'} verticalAlign={'top'}>
				{reportId}
			</Td>
			<Td maxW={'200px'} verticalAlign={'top'}>
				{testGroup}
			</Td>
			<Td maxW={'200px'} verticalAlign={'top'}>
				{customerName}
			</Td>
			<Td maxW={'150px'} verticalAlign={'top'}>
				{customerId}
			</Td>
			<Td verticalAlign={'top'}>
				<IconButton
					aria-label="go to the report details"
					onClick={() => onClickDetails(reportId)}>
					<Icon
						name="right-arrow"
						color="dark"
						width={'20px'}
						height={'20px'}
					/>
				</IconButton>
			</Td>
		</Tr>
	);
};
