import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponse, ChangeTestCycleRunningStatusPayload } from '../../types';
import apiBase from '../../api/api.base';
import { Alerter } from '../../utils';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { QueryKeys } from '../../constants';

export const useChangeTestRunningStatus = () => {
	const queryClient = useQueryClient();

	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useMutation<
		ApiResponse<number>,
		unknown,
		ChangeTestCycleRunningStatusPayload
	>({
		mutationFn: payload =>
			apiBase.patch(
				`/api/ranchos/${ranchoId}/test-cycles/${payload.testCycleId}/${payload.apiOperation}`,
				payload,
			),
		onSuccess: response => {
			if (!response.success) {
				Alerter.error(response.errors?.[0]?.message);
				return;
			}
			queryClient.invalidateQueries({
				queryKey: [QueryKeys.GET_TEST_CYCLES],
			});
			Alerter.success('Test running status changed!');
		},
	});
};
