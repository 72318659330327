import {  AnimalRanchoTestSelectorItem, IdNameType, SelectOption } from '../types';

export const transformIdNameToOptions = (
	data?: IdNameType[],
): SelectOption[] => {
	if (!data?.length) {
		return [];
	}
	return data.map(it => ({
		value: it.id.toString(),
		label: it.name,
	}));
};

export const transformAnimalIdToOptions = (
	data?: AnimalRanchoTestSelectorItem[],
): SelectOption[] => {
	if (!data?.length) {
		return [];
	}
	return data.map(it => ({
		value: it.animalInTestId.toString(),
		label: it.ranchTag,
	}));
};
