import React from 'react';
import { Tr, Td, IconButton, HStack } from '@chakra-ui/react';
import { Controller, useForm } from 'react-hook-form';
import { inputStyles } from '../../../../constants';
import { CustomTextarea, Icon, InputText } from '../../../../components';
import { colors } from '../../../../theme/colors';
import { CreateFeedRationPayload } from '../../../../types';
import { useCreateFeedRation } from '../../../../query-hooks';
import { addRationResolver } from '../../../../validation';

interface INewRationRowProps {
	onCancel: VoidFunction;
}

export const NewRationRow: React.FC<INewRationRowProps> = ({ onCancel }) => {
	const {
		register,
		handleSubmit,
		control,
		formState: { errors },
	} = useForm<CreateFeedRationPayload>({
		// @ts-ignore
		resolver: addRationResolver,
	});

	const createFeedRationMutation = useCreateFeedRation();

	const onSubmit = (data: CreateFeedRationPayload) => {
		createFeedRationMutation.mutate(data, {
			onSuccess(data) {
				if (data.success) {
					onCancel();
				}
			},
		});
	};

	return (
		<Tr>
			<Td minW="170px" verticalAlign="top">
				<InputText
					{...register('asFedPercent')}
					{...inputStyles}
					h={'24px'}
					fontSize="12px"
					lineHeight="14px"
					padding="4px 8px"
					type="number"
					maxW={'170px'}
					_focusVisible={{
						border: 'none',
						borderBottom: `1px solid ${colors.dark}`,
					}}
					errorMsg={errors.asFedPercent?.message}
				/>
			</Td>
			<Td minW="170px" verticalAlign="top">
				<InputText
					{...register('dryMatter')}
					{...inputStyles}
					h={'24px'}
					type="number"
					fontSize="12px"
					lineHeight="14px"
					padding="4px 8px"
					maxW={'170px'}
					_focusVisible={{
						border: 'none',
						borderBottom: `1px solid ${colors.dark}`,
					}}
					errorMsg={errors.dryMatter?.message}
				/>
			</Td>
			<Td minW="170px" verticalAlign="top">
				<Controller
					name="ingredient"
					control={control}
					defaultValue=""
					render={({ field: { onChange, value } }) => (
						<CustomTextarea
							minHeight={24}
							padding="4px 8px"
							value={value}
							fontSize="12px"
							lineHeight="14px"
							onChange={onChange}
						/>
					)}
				/>
			</Td>
			<Td minW="170px" verticalAlign="top">
				<Controller
					name="note"
					control={control}
					defaultValue=""
					render={({ field: { onChange, value } }) => (
						<CustomTextarea
							minHeight={24}
							padding="4px 8px"
							value={value}
							fontSize="12px"
							lineHeight="14px"
							onChange={onChange}
						/>
					)}
				/>
			</Td>
			<Td verticalAlign="top" style={{ width: '90px' }}>
				<HStack justify="space-between" maxW="65px" mx="auto">
					<IconButton
						aria-label="Save ration"
						w="20px"
						h="20px"
						minW="20px"
						isLoading={createFeedRationMutation.isPending}
						onClick={handleSubmit(onSubmit)}>
						<Icon
							type="submit"
							name="save"
							width={'20px'}
							height={'20px'}
							color={colors.primary}
						/>
					</IconButton>
					<IconButton
						aria-label="Cancel creating ration"
						w="20px"
						h="20px"
						minW="20px"
						onClick={onCancel}>
						<Icon
							name="trash"
							width={'20px'}
							height={'20px'}
							color={colors.error}
						/>
					</IconButton>
				</HStack>
			</Td>
		</Tr>
	);
};
