import { useMutation } from '@tanstack/react-query';
import API from '../../api/api.base';
import { CreateBinSettingPayload, ApiResponse } from '../../types';
import { Alerter } from '../../utils';
import { queryClient, QueryKeys } from '../../constants';

export const useUpdateUnusedBinSetting = (settingId?: number) => {
	return useMutation<ApiResponse<number>, unknown, CreateBinSettingPayload>({
		mutationFn: payload =>
			API.put(`/api/app/settings/${settingId}/update/unused`, payload),
		onSuccess: response => {
			if (response.success) {
				Alerter.success('Bin Setting has been changed successfully');
				queryClient.invalidateQueries({
					queryKey: [QueryKeys.GET_BIN_SETTINGS],
				});
			} else {
				Alerter.error(response.errors?.[0]?.message);
			}
		},
	});
};
