import React, { PropsWithChildren, useCallback, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
	Text,
	Tabs,
	TabList,
	TabPanels,
	Tab,
	TabPanel,
	Box,
	Button,
	Flex,
} from '@chakra-ui/react';
import { CustomSwitch, Icon } from '../../components';
import {
	AnimalRetags,
	AnimalSickDays,
	AnimalToRemove,
	DisqualifiedDays,
	FeedRation,
	PreSurvey,
	TestNotes,
	Weights,
} from './components';
import { colors } from '../../theme/colors';
import { AnimalInfo, DailyFeedDelivery } from './tabs';
import { StatusColor, statusColor } from '../../constants';
import {
	TestCycleStatus,
	TestRunningStatusChangeApiOperation,
} from '../../types';
import { useChangeTestRunningStatus } from '../../query-hooks/test-cycle/useChangeTestRunningStatus';

export const Test: React.FC<PropsWithChildren> = () => {
	const navigate = useNavigate();
	const { id } = useParams();
	const { state } = useLocation();

	const changeTestRunningStatusMutation = useChangeTestRunningStatus();

	const { statusId, isTestRun } = state || {};

	const [tabIndex, setTabIndex] = useState(0);
	const [isChecked, setIsChecked] = useState(isTestRun as boolean);

	const tabListRef = useRef<HTMLDivElement | null>(null);

	const isLeftDisabled = tabIndex === 0;
	const isRightDisabled = tabIndex === 9;

	const handleBack = () => navigate(-1);

	const handleLeftClick = () => {
		if (tabIndex > 0) {
			setTabIndex(prev => prev - 1);
			scrollToTab(tabIndex);
		}
	};

	const handleRightClick = () => {
		if (tabIndex < 9) {
			setTabIndex(prev => prev + 1);
			scrollToTab(tabIndex);
		}
	};
	const scrollToTab = (index: number) => {
		const tabList = tabListRef.current;
		if (tabList) {
			const activeTab = tabList.children[index] as HTMLElement;
			if (activeTab) {
				activeTab.scrollIntoView({
					behavior: 'smooth',
					block: 'nearest',
					inline: 'center',
				});
			}
		}
	};
	const handleTabChange = (index: number) => {
		setTabIndex(index);
		scrollToTab(index);
	};

	const onChangeRunningStatus = useCallback(() => {
		if (!id) {
			return;
		}
		const apiOperation = isChecked
			? TestRunningStatusChangeApiOperation.DEACTIVATE
			: TestRunningStatusChangeApiOperation.ACTIVATE;

		changeTestRunningStatusMutation.mutate(
			{
				apiOperation,
				testCycleId: +id,
			},
			{
				onSuccess: data => {
					if (!data?.success) {
						return;
					}
					setIsChecked(prev => !prev);
				},
			},
		);
	}, [id, isChecked, changeTestRunningStatusMutation]);

	return (
		<Box>
			<Tabs variant="custom" index={tabIndex} onChange={handleTabChange}>
				<Flex alignItems={'center'} gap={'16px'}>
					<Button
						onClick={handleBack}
						minWidth={'80px'}
						color={'primary'}
						p={'12px'}
						paddingLeft={'0px'}
						gap={'8px'}
						marginRight={'16px'}>
						<Icon
							name={'left-arrow'}
							width={'24px'}
							height={'24px'}
						/>
						Back
					</Button>
					<Flex flexDirection={'column'} gap={'8px'}>
						<Text minWidth={'177px'} variant={'B03'} color={'dark'}>
							Test #{id || 1}
						</Text>
						<Flex flexDirection={'row'} gap={'8px'}>
							<CustomSwitch
								isChecked={isChecked}
								setIsChecked={onChangeRunningStatus}
								trackSize={45}
								isDisabled={
									statusId === TestCycleStatus.Completed ||
									changeTestRunningStatusMutation.isPending
								}
							/>
							<Text
								display={'flex'}
								alignItems={'center'}
								color={colors.dark}
								justifyContent={'center'}
								maxW={'102px'}
								h={'34px'}
								fontSize={'14px'}
								backgroundColor={
									statusColor[statusId as keyof StatusColor]
										.bg
								}
								border={
									statusColor[statusId as keyof StatusColor]
										.border
								}
								borderRadius={'20px'}
								p={'10px'}>
								{TestCycleStatus[statusId]}
							</Text>
						</Flex>
					</Flex>
					<Flex
						alignItems={'center'}
						gap={'16px'}
						maxWidth={'calc(100vw - 550px)'}>
						<Button
							px={0}
							onClick={handleLeftClick}
							isDisabled={isLeftDisabled}
							variant={'primary'}
							width={'24px'}
							p="0"
							minW="24px"
							height={'24px'}
							sx={{
								_disabled: {
									bg: 'gray',
									cursor: 'not-allowed',
									_hover: {
										bg: 'gray',
										cursor: 'not-allowed',
									},
								},
								'@media screen and (min-width: 1900px)': {
									display: 'none',
								},
							}}
							borderRadius={'50%'}>
							<Icon
								color="white"
								name={'left-arrow'}
								width={'24px'}
								height={'24px'}
							/>
						</Button>
						<TabList overflow={'hidden'} ref={tabListRef}>
							<Tab
								sx={{
									borderLeft: 'none',
								}}>
								Animal information
							</Tab>
							<Tab>
								User <br /> pre-survey
							</Tab>
							<Tab>
								Test <br /> Feed Ration
							</Tab>
							<Tab>Weights</Tab>
							<Tab>Test ‘Disqualified’ Days (Group)</Tab>
							<Tab>Test Notes</Tab>
							<Tab>Daily Feed Delivery</Tab>
							<Tab>Individual Animal Retags</Tab>
							<Tab>Individual Animal Sick Days</Tab>
							<Tab
								sx={{
									borderRight: 'none',
								}}>
								Animals that need to remove
							</Tab>
						</TabList>
						<Button
							variant={'primary'}
							onClick={handleRightClick}
							isDisabled={isRightDisabled}
							sx={{
								_disabled: {
									bg: 'gray',
									cursor: 'not-allowed',
									_hover: {
										bg: 'gray',
										cursor: 'not-allowed',
									},
								},
								'@media screen and (min-width: 1900px)': {
									display: 'none',
								},
							}}
							width={'24px'}
							height={'24px'}
							p="0"
							minW="24px"
							borderRadius={'50%'}
							px={0}>
							<Icon
								name={'right-arrow'}
								color="white"
								width={'24px'}
								height={'24px'}
							/>
						</Button>
					</Flex>
				</Flex>
				<TabPanels marginTop={'32px'}>
					<TabPanel maxW="calc(100vw - 220px)">
						<AnimalInfo />
					</TabPanel>
					<TabPanel maxW="calc(100vw - 220px)">
						<PreSurvey />
					</TabPanel>
					<TabPanel maxW="calc(100vw - 220px)">
						<FeedRation />
					</TabPanel>
					<TabPanel maxW="calc(100vw - 220px)">
						<Weights />
					</TabPanel>
					<TabPanel maxW="calc(100vw - 220px)">
						<DisqualifiedDays />
					</TabPanel>
					<TabPanel maxW="calc(100vw - 220px)">
						<TestNotes />
					</TabPanel>
					<TabPanel maxW="calc(100vw - 220px)">
						<DailyFeedDelivery />
					</TabPanel>
					<TabPanel maxW="calc(100vw - 220px)">
						<AnimalRetags />
					</TabPanel>
					<TabPanel maxW="calc(100vw - 220px)">
						<AnimalSickDays />
					</TabPanel>
					<TabPanel maxW="calc(100vw - 220px)">
						<AnimalToRemove />
					</TabPanel>
				</TabPanels>
			</Tabs>
		</Box>
	);
};
