import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ApiResponse, SignInResponse } from '../../types';
import { Alerter } from '../../utils';
import apiBase from '../../api/api.base';
import { setAuthStatus } from '../../redux/reducers/auth.reducer';
import { ROUTE_PATH } from '../../constants/route-paths';

export const useSignIn = () => {
	const nav = useNavigate();
	const dispatch = useDispatch();

	return useMutation<
		ApiResponse<SignInResponse>,
		unknown,
		{ login: string; password: string }
	>({
		mutationFn: payload => apiBase.post('/api/auth/sign-in', payload),
		onSuccess: response => {
			if (!response.success || !response.value) {
				Alerter.error(response.errors[0].message);
			} else {
				dispatch(
					setAuthStatus({
						token: response.value.token,
						user: response.value.user,
					}),
				);
				nav(ROUTE_PATH.animals);
			}
		},
		onError: () => {
			Alerter.error('Login failed');
		},
	});
};
