import { Box, HStack, IconButton, Input, Td, Text, Tr } from '@chakra-ui/react';
import React, { useState } from 'react';
import { AnimalTestCyclesRetags } from '../../../../../types';
import { format } from 'date-fns';
import {
	useDeleteTestCyclesAnimalsChangeEidNote,
	useUpdateTestCyclesAnimalsChangeEidNote,
} from '../../../../../query-hooks';
import { colors } from '../../../../../theme/colors';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import {
	CustomDatePicker,
	CustomTextarea,
	DeleteConfirmPopup,
	Icon,
} from '../../../../../components';
import { convertStringToDate } from '../../../../../utils';
import { inputStyles } from '../../../../../constants';

interface EidNoteForm {
	date: string;
	oldEid: string;
	newEid: string;
	eidNote: string;
}

interface TableRowProps extends AnimalTestCyclesRetags {
	index: number;
}
export const TableRetagsRow: React.FC<TableRowProps> = ({
	index,
	id,
	animalInTestCycleId,
	date,
	oldEid,
	newEid,
	eidNote,
	ranchTag,
}) => {
	const [isEditMode, setIsEditMode] = useState(false);
	const { control, register, handleSubmit, reset } = useForm<EidNoteForm>();

	const updateEidNotMutation = useUpdateTestCyclesAnimalsChangeEidNote(
		animalInTestCycleId,
		id,
	);
	const deleteEidNoteMutation = useDeleteTestCyclesAnimalsChangeEidNote(
		animalInTestCycleId,
		id,
	);

	const onSubmit = (data: EidNoteForm) => {
		updateEidNotMutation.mutate(
			{
				date: dayjs(data.date).format('YYYY-MM-DD'),
				eid: data.newEid,
				eidNote: data.eidNote,
			},
			{
				onSuccess(data) {
					if (data.success) {
						setIsEditMode(false);
					}
				},
			},
		);
	};

	const onDelete = () => {
		deleteEidNoteMutation.mutate();
	};

	return (
		<Tr>
			<Td w={'100px'} verticalAlign="top" style={{ fontSize: '12px' }}>
				<Text color="darker" textAlign="center">
					{id}
				</Text>
			</Td>
			<Td w={'100px'} verticalAlign="top" style={{ fontSize: '12px' }}>
				<Text color="darker" textAlign="center">
					{ranchTag}
				</Text>
			</Td>
			<Td w={'140px'} verticalAlign="top" style={{ fontSize: '12px' }}>
				{isEditMode ? (
					<HStack align="flex-start">
						<Controller
							control={control}
							name="date"
							render={({ field }) => (
								<CustomDatePicker
									showTime={false}
									className="row-field-low"
									value={convertStringToDate(field.value)}
									onChange={field.onChange}
								/>
							)}
						/>
						<Box boxSize="16px" pt="4px">
							<Icon
								name="calendar"
								width={'16px'}
								height={'16px'}
							/>
						</Box>
					</HStack>
				) : (
					format(date, 'M/d/yyyy')
				)}
			</Td>
			<Td w="100px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{isEditMode ? (
					<Input
						{...register('oldEid')}
						{...inputStyles}
						height={'20px'}
						placeholder="Enter old eid"
						_placeholder={{
							fontSize: '12px',
							lineHeight: '16px',
							fontWeight: 500,
							color: 'darkLight',
						}}
						_focusVisible={{
							borderBottomColor: 'primary',
						}}
					/>
				) : (
					oldEid
				)}
			</Td>
			<Td w="100px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{isEditMode ? (
					<Input
						{...register('newEid')}
						{...inputStyles}
						height={'20px'}
						placeholder="Enter new eid full"
						_placeholder={{
							fontSize: '12px',
							lineHeight: '16px',
							fontWeight: 500,
							color: 'darkLight',
						}}
						_focusVisible={{
							borderBottomColor: 'primary',
						}}
					/>
				) : (
					newEid
				)}
			</Td>
			<Td verticalAlign="top" style={{ fontSize: '12px' }}>
				{isEditMode ? (
					<Controller
						name="eidNote"
						control={control}
						defaultValue=""
						render={({ field: { onChange, value } }) => (
							<CustomTextarea
								minHeight={24}
								placeholder="Enter eid note"
								padding="4px 8px"
								value={value}
								fontSize="12px"
								lineHeight="14px"
								onChange={onChange}
							/>
						)}
					/>
				) : (
					eidNote
				)}
			</Td>
			<Td verticalAlign="top" style={{ width: '90px' }}>
				<HStack justify="space-between" maxW="65px" mx="auto">
					{isEditMode ? (
						<IconButton
							aria-label="Save editing of sick day"
							onClick={handleSubmit(onSubmit)}
							w="20px"
							h="20px"
							minW="20px"
							isLoading={updateEidNotMutation.isPending}>
							<Icon
								name="save"
								width={'20px'}
								height={'20px'}
								color={colors.primary}
							/>
						</IconButton>
					) : (
						<IconButton
							aria-label="Edit note"
							w="20px"
							h="20px"
							minW="20px"
							onClick={() => {
								reset({
									date,
									oldEid,
									newEid,
									eidNote,
								});
								setIsEditMode(true);
							}}>
							<Icon
								name="small-pen"
								width={'20px'}
								height={'20px'}
								color={colors.dark}
							/>
						</IconButton>
					)}
					<DeleteConfirmPopup
						title="Are you sure you want to delete that information?"
						onConfirm={onDelete}
						confirmButtonProps={{
							isLoading: deleteEidNoteMutation.isPending,
						}}>
						<IconButton
							padding={0}
							aria-label="Delete retags animal"
							w="20px"
							h="20px"
							minW="20px">
							<Icon
								name="trash"
								width={'20px'}
								height={'20px'}
								color={colors.error}
							/>
						</IconButton>
					</DeleteConfirmPopup>
				</HStack>
			</Td>
		</Tr>
	);
};
