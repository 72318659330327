import axios from 'axios';
import { store } from '../redux/createStore';
import { Alerter, setAuthHeaderToken, handleAxiosError } from '../utils';
import { refreshToken, logout } from '../redux/reducers/auth.reducer';

export default function intercept(): void {
	axios.defaults.baseURL =
		import.meta.env.VITE_API_BASE_URL || 'http://localhost:5173';
	let isRefreshing = false;
	let subscribers: Array<(value: string) => void> = [];

	axios.interceptors.request.use(async req => {
		const accessToken = store.getState().auth?.token?.accessToken;

		if (accessToken) {
			if (req.headers) {
				req.headers.Authorization = `Bearer ${accessToken}`;
			} else {
				delete req.headers['Authorization'];
			}
		}
		return req;
	});

	axios.interceptors.response.use(undefined, async err => {
		const {
			config,
			response: { status },
		} = err;
		const originalRequest = config;
		if (status === 401) {
			if (!isRefreshing) {
				isRefreshing = true;
				try {
					const refreshResult = await axios
						.post('/api/auth/refresh-token', {
							accessToken:
								store.getState().auth?.token?.accessToken,
							refreshToken:
								store.getState().auth?.token?.refreshToken,
						})
						.catch(err => handleAxiosError(err));

					if (!refreshResult || !refreshResult?.data?.success) {
						console.log('throwing out');
						store.dispatch(logout());
						window.location.href = '/';
						setAuthHeaderToken(null);
						isRefreshing = false;
						return;
					}

					isRefreshing = false;
					onRefreshed(refreshResult.data.value.accessToken);
					store.dispatch(refreshToken(refreshResult.data.value));
					setAuthHeaderToken(refreshResult.data.value.accessToken);
					subscribers = [];
				} catch (error) {
					console.log(error, 'You need login again error');
					Alerter.error('You need login again to continue');
					store.dispatch(logout());
					window.location.href = '/';
					setAuthHeaderToken(null);
					isRefreshing = false;
				}
			}
			return new Promise(resolve => {
				subscribeTokenRefresh((token: string) => {
					originalRequest.headers.Authorization = `Bearer ${token}`;
					resolve(axios(originalRequest));
				});
			});
		}

		return Promise.reject(err);
	});

	function subscribeTokenRefresh(cb: (value: string) => void) {
		subscribers.push(cb);
	}

	function onRefreshed(token: string) {
		subscribers.map(cb => cb(token));
	}
}
