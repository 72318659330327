import React, { useState } from 'react';
import { Tr, Td, Input, Button, IconButton, HStack } from '@chakra-ui/react';
import { colors } from '../../../../theme/colors';
import {
	CustomTextarea,
	DeleteConfirmPopup,
	Icon,
} from '../../../../components';
import {
	useDeleteAnimalRemoveNote,
	useUpdateAnimalRemoveNote,
} from '../../../../query-hooks';
import { Controller, useForm } from 'react-hook-form';

interface IAnimalRemoveNoteRowProps {
	note: string;
	ranchTag: string;
	id: number;
	animalInTestCycleId: number;
}
interface RemoveNoteForm {
	note: string;
}

export const AnimalToRemoveRow: React.FC<IAnimalRemoveNoteRowProps> = ({
	note,
	ranchTag,
	id,
	animalInTestCycleId,
}) => {
	const [isEditMode, setIsEditMode] = useState(false);

	const { control, handleSubmit, reset } = useForm<RemoveNoteForm>();

	const updateRemoveNoteMutation = useUpdateAnimalRemoveNote();
	const deleteRemoveNoteMutation = useDeleteAnimalRemoveNote();

	const onSubmit = (data: RemoveNoteForm) => {
		updateRemoveNoteMutation.mutate(
			{
				animalInTestCycleId,
				noteId: id,
				note: data.note,
			},
			{
				onSuccess(data) {
					if (data.success) {
						setIsEditMode(false);
					}
				},
			},
		);
	};

	const onDelete = () => {
		deleteRemoveNoteMutation.mutate({ animalInTestCycleId, noteId: id });
	};

	return (
		<Tr>
			<Td w="100px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{id}
			</Td>
			<Td w="100px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{ranchTag}
			</Td>
			<Td minW="170px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{isEditMode ? (
					<Controller
						name="note"
						control={control}
						defaultValue=""
						render={({ field: { onChange, value } }) => (
							<CustomTextarea
								minHeight={24}
								placeholder="Enter Notes"
								padding="4px 8px"
								value={value}
								fontSize="12px"
								lineHeight="14px"
								onChange={onChange}
							/>
						)}
					/>
				) : (
					note
				)}
			</Td>
			<Td verticalAlign="top" style={{ width: '90px' }}>
				<HStack justify="space-between" maxW="65px" mx="auto">
					{isEditMode ? (
						<IconButton
							aria-label="Save editing of note"
							w="20px"
							h="20px"
							minW="20px"
							onClick={handleSubmit(onSubmit)}
							isLoading={updateRemoveNoteMutation.isPending}>
							<Icon
								name="save"
								width={'20px'}
								height={'20px'}
								color={colors.primary}
							/>
						</IconButton>
					) : (
						<IconButton
							aria-label="Edit note"
							w="20px"
							h="20px"
							minW="20px"
							onClick={() => {
								reset({ note });
								setIsEditMode(true);
							}}>
							<Icon
								name="small-pen"
								width={'20px'}
								height={'20px'}
								color={colors.dark}
							/>
						</IconButton>
					)}
					<DeleteConfirmPopup
						title="Are you sure you want to delete that information?"
						onConfirm={onDelete}
						confirmButtonProps={{
							isLoading: deleteRemoveNoteMutation.isPending,
						}}>
						<IconButton
							padding={0}
							aria-label="Delete note"
							w="20px"
							h="20px"
							minW="20px">
							<Icon
								name="trash"
								width={'20px'}
								height={'20px'}
								color={colors.error}
							/>
						</IconButton>
					</DeleteConfirmPopup>
				</HStack>
			</Td>
		</Tr>
	);
};
