import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import API from '../../api/api.base';
import { ApiResponseEmpty, CreateTestNotePayload } from '../../types';
import { queryClient, QueryKeys } from '../../constants';
import { Alerter } from '../../utils';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

export const useUpdateTestNote = (noteId: number) => {
	const { id } = useParams();
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useMutation<ApiResponseEmpty, unknown, CreateTestNotePayload>({
		mutationFn: payload =>
			API.put(
				`/api/ranchos/${ranchoId}/test-cycles/${id}/notes/${noteId}`,
				payload,
			),
		onSuccess: response => {
			if (response.success) {
				Alerter.success('Note updated');
				queryClient.invalidateQueries({
					queryKey: [QueryKeys.GET_TEST_NOTES],
				});
			} else {
				Alerter.error(response.errors?.[0]?.message);
			}
		},
	});
};
