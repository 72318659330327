import React from 'react';
import { DOTS, usePagination } from '../../hooks';
import { Button, HStack, IconButton, StackProps, Text } from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';

interface IPaginationProps extends StackProps {
	totalCount: number;
	pageSize: number;
	currentPage: number;
	siblingCount?: number;
	onPageChange: (value: number) => void;
}
export const Pagination: React.FC<IPaginationProps> = ({
	totalCount,
	pageSize,
	currentPage,
	siblingCount = 1,
	onPageChange,
	...rest
}) => {
	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize,
	});
	const lastPage = paginationRange[paginationRange.length - 1];
	if (currentPage === 0 || paginationRange.length < 2) {
		return null;
	}

	const onNext = () => {
		onPageChange(currentPage + 1);
	};

	const onPrevious = () => {
		onPageChange(currentPage - 1);
	};

	const calculateButtonWidth = (num: number) => {
		const baseValue = 40;
		const increasePerUnit = 8;

		const output = baseValue + (num - 1) * increasePerUnit;

		return output;
	};
	return (
		<HStack justifyContent={'center'} {...rest}>
			<IconButton
				w={'40px'}
				h={'40px'}
				variant={'secondary'}
				aria-label={'Previous page'}
				isDisabled={currentPage === 1}
				onClick={onPrevious}
				icon={
					<Icon name="pagination-left" height="23px" width="24px" />
				}
			/>
			{paginationRange.map(
				(pageNumber: number | string, index: number) => {
					const isSelected = pageNumber === currentPage;
					if (pageNumber === DOTS) {
						return (
							<Text color={'gray'} key={index}>
								&#8230;
							</Text>
						);
					}

					const buttonWidth = calculateButtonWidth(
						String(pageNumber).length,
					);

					return (
						<Button
							background={isSelected ? 'primary' : 'transparent'}
							color={isSelected ? 'white' : 'lightText'}
							fontWeight={600}
							w={`${buttonWidth}px`}
							h={'40px'}
							key={index}
							variant={'secondary'}
							onClick={() => onPageChange(+pageNumber)}>
							{pageNumber}
						</Button>
					);
				},
			)}
			<IconButton
				onClick={onNext}
				isDisabled={currentPage === lastPage}
				w={'40px'}
				h={'40px'}
				variant={'secondary'}
				aria-label={'Next page'}
				icon={
					<Icon name="pagination-right" height="23px" width="24px" />
				}
			/>
		</HStack>
	);
};
