import { Button, Flex, Text, VStack } from '@chakra-ui/react';
import { InputText } from '../../../components';
import { Link } from 'react-router-dom';
import { ROUTE_PATH } from '../../../constants/route-paths';
import { useForm } from 'react-hook-form';
import { ForgotPasswordFormValues } from '../types';
import { useForgotPasswordEmailSend } from '../../../query-hooks';
import { useState } from 'react';
import { SentEmail } from './components/SentEmail';
import { Alerter } from '../../../utils';

export const ForgotPassword = () => {
	const [isEmailSent, setIsEmailSent] = useState(false);

	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
	} = useForm<ForgotPasswordFormValues>();

	const forgotPassword = useForgotPasswordEmailSend();

	const onSubmit = (formValues: ForgotPasswordFormValues) => {
		forgotPassword.mutate(
			{ ...formValues },
			{
				onSuccess: data => {
					if (data.success) {
						setIsEmailSent(true);
					} else {
						Alerter.error(data.errors?.[0]?.message);
					}
				},
			},
		);
	};
	const watchFields = watch(['email']);
	const isDisabled = !watchFields.every(field => field);

	if (isEmailSent) {
		return <SentEmail />;
	}
	return (
		<Flex
			flexDirection={'column'}
			alignItems={'center'}
			justifyContent={'center'}
			gap={'40px'}
			width={'422px'}>
			<Flex flexDirection={'column'} alignItems={'center'} gap={'16px'}>
				<Text color={'darker'} variant={'B04'}>
					Forgot password
				</Text>
				<Text color={'darker'} variant={'B02'} textAlign={'center'}>
					Please enter your email, so we could send you a
					<br /> password reset link
				</Text>
			</Flex>
			<VStack width={'100%'}>
				<InputText
					{...register('email', {
						required: 'Email is required',
						pattern: {
							value: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
							message: 'Invalid email address',
						},
					})}
					errorMsg={errors.email?.message as string}
					label={'Your email'}
					variant={'flushed'}
					placeholder={'Enter your email'}
					autoComplete={'email'}
				/>
			</VStack>
			<Button
				variant={'primary'}
				isDisabled={isDisabled}
				onClick={handleSubmit(onSubmit)}>
				Send
			</Button>
			<Flex gap={'8px'}>
				<Text color={'dark'} fontFamily={'Roboto'}>
					Don’t have an account?
				</Text>
				<Link to={ROUTE_PATH.sign_up}>
					<Text fontWeight={'700'}>Sign Up</Text>
				</Link>
			</Flex>
		</Flex>
	);
};
