import React, { useCallback, useEffect, useState } from 'react';
import {
	Button,
	Flex,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
} from '@chakra-ui/react';
import { CustomSelect, InputText } from '../../components';
import { SelectOption } from '../../types';

interface AddAnimalInfoModalProps {
	isOpen: boolean;
	onClose: () => void;
	onSelectTag?: (tag: string) => void;
	placeholder: string;
	title: string;
	label: string;
	includeNotes?: boolean;
	onSubmit?: (id: number, note?: string) => void;
	isLoading?: boolean;
	options?: SelectOption[];
}

export const AddAnimalInfoModal: React.FC<AddAnimalInfoModalProps> = ({
	isOpen,
	onClose,
	onSelectTag,
	title,
	placeholder,
	label,
	includeNotes,
	isLoading,
	onSubmit,
	options,
}) => {
	const [selectedTag, setSelectedTag] = useState<string | null>(null);
	const [note, setNote] = useState<string>('');

	const handleAdd = useCallback(() => {
		if (!selectedTag) {
			return;
		}
		onSelectTag?.(selectedTag);
		if (includeNotes && note) {
			onSubmit?.(+selectedTag, note);
		} else {
			onSubmit?.(+selectedTag);
		}
	}, [onSubmit, onSelectTag, note, selectedTag]);

	const handleChange = (tag: string) => {
		setSelectedTag(tag);
	};

	useEffect(() => {
		if (!isOpen) {
			setSelectedTag(null);
		}
	}, [isOpen]);

	return (
		<Modal
			blockScrollOnMount={false}
			isCentered={true}
			isOpen={isOpen}
			onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				minH={'288px'}
				minW={'538px'}
				justifyContent={'space-between'}
				alignItems={'center'}
				flexDirection={'column'}
				borderRadius={'24px'}>
				<Flex alignItems={'center'} flexDirection={'column'}>
					<ModalHeader
						display={'flex'}
						justifyContent={'center'}
						p={'32px 32px 24px'}>
						<Text variant={'B03'} color={'dark'}>
							{title}
						</Text>
					</ModalHeader>
					<Flex minW={'422px'} flexDirection={'column'} gap={'24px'}>
						<CustomSelect
							maxH={'200px'}
							label={label}
							value={selectedTag}
							onChange={tag => handleChange(tag)}
							placeholder={placeholder}
							options={options || []}
						/>
						{includeNotes ? (
							<InputText
								label={'Note'}
								variant={'flushed'}
								value={note}
								onChange={e => setNote(e.target.value)}
								placeholder={
									'Enter the reason for the day being disqualified'
								}
							/>
						) : null}
					</Flex>
				</Flex>

				<Flex gap={'24px'} p={'32px'} minW={'422px'}>
					<Button variant={'secondary'} onClick={onClose}>
						Cancel
					</Button>
					<Button
						variant={'primary'}
						isDisabled={selectedTag ? false : true}
						isLoading={isLoading}
						onClick={handleAdd}>
						Add
					</Button>
				</Flex>
			</ModalContent>
		</Modal>
	);
};
