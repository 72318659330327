import React from 'react';
import {
	Box,
	Button,
	Flex,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
} from '@chakra-ui/react';
import { Icon, InputPassword } from '../../components';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ChangePasswordFormValues } from './types';
import { useChangePassword } from '../../query-hooks';

interface ChangePasswordModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
	isOpen,
	onClose,
}) => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		watch,
		reset,
	} = useForm<ChangePasswordFormValues>();

	const successCb = () => {
		reset({
			newPassword: '',
			oldPassword: '',
			password: '',
		});
		onClose();
	};
	const changePassword = useChangePassword({ successCb: successCb });

	const onSubmit: SubmitHandler<ChangePasswordFormValues> = data => {
		changePassword.mutate({
			newPassword: data.newPassword,
			oldPassword: data.oldPassword,
		});
	};
	const password = watch('password');

	return (
		<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent minH={'472px'} minW={'486'} borderRadius={'24px'}>
				<ModalHeader
					display={'flex'}
					justifyContent={'space-between'}
					p={'32px 32px 24px'}
					borderBottom={'1px solid #ECECEC'}>
					<Text variant={'B03'}>Profile password change:</Text>
					<Box onClick={onClose} cursor={'pointer'}>
						<Icon name={'close'} width="24px" height="24px" />
					</Box>
				</ModalHeader>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Flex
						flexDirection={'column'}
						gap={'40px'}
						padding={'32px'}>
						<Flex flexDirection={'column'} gap={'40px'}>
							<VStack spacing={'24px'}>
								<InputPassword
									label={'Your password'}
									variant={'flushed'}
									placeholder={'Enter your password'}
									showRules={false}
									errorMsg={
										errors.oldPassword?.message as string
									}
									{...register('oldPassword', {
										required: 'Password is required',
									})}></InputPassword>{' '}
								<InputPassword
									label={'New password'}
									variant={'flushed'}
									placeholder={'Enter your new password'}
									showRules={false}
									errorMsg={
										errors.password?.message as string
									}
									{...register('password', {
										required: 'Password is required',
									})}></InputPassword>{' '}
								<InputPassword
									label={'Confirm new password'}
									variant={'flushed'}
									placeholder={'Repeat new password'}
									showRules={false}
									errorMsg={
										errors.newPassword?.message as string
									}
									{...register('newPassword', {
										required:
											'Confirm password is required',
										validate: value =>
											value === password ||
											'Passwords do not match',
									})}></InputPassword>
							</VStack>
						</Flex>

						<Flex>
							<Button
								variant={'primary'}
								type="submit"
								isLoading={changePassword.isPending}>
								Change Password
							</Button>
						</Flex>
					</Flex>
				</form>
			</ModalContent>
		</Modal>
	);
};
