import React, { useEffect } from 'react';
import {
	Box,
	Button,
	Flex,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Text,
	VStack,
} from '@chakra-ui/react';
import {
	CustomDatePicker,
	CustomSelect,
	InputText,
	Loader,
	CustomTextarea,
} from '../../../../../components';
import { Controller, useForm } from 'react-hook-form';
import {
	useGetTestCyclesAnimal,
	useUpdateTestCyclesAnimal,
} from '../../../../../query-hooks';
import { AnimalStatus } from '../../../../../types';
import {
	EditAnimalInfoFormType,
	editAnimalInfoResolver,
} from '../../../../../validation';
import dayjs from 'dayjs';
import {
	AnimalGenderOptions,
	ContemporaryGroupOptions,
	DNACollectedOptions,
} from '../../../../../constants/select-options';
import { DisabledSelectTriggerProps } from '../constants';

interface IEditAnimalInfoModalProps {
	isOpen: boolean;
	onClose: () => void;
	id: number;
}

export const EditAnimalInfoModal: React.FC<IEditAnimalInfoModalProps> = ({
	isOpen,
	onClose,
	id,
}) => {
	const updateTestCyclesAnimalMutation = useUpdateTestCyclesAnimal();
	const { data, isLoading } = useGetTestCyclesAnimal({
		animalInTestCycleId: id,
		isEnabled: isOpen && !!id,
	});

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
		control,
		reset,
	} = useForm<EditAnimalInfoFormType>({
		resolver: editAnimalInfoResolver,
	});

	const animalsInfo = data?.value;

	const onSubmit = (data: EditAnimalInfoFormType) => {
		const { contemporary, eidNumber, eidFull, eidNotes, eidTruncated } =
			data;
		updateTestCyclesAnimalMutation.mutate(
			{
				contemporary: contemporary ? +contemporary : 1,
				eidNumber: eidNumber || '',
				eidFull: eidFull || '',
				eidNotes: eidNotes || '',
				eidTruncated: eidTruncated || '',
				animalInTestCycleId: id,
			},
			{
				onSuccess: data => {
					if (!data?.success) {
						return;
					}
					onClose();
					reset();
				},
			},
		);
	};

	useEffect(() => {
		if (animalsInfo && isOpen) {
			const { eid, eidFull, eidNote, eidTruncated, contemporary } =
				animalsInfo;
			reset({
				eidNumber: eid,
				eidFull,
				eidTruncated,
				eidNotes: eidNote || '',
				contemporary: contemporary.toString(),
			});
		}
	}, [animalsInfo, reset, isOpen]);

	const { birthday, ranchTag, regNumber, sex, dna, sire, dam, status } =
		animalsInfo || {};

	return (
		<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent minW={'486px'} borderRadius={'24px'}>
				<ModalHeader
					display={'flex'}
					justifyContent={'center'}
					p={'32px 32px 24px'}>
					<Text variant={'bold20'} color={'dark'}>
						Edit Animal
					</Text>
				</ModalHeader>
				{isLoading ? (
					<Box margin={'auto'}>
						<Loader centerProps={{ width: '100%' }} />
					</Box>
				) : (
					<Flex
						flexDirection={'column'}
						gap={'40px'}
						padding={'16px 32px 32px 32px'}>
						<form onSubmit={handleSubmit(onSubmit)}>
							<VStack spacing={'24px'} overflowX="clip">
								<Flex columnGap="24px" w="full">
									<InputText
										label={'Ranch Tag'}
										value={ranchTag}
										placeholder={'Enter Ranch Tag'}
										isReadOnly={true}
										formControlProps={{
											isReadOnly: true,
										}}
									/>
									<InputText
										label={'EID'}
										placeholder={'Enter EID'}
										fontWeight={400}
										errorMsg={
											errors.eidNumber?.message as string
										}
										{...register('eidNumber')}
										_placeholder={{
											color: 'darkLight',
											fontWeight: 400,
										}}
									/>
								</Flex>
								<Flex columnGap="24px" w="full">
									<InputText
										label={'Reg #'}
										variant={'flushed'}
										placeholder={'Enter Reg #'}
										value={regNumber}
										formControlProps={{
											isReadOnly: true,
										}}
									/>
									<CustomDatePicker
										label="BD"
										showTime={false}
										isReadOnly={true}
										value={dayjs(birthday || '').toDate()}
										formControlProps={{
											isReadOnly: true,
										}}
										className="edit-animal-modal"
										calendarIconSize="20px"
									/>
								</Flex>
								<Flex columnGap="24px" w="full">
									<CustomSelect
										label="Sex"
										options={AnimalGenderOptions}
										zIndex={3}
										placeholder={'Choose sex'}
										value={sex?.toString() || null}
										formControlProps={{
											w: 'full',
											isReadOnly: true,
										}}
										triggerProps={
											DisabledSelectTriggerProps
										}
										menuListProps={{
											minW: 'auto',
											w: '200px',
										}}
									/>
									<CustomSelect
										label={'DNA collected?'}
										options={DNACollectedOptions}
										placeholder={'Choose DNA collected'}
										value={dna?.toString() || null}
										formControlProps={{
											w: 'full',
											isReadOnly: true,
										}}
										triggerProps={
											DisabledSelectTriggerProps
										}
										menuListProps={{
											minW: 'auto',
											w: '200px',
										}}
									/>
								</Flex>
								<Flex columnGap="24px" w="full">
									<InputText
										label={'Sire'}
										variant={'flushed'}
										placeholder={'Enter Sire'}
										isReadOnly={true}
										value={sire}
										formControlProps={{
											isReadOnly: true,
										}}
									/>
									<InputText
										label={'Dam'}
										variant={'flushed'}
										placeholder={'Enter Dam'}
										isReadOnly={true}
										value={dam}
										formControlProps={{
											isReadOnly: true,
										}}
									/>
								</Flex>
								<Flex columnGap="24px" w="full">
									<InputText
										label={'EID full'}
										variant={'flushed'}
										placeholder={'Enter EID full'}
										errorMsg={
											errors.eidFull?.message as string
										}
										{...register('eidFull')}
										_placeholder={{
											color: 'darkLight',
											fontWeight: 400,
										}}
									/>
									<InputText
										label={'EID truncated'}
										variant={'flushed'}
										placeholder={'Enter EID truncated'}
										errorMsg={
											errors.eidTruncated
												?.message as string
										}
										{...register('eidTruncated')}
										_placeholder={{
											color: 'darkLight',
											fontWeight: 400,
										}}
									/>
								</Flex>

								<Controller
									name="eidNotes"
									control={control}
									defaultValue=""
									render={({
										field: { onChange, value },
									}) => (
										<CustomTextarea
											label="EID Notes"
											minHeight={30}
											placeholder="Enter EID Notes"
											padding="8px 0px"
											value={value}
											onChange={onChange}
											errorMsg={
												errors.eidNotes
													?.message as string
											}
											_placeholder={{
												color: 'darkLight',
												fontWeight: 400,
											}}
										/>
									)}
								/>

								<Flex columnGap="24px" w="full">
									<InputText
										label={'Status'}
										variant={'flushed'}
										placeholder={'Status'}
										isReadOnly={true}
										value={
											status
												? AnimalStatus[status]
												: 'n/a'
										}
										formControlProps={{
											isReadOnly: true,
										}}
									/>
									<Controller
										name="contemporary"
										control={control}
										render={({
											field: { onChange, value },
										}) => (
											<CustomSelect
												label="Contemporary Group"
												formControlProps={{
													w: 'full',
												}}
												options={
													ContemporaryGroupOptions
												}
												zIndex={3}
												placeholder={
													'Select or search Group'
												}
												triggerProps={{
													lineHeight: '15px',
												}}
												value={value}
												onChange={onChange}
												menuListProps={{
													minW: 'auto',
													w: '199px',
												}}
											/>
										)}
									/>
								</Flex>
							</VStack>

							<Flex
								display={'flex'}
								gap={'32px'}
								p={'0'}
								mt={'40px'}>
								<Button variant={'secondary'} onClick={onClose}>
									Cancel
								</Button>
								<Button
									variant={'primary'}
									type={'submit'}
									isDisabled={!isDirty}
									isLoading={
										updateTestCyclesAnimalMutation.isPending
									}>
									Save
								</Button>
							</Flex>
						</form>
					</Flex>
				)}
			</ModalContent>
		</Modal>
	);
};
