export type BinSetting = {
	id: number;
	isUsed: boolean;
	name: string;
	rfidSettings: {
		id: number;
		nameFormat: string;
		numberOffset: number;
		numberRange: number;
	}[];
	scaleSettings: {
		id: number;
		binNumber: number;
		binRfidNumber: number;
		nameFormat: string;
	}[];
};

export type CreateBinSettingPayload = {
	name: string;
	rfidSettings: {
		nameFormat: string;
		numberOffset: number;
		numberRange: number;
	}[];
	scaleSettings: {
		nameFormat: string;
		binRfidNumber: number;
		binNumber: number;
	}[];
};

export type EditBinSettingPayload = {
	name: string;
	rfidSettings: {
		id: number;
		nameFormat: string;
		numberOffset: number;
		numberRange: number;
	}[];
	scaleSettings: {
		id: number;
		nameFormat: string;
		binRfidNumber: number;
		binNumber: number;
	}[];
};

export type EditUsedBinSettingPayload = {
	name: string;
	rfidSettings: {
		id: number;
		name: string;
	}[];
	scaleSettings: {
		id: number;
		name: string;
	}[];
};

// export enum ProcessCode {
// 	Normal = 0,
// 	AnomalyCode10 = 1,
// 	AnomalyCode11 = 2,
// 	AnomalyCode15 = 3,
// 	AnomalyCode16 = 4,
// 	AnomalyCode17 = 5,
// 	AnomalyCode18 = 6,
// 	AnomalyCode21 = 7,
// 	AnomalyCode22 = 8,
// 	AnomalyCode41 = 9,
// 	AnomalyCode44 = 10,
// 	AnomalyCode45 = 11,
// 	AnomalyCode46 = 12,
// 	AnomalyCode51 = 13,
// 	AnomalyCode81 = 14,
// 	AnomalyCode82 = 15,
// 	AnomalyCode83 = 16,
// 	AnomalyCode84 = 17,
// 	AnomalyCode91 = 18,
// 	AnomalyCode92 = 19,
// 	AnomalyCode99 = 20,
// 	WarningCode49 = 30,
// 	WarningCode70 = 31,
// 	WarningCode34 = 32,
// 	WarningCode101 = 33,
// 	WarningCode102 = 34,
// 	WarningCode103 = 35,
// 	FinalCode110 = 50,
// 	FinalCode120 = 51,
// 	FinalCode93 = 52,
// 	FinalCode85 = 53,
// }
export const ProcessCode = {
	0: 'Normal',
	1: '10',
	2: '11',
	3: '15',
	4: '16',
	5: '17',
	6: '18',
	7: '21',
	8: '22',
	9: '41',
	10: '44',
	11: '45',
	12: '46',
	13: '51',
	14: '81',
	15: '82',
	16: '83',
	17: '84',
	18: '91',
	19: '92',
	20: '99',
	30: '49',
	31: '70',
	32: '34',
	33: '101',
	34: '102',
	35: '103',
	50: '110',
	51: '120',
	52: '93',
	53: '85',
};

export type BinAnalysisResponse = {
	id: number;
	date: string;
	tag: string;
	binNumber: number;
	seq: number;
	startWeight: number;
	stopWeight: number;
	consumption: number;
	rateWeight: number;
	duration: string;
	totalRfid: number;
	averageRfid: number;
	prior: string;
	post: string;
	priorWeight: number;
	postWeight: number;
	processCodes: number[];
	code110Start: number;
	code110Stop: number;
	expConsumption: number;
};
