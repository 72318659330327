import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../constants';
import { ApiResponse } from '../../types';
import apiBase from '../../api/api.base';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { useParams } from 'react-router-dom';
import { PreSurveyApiResponse } from '../../types/pre-survey.types';

export const useGetTestCyclePreSurvey = () => {
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	const { id } = useParams();
	return useQuery({
		queryKey: [QueryKeys.GET_PRE_SURVEY, ranchoId, id],
		queryFn: (): Promise<ApiResponse<PreSurveyApiResponse>> =>
			apiBase.get(
				`/api/ranchos/${ranchoId}/test-cycles/${id}/user-pre-survey`,
				{},
			),
	});
};
