import React from 'react';
import { Box, Text, HStack, IconButton, Flex } from '@chakra-ui/react';
import { BinSetting } from '../../types';
import { Icon } from '../Icon/Icon';
import { DeleteSettingButton } from './DeleteSettingButton';

interface ISettingItemProps extends BinSetting {
	onEdit: VoidFunction;
}

export const SettingItem: React.FC<ISettingItemProps> = ({
	name,
	id,
	isUsed,
	// rfidSettings,
	// scaleSettings,
	onEdit,
}) => {
	return (
		<Box
			borderRadius="12px"
			background="white"
			border="1px solid #DCE6D8"
			padding="8px 16px">
			<Flex align="center" flexDirection="row" justify="space-between">
				<Text color="dark" variant="B01" noOfLines={1}>
					{name}
				</Text>
				<HStack spacing="8px">
					<IconButton
						aria-label="Edit bin setting"
						bg="light"
						borderRadius="50%"
						border="1px solid"
						borderColor="transparent"
						boxSize="32px"
						minW="auto"
						onClick={onEdit}
						_hover={{ bg: 'secondary', borderColor: 'primary' }}>
						<Icon
							name="big-pen"
							height="20px"
							width="20px"
							color="#010101"
						/>
					</IconButton>
					<DeleteSettingButton id={id} isUsed={isUsed} />
				</HStack>
			</Flex>
		</Box>
	);
};
