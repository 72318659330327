import React from 'react';
import { ReportsAnimalIntakeItemType } from '../../../../../types';
import { Box, Td, Text, Tr } from '@chakra-ui/react';
import { trickyTableDividerStyles } from '../../../../../constants';

export const TableRow: React.FC<ReportsAnimalIntakeItemType> = ({
	days,
	eid,
	ranchTag,
}) => {
	return (
		<Tr>
			<Td p="8px">
				<Text color="darker" textAlign="center" minW="90px">
					{ranchTag}
				</Text>
			</Td>
			<Td p="8px" position="relative">
				<Text color="darker" textAlign="center" minW="90px">
					{eid || ''}
				</Text>
				<Box {...trickyTableDividerStyles} />
			</Td>
			{days?.map((el, index) => <Td key={index}>{el.intake}</Td>)}
		</Tr>
	);
};
