import React, { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import './datePicker.css';
import DatePicker from 'react-datepicker';
import { Icon } from '../Icon/Icon';
import { CustomHeader } from './components/CustomHeader';
import { CustomInput } from './components/CustomInput';
import {
	FormControl,
	FormControlProps,
	FormErrorMessage,
	FormLabel,
} from '@chakra-ui/react';
import { labelStyles } from '../../constants';

interface CustomDatePickerProps {
	label?: string;
	className?: string;
	placeholder?: string;
	errorMsg?: string;
	formControlProps?: FormControlProps;
	showTime?: boolean;
	isReadOnly?: boolean;
	toggleCalender?: (open: boolean) => void;
	value?: Date;
	onChange?: (value?: Date) => void;
	includeDateIntervals?: { start: Date; end: Date }[];
	calendarIconName?: string;
	enableCustomInput?: boolean;
	isClearable?: boolean;
	maxDate?: Date;
	minDate?: Date;
	shouldCloseOnSelect?: boolean;
	calendarIconSize?: string;
}

export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
	label,
	placeholder,
	errorMsg,
	formControlProps,
	showTime = true,
	toggleCalender = () => {},
	value,
	className,
	isReadOnly,
	onChange,
	includeDateIntervals,
	calendarIconName = 'calendar',
	isClearable = false,
	maxDate,
	minDate,
	shouldCloseOnSelect = false,
	calendarIconSize = '16px',
}) => {
	const [isYearPickerShow, setIsYearPickerShow] = useState<boolean>(false);

	return (
		<FormControl
			isInvalid={!!errorMsg}
			_readOnly={{
				'> label': {
					color: 'darkLight',
				},
			}}
			_invalid={{
				'> div > div > input': {
					borderBottomColor: 'error',
				},
			}}
			{...formControlProps}>
			{label ? <FormLabel {...labelStyles}>{label}</FormLabel> : null}
			<DatePicker
				selected={value}
				placeholderText={placeholder}
				readOnly={isReadOnly}
				// @ts-ignore
				onChange={onChange}
				className={className}
				isClearable={isClearable}
				maxDate={maxDate}
				minDate={minDate}
				icon={
					<Icon
						name={calendarIconName}
						width={calendarIconSize}
						height={calendarIconSize}
					/>
				}
				showIcon={true}
				renderCustomHeader={props => (
					<CustomHeader
						setIsYearPickerShow={setIsYearPickerShow}
						isYearPickerShow={isYearPickerShow}
						{...props}
					/>
				)}
				popperPlacement={showTime ? 'right-start' : 'bottom-end'}
				portalId="date-picker"
				showYearPicker={isYearPickerShow}
				showTimeInput={showTime}
				customTimeInput={
					<CustomInput date={value!} setSelectedDate={onChange!} />
				}
				timeIntervals={15}
				timeFormat="h:mm aa"
				toggleCalendarOnIconClick={true}
				closeOnScroll={true}
				dateFormat={showTime ? 'M/d/yyyy h:mm aa' : 'M/d/yyyy'}
				shouldCloseOnSelect={shouldCloseOnSelect}
				onCalendarOpen={() => toggleCalender(true)}
				onCalendarClose={() => toggleCalender(false)}
				includeDateIntervals={includeDateIntervals}
			/>
			<FormErrorMessage
				color="error"
				fontSize="12px"
				lineHeight="18px"
				fontWeight={400}>
				{errorMsg}
			</FormErrorMessage>
		</FormControl>
	);
};
