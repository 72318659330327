import React from 'react';
import { Box, Td, Text, Tr } from '@chakra-ui/react';
import { ReportsFinalAnalysisItemType } from '../../../../../types';
import { trickyTableDividerStyles } from '../../../../../constants';

export const TableRow: React.FC<ReportsFinalAnalysisItemType> = ({
	adcDmiRatio,
	adgRatio,
	avgDailyCog,
	avgDailyGain,
	cogRatio,
	conversionDmi,
	eid,
	ranchTag,
	sickDaysDuringTestCount,
	testAdcLbsAsFed,
	testAdcLbsDmi,
	testDuration,
}) => {
	return (
		<Tr>
			<Td p="8px">
				<Text color="darker" textAlign="center" minW="90px">
					{ranchTag}
				</Text>
			</Td>
			<Td p="8px" position="relative">
				<Text color="darker" textAlign="center" minW="90px">
					{eid || ''}
				</Text>
				<Box {...trickyTableDividerStyles} />
			</Td>
			<Td>{sickDaysDuringTestCount}</Td>
			<Td>{testDuration}</Td>
			<Td>{avgDailyGain}</Td>
			<Td>{adgRatio}</Td>
			<Td>{testAdcLbsAsFed}</Td>
			<Td>{testAdcLbsDmi}</Td>
			<Td>{adcDmiRatio}</Td>
			<Td>{conversionDmi}</Td>
			<Td>{avgDailyCog}</Td>
			<Td>{cogRatio}</Td>
		</Tr>
	);
};
