import { Button, Flex, Text } from '@chakra-ui/react';
import { Icon } from '../../../../components';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '../../../../constants/route-paths';

export const SentEmail = () => {
	const nav = useNavigate();
	const handleRedirect = () => {
		nav(ROUTE_PATH.sign_in);
	};
	return (
		<Flex
			flexDirection={'column'}
			alignItems={'center'}
			justifyContent={'center'}
			gap={'40px'}
			width={'422px'}>
			<Flex
				flexDirection={'column'}
				alignItems={'center'}
				justifyContent={'center'}
				gap={'16px'}
				width={'100%'}>
				<Flex alignItems={'center'} gap={'24px'}>
					<Icon name={'check'} width="40px" height="40px" />
					<Text color={'dark'} variant={'B04'}>
						Sent
					</Text>
				</Flex>
				<Text color={'dark'} variant={'B02'} textAlign={'center'}>
					Please check your mailbox and change password <br /> for a
					new one
				</Text>
			</Flex>
			<Button variant={'primary'} onClick={handleRedirect}>
				Ok
			</Button>
		</Flex>
	);
};
