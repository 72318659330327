import React from 'react';
import { Tr, Td, Text, Box } from '@chakra-ui/react';
import {
	AnimalStatus,
	ContemporaryGroup,
	DnaStatus,
	GenderStatus,
	ReportsAnimalInformationItemType,
} from '../../../../../types';
import { getDateByFormat } from '../../../../../utils';
import { trickyTableDividerStyles } from '../../../../../constants';

export const TableRow: React.FC<ReportsAnimalInformationItemType> = ({
	birthday,
	contemporary,
	dam,
	dna,
	eidFull,
	eidNote,
	eidTruncated,
	regNumber,
	sex,
	sire,
	status,
	eid,
	ranchTag,
}) => {
	return (
		<Tr>
			<Td p="8px">
				<Text color="darker" textAlign="center" minW="100px">
					{ranchTag}
				</Text>
			</Td>
			<Td p="8px" position="relative">
				<Text color="darker" textAlign="center" minW="100px">
					{eid || ''}
				</Text>
				<Box {...trickyTableDividerStyles} />
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{regNumber}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{getDateByFormat(birthday)}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{sex === GenderStatus.Bull ? 'B' : 'C'}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{DnaStatus[dna]}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center" whiteSpace="nowrap">
					{sire}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center" whiteSpace="nowrap">
					{dam}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{eidFull}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{eidTruncated}
				</Text>
			</Td>
			<Td p="8px">
				<Text
					color="darker"
					textAlign="center"
					w="full"
					maxW="200px"
					isTruncated={true}>
					{eidNote}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{AnimalStatus[status]}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{ContemporaryGroup[contemporary]}
				</Text>
			</Td>
		</Tr>
	);
};
