import React, { PropsWithChildren, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
	Box,
	Button,
	Flex,
	Tab,
	TabList,
	TabPanel,
	TabPanels,
	Tabs,
	Text,
	useMediaQuery,
} from '@chakra-ui/react';
import { Icon } from '../../components';
import CustomSelector from '../../components/customSelector/CustomSelector';
import {
	AllFilesCombinedModal,
	AnimalRetagsModal,
	AnimalSickDaysModal,
	AnimalToRemoveModal,
	DailyErrorReportModal,
	DailyFeedDeliveryModal,
	DisqualifiedDaysModal,
	TestDetailsModal,
	TestFeedRationModal,
	TestNotesModal,
} from '../../modals';
import {
	AnimalInfo,
	AnimalWeights,
	AnimalIntakes,
	AnimalBehavior,
	FinalAnalysis,
} from './tabs';
import { useGetTestDates } from '../../query-hooks';

export const Report: React.FC<PropsWithChildren> = () => {
	const navigate = useNavigate();
	const { id } = useParams();

	const [isSmallerThan1350px] = useMediaQuery('(max-width: 1350px)');

	const { data: testDates } = useGetTestDates({
		isEnabled: true,
	});

	const [tabIndex, setTabIndex] = useState(0);
	const [modals, setModals] = useState({
		testFeedRation: false,
		animalRetags: false,
		animalSickDays: false,
		dailyFeedDelivery: false,
		dailyErrorReport: false,
		disqualifiedDays: false,
		testNotes: false,
		testDetails: false,
		animalThatNeedToRemove: false,
		allFilesCombined: false,
	});

	const tabListRef = useRef<HTMLDivElement | null>(null);

	const handleCustomSelectorChange = (selectedItem: string) => {
		switch (selectedItem) {
			case 'Test Feed Ration':
				openModal('testFeedRation');
				break;
			case 'Individual Animal Retags':
				openModal('animalRetags');
				break;
			case 'Individual Animal Sick Days':
				openModal('animalSickDays');
				break;
			case 'Test "Disqualified" Days':
				openModal('disqualifiedDays');
				break;
			case 'Animals that need to be removed':
				openModal('animalThatNeedToRemove');
				break;
			case 'Daily Feed Delivery':
				openModal('dailyFeedDelivery');
				break;
			case 'Test Notes':
				openModal('testNotes');
				break;
			case 'Daily Error Report':
				openModal('dailyErrorReport');
				break;
			case 'Test Details':
				openModal('testDetails');
				break;
			case 'All Files Combined':
				openModal('allFilesCombined');
				break;
			default:
				break;
		}
	};
	const openModal = (modal: string) => {
		setModals(prev => ({ ...prev, [modal]: true }));
	};

	const closeModal = (modal: string) => {
		setModals(prev => ({ ...prev, [modal]: false }));
	};

	const isLeftDisabled = tabIndex === 0;
	const isRightDisabled = tabIndex === 4;

	const handleBack = () => navigate(-1);

	const scrollToTab = (index: number) => {
		const tabList = tabListRef.current;
		if (tabList) {
			const activeTab = tabList.children[index] as HTMLElement;
			if (activeTab) {
				activeTab.scrollIntoView({
					behavior: 'smooth',
					block: 'nearest',
					inline: 'start',
				});
			}
		}
	};

	const onClickLeftArrow = () => {
		if (tabIndex > 0) {
			setTabIndex(prev => prev - 1);
			scrollToTab(tabIndex);
		}
	};

	const onClickRightArrow = () => {
		if (tabIndex < 4) {
			setTabIndex(prev => prev + 1);
			scrollToTab(tabIndex);
		}
	};

	const onChangeTab = (index: number) => {
		setTabIndex(index);
		scrollToTab(index);
	};

	return (
		<Box w="100%" paddingRight="40px">
			<Tabs
				variant="custom"
				isLazy={true}
				index={tabIndex}
				onChange={onChangeTab}>
				<Flex
					alignItems={{ base: 'flex-start', lg: 'center' }}
					gap="16px"
					direction={{ base: 'column', lg: 'row' }}>
					<Flex align="center">
						<Button
							onClick={handleBack}
							minWidth="80px"
							color="primary"
							p="12px"
							paddingLeft="0px"
							gap="8px"
							marginRight="16px">
							<Box boxSize="24px">
								<Icon
									name="left-arrow"
									width="24px"
									height="24px"
								/>
							</Box>
							Back
						</Button>
						<Text
							minWidth={isSmallerThan1350px ? '100px' : '177px'}
							variant="B03"
							color="dark">
							Report #{id || 1}
						</Text>
					</Flex>
					<Flex gap="16px">
						<Flex
							alignItems="center"
							gap="16px"
							w="full"
							maxW={{
								base: 'calc(100vw - 445px)',
								lg: 'calc(100vw - 660px)',
							}}
							minW="240px">
							{isSmallerThan1350px ? (
								<Button
									px={0}
									onClick={onClickLeftArrow}
									isDisabled={isLeftDisabled}
									variant="primary"
									width="24px"
									p="0"
									minW="24px"
									height="24px"
									sx={{
										_disabled: {
											bg: 'gray',
											cursor: 'not-allowed',
											_hover: {
												bg: 'gray',
												cursor: 'not-allowed',
											},
										},
										'@media screen and (min-width: 1900px)':
											{
												display: 'none',
											},
									}}
									borderRadius="50%">
									<Icon
										color="white"
										name="left-arrow"
										width="24px"
										height="24px"
									/>
								</Button>
							) : null}
							<TabList ref={tabListRef} overflow="hidden">
								<Tab
									sx={{
										borderLeft: 'none',
									}}>
									Animal <br />
									information
								</Tab>
								<Tab>
									Animal <br />
									Weights
								</Tab>
								<Tab>
									Animal <br />
									Intakes
								</Tab>
								<Tab>
									Animal <br />
									Behavior
								</Tab>
								<Tab
									sx={{
										borderRight: 'none',
									}}>
									Final <br />
									Analysis
								</Tab>
							</TabList>
							{isSmallerThan1350px ? (
								<Button
									variant="primary"
									onClick={onClickRightArrow}
									isDisabled={isRightDisabled}
									sx={{
										_disabled: {
											bg: 'gray',
											cursor: 'not-allowed',
											_hover: {
												bg: 'gray',
												cursor: 'not-allowed',
											},
										},
										'@media screen and (min-width: 1900px)':
											{
												display: 'none',
											},
									}}
									width="24px"
									height="24px"
									p="0"
									minW="24px"
									borderRadius="50%"
									px={0}>
									<Icon
										name="right-arrow"
										color="white"
										width="24px"
										height="24px"
									/>
								</Button>
							) : null}
						</Flex>
						<CustomSelector onChange={handleCustomSelectorChange} />
					</Flex>
				</Flex>
				<TabPanels marginTop="32px">
					<TabPanel>
						<AnimalInfo />
					</TabPanel>
					<TabPanel>
						<AnimalWeights />
					</TabPanel>
					<TabPanel>
						<AnimalIntakes />
					</TabPanel>
					<TabPanel>
						<AnimalBehavior testDates={testDates?.value} />
					</TabPanel>
					<TabPanel>
						<FinalAnalysis />
					</TabPanel>
				</TabPanels>
			</Tabs>
			<AnimalRetagsModal
				isOpen={modals.animalRetags}
				onClose={() => closeModal('animalRetags')}
			/>
			<AnimalSickDaysModal
				isOpen={modals.animalSickDays}
				onClose={() => closeModal('animalSickDays')}
			/>
			<AnimalToRemoveModal
				isOpen={modals.animalThatNeedToRemove}
				onClose={() => closeModal('animalThatNeedToRemove')}
			/>
			<DailyFeedDeliveryModal
				isOpen={modals.dailyFeedDelivery}
				onClose={() => closeModal('dailyFeedDelivery')}
			/>
			<DisqualifiedDaysModal
				isOpen={modals.disqualifiedDays}
				onClose={() => closeModal('disqualifiedDays')}
			/>
			<TestFeedRationModal
				isOpen={modals.testFeedRation}
				onClose={() => closeModal('testFeedRation')}
			/>
			<TestNotesModal
				isOpen={modals.testNotes}
				onClose={() => closeModal('testNotes')}
			/>
			<DailyErrorReportModal
				testDates={testDates?.value}
				isOpen={modals.dailyErrorReport}
				onClose={() => closeModal('dailyErrorReport')}
			/>
			<TestDetailsModal
				isOpen={modals.testDetails}
				onClose={() => closeModal('testDetails')}
			/>
			<AllFilesCombinedModal
				testDates={testDates?.value}
				isOpen={modals.allFilesCombined}
				onClose={() => closeModal('allFilesCombined')}
			/>
		</Box>
	);
};
