import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import apiBase from '../../api/api.base';
import { QueryKeys } from '../../constants';
import { ApiResponse, ReportsDailyErrorsResponse } from '../../types';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

interface IPayload {
	date: string;
	enabled: boolean;
}

export const useGetReportDailyErrors = ({ date, enabled = true }: IPayload) => {
	const { id: testCycleId } = useParams();
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useQuery({
		queryKey: [
			QueryKeys.GET_REPORTS_DAILY_ERRORS,
			ranchoId,
			testCycleId,
			date,
		],
		queryFn: (): Promise<ApiResponse<ReportsDailyErrorsResponse>> =>
			apiBase.get(
				`/api/ranchos/${ranchoId}/test-cycles/${testCycleId}/daily-errors`,
				{ date },
			),
		enabled: !!testCycleId && enabled,
	});
};
