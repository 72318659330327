import React, { useState, useMemo } from 'react';
import {
	Flex,
	Text,
	Table,
	TableContainer,
	Tbody,
	Th,
	Thead,
	Tr,
	Td,
	Center,
} from '@chakra-ui/react';
import { useGetFeedRation } from '../../../../query-hooks';
import { RationMetaData } from './RationMetaData';
import { RationRow } from './RationRow';
import { NewRationRow } from './NewRationRow';
import { Loader } from '../../../../components';

export const FeedRation: React.FC = () => {
	const [addNewNoteOpen, setAddNewNoteOpen] = useState(false);
	const { data, isLoading } = useGetFeedRation();

	const totalFedPercent = useMemo(
		() =>
			data?.value?.items?.reduce(
				(acc, curr) => acc + curr.asFedPercent,
				0,
			),
		[data],
	);

	const totalDryMatter = useMemo(
		() =>
			data?.value?.items?.reduce((acc, curr) => acc + curr.dryMatter, 0),
		[data],
	);

	const items = data?.value?.items;

	return (
		<Flex
			minH={'calc(100vh - 271px)'}
			minW={'calc(100vw - 304px)'}
			p={'16px'}
			flexDirection={'column'}
			backgroundColor={'light'}
			boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
			borderRadius={'12px'}>
			<TableContainer
				display={'flex'}
				flexDirection={'column'}
				overflowY={'auto'}
				maxH={'500px'}>
				<Table
					variant="custom"
					mb={'32px'}
					sx={{
						td: { borderBottom: 'none' },
						th: { borderBottom: 'none' },
					}}>
					<Thead>
						<Tr>
							<Th>As fed %</Th>
							<Th>DM %</Th>
							<Th>Ingredient</Th>
							<Th>Feed Notes</Th>
							<Th>Actions</Th>
						</Tr>
					</Thead>
					<Tbody>
						{addNewNoteOpen ? (
							<NewRationRow
								onCancel={() => setAddNewNoteOpen(false)}
							/>
						) : null}
						{isLoading ? (
							<Tr>
								<Td colSpan={5}>
									<Loader centerHeight="400px" />
								</Td>
							</Tr>
						) : items?.length === 0 ? (
							<Tr>
								<Td colSpan={5}>
									<Center h="300px">
										<Text variant="B02" color="darkLight">
											No data yet
										</Text>
									</Center>
								</Td>
							</Tr>
						) : (
							items?.map(elem => (
								<RationRow
									key={elem.id}
									asFedPercent={elem.asFedPercent}
									dryMatter={elem.dryMatter}
									ingredient={elem.ingredient}
									note={elem.note}
									id={elem.id}
								/>
							))
						)}
					</Tbody>
				</Table>
			</TableContainer>

			<RationMetaData
				onAddRow={() => {
					setAddNewNoteOpen(true);
				}}
				totalFedPercent={totalFedPercent || 0}
				totalDryMatter={totalDryMatter || 0}
			/>
		</Flex>
	);
};
