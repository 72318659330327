import { colors } from '../theme/colors';

export const inputStyles = {
	lineHeight: '24px',
	fontSize: '16px',
	fontWeight: 400,
	fontFamily: 'Roboto',
	color: 'dark',
	borderRadius: '0',
	border: 'none',
	padding: '12px 0',
	borderBottom: `1px solid ${colors.gray}`,
	_placeholder: {
		color: '#898989',
		fontWeight: 500,
	},
	_focus: {
		borderBottom: `1px solid ${colors.primary} `,
	},
	_invalid: {
		borderBottom: `1px solid ${colors.error}`,
	},
};

export const inputSearchStyles = {
	lineHeight: '20px',
	fontSize: '12px',
	fontWeight: 400,
	color: 'dark',
	borderRadius: '8px',
	border: '1px solid #175D00',
	padding: '12px 8px 12px 32px',
	_placeholder: {
		color: 'gray',
		fontWeight: 500,
	},
};

export const inputFileStyles = {
	lineHeight: '20px',
	fontSize: '12px',
	fontWeight: 400,
	color: 'dark',
	borderRadius: '8px',
	border: '1px solid lightGray',
	padding: '8px',
	_placeholder: {
		color: 'gray',
		fontWeight: 400,
	},
};

export const inputHorizontalStyles = {
	lineHeight: '24px',
	fontSize: '14px',
	height: 'inherit',
	fontWeight: 500,
	color: 'dark',
	borderRadius: '0',
	border: 'none',
	borderBottom: `1px solid ${colors.gray}`,
	_placeholder: {
		color: '#898989',
		fontWeight: 500,
	},
	_focus: {
		border: 'none',
		borderBottom: `1px solid ${colors.primary} `,
	},
	_invalid: {
		borderBottom: `1px solid ${colors.error}`,
	},
};

export const inputHorizontalStylesReadOnly = {
	lineHeight: '24px',
	fontSize: '14px',
	height: 'inherit',
	fontWeight: 500,
	color: 'dark',
	borderRadius: '0',
	border: 'none',
	padding: '12px 0',
	isReadOnly: true,
	_focus: {
		border: 'none',
	},
};
export const SelectStylesReadOnly = {
	lineHeight: '24px',
	fontSize: '14px',
	height: 'inherit',
	fontWeight: 500,
	color: 'dark',
	borderRadius: '0',
	border: 'none',
	padding: '12px 0',
};

export const inputVerticalStyles = {
	lineHeight: '24px',
	padding: '2px',
	fontSize: '16px',
	height: '40px',
	fontWeight: 400,
	color: 'dark',
	borderRadius: '0',
	border: 'none',
	borderBottom: `1px solid ${colors.gray}`,
	_placeholder: {
		color: '#898989',
		fontWeight: 400,
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		width: 'auto',
		display: 'block',
	},
	_focus: {
		border: 'none',
		borderBottom: `1px solid ${colors.gray}`,
	},
	_invalid: {
		borderBottom: `1px solid ${colors.error}`,
	},
};

export const inputVerticalStylesReadOnly = {
	lineHeight: '24px',
	fontSize: '16px',
	height: '40px',
	fontWeight: 400,
	color: 'darkLight',
	borderRadius: '0',
	border: 'none',
	borderBottom: '1px solid lightGray',
	padding: '2px',
	isReadOnly: true,
	cursor: 'not-allowed',
	_focus: {
		borderBottom: '1px solid lightGray',
		cursor: 'not-allowed',
	},
};
