export const ContemporaryGroupOptions = [
	{
		label: 'None',
		value: '1',
	},
	{
		label: 'Natural',
		value: '2',
	},
	{
		label: 'Artificial',
		value: '3',
	},
	{
		label: 'Pasture',
		value: '4',
	},
	{
		label: 'Feedlot',
		value: '5',
	},
	{
		label: 'Organic',
		value: '6',
	},
];

export const AnimalGenderOptions = [
	{
		label: 'B',
		value: '1',
	},
	{
		label: 'C',
		value: '2',
	},
];

export const DNACollectedOptions = [
	{
		label: 'yes',
		value: '1',
	},
	{
		label: 'no',
		value: '2',
	},
];
