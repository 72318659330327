import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import API from '../../api/api.base';
import { queryClient, QueryKeys } from '../../constants';
import { ApiResponse } from '../../types';
import { Alerter } from '../../utils';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

export const useCreateAnimalWeight = (sessionId: number) => {
	const { id } = useParams();
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useMutation<
		ApiResponse<any>,
		unknown,
		{
			animalInTestCycleId: number;
			weight: number;
			note: string;
		}
	>({
		mutationFn: payload =>
			API.post(
				`/api/ranchos/${ranchoId}/test-cycles/${id}/measurement-sessions/${sessionId}/animal-weight`,
				payload,
			),
		onSuccess: response => {
			if (response.success) {
				Alerter.success('Session added');
				queryClient.invalidateQueries({
					queryKey: [QueryKeys.GET_MEASUREMENT_SESSIONS],
				});
			} else {
				Alerter.error(response.errors?.[0]?.message);
			}
		},
	});
};
