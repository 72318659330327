import React, { useEffect, useMemo } from 'react';
import {
	Box,
	Button,
	Divider,
	Flex,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputLeftAddon,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { CustomDatePicker } from '../../../components';
import {
	formControlHorizontalStyles,
	labelVerticalStyles,
	inputVerticalStyles,
	inputVerticalStylesReadOnly,
} from '../../../constants';
import { useGetTestCyclePreSurvey } from '../../../query-hooks/pre-survey/useGetTestCyclePreSurvey';
import {
	useGetBinSettings,
	useGetAvailableTestIntervals,
} from '../../../query-hooks';
import { useUpdatePreSurvey } from '../../../query-hooks/pre-survey/useUpdatePreSurvey';
import { UpdatePreSurveyPayload } from '../../../types/pre-survey.types';
import { colors } from '../../../theme/colors';
import { ChangeBinSettingsModal } from '../../../modals/ChangeBinSettings/ChangeBinSettingsModal';

interface PreSurveyFormValues {
	customerName: string;
	representative: string;
	customerId: string;
	premiseId: string;
	premiseState: string;
	testNumber: string;
	testGroup: string;
	testHead: string;
	breed: string;
	weightStartLow: string;
	weightStartHigh: string;
	vGate: string;
	turnInDate: Date;
	testStart: Date;
	testFinish: Date;
	testDietCost: string;
	testDryMatter: string;
	neg: string;
	nem: string;
	cleanOutEidTagFirst: string;
	cleanOutEidTagSecond: string;
	binSettings: string;
}

export const PreSurvey: React.FC = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
		control,
		watch,
		reset,
	} = useForm<PreSurveyFormValues>();
	const { isOpen, onClose, onOpen } = useDisclosure();
	const { id } = useParams();

	const { data, refetch } = useGetTestCyclePreSurvey();
	const { data: intervalsData } = useGetAvailableTestIntervals(+(id || 0));

	useEffect(() => {
		if (data?.value) {
			reset({
				customerName: data?.value?.customerName,
				representative: data?.value?.representative,
				customerId: data?.value?.customerId,
				premiseId: data?.value?.premiseId,
				premiseState: data?.value?.premiseState,
				testNumber: data?.value?.testNumber?.toString(),
				testGroup: data?.value?.testGroup,
				testHead: data?.value?.testHead.toString(),
				breed: data?.value?.breed,
				weightStartLow: data?.value?.weightStartLow?.toString(),
				weightStartHigh: data?.value?.weightStartHigh?.toString(),
				vGate: data?.value?.vGate?.toString(),
				turnInDate: new Date(data.value.turnInDate),
				testStart: new Date(data.value.testStart),
				testFinish: new Date(data.value.testFinish),
				testDietCost: data?.value?.testDietCost?.toString(),
				testDryMatter: data?.value.testDryMatter?.toString(),
				neg: data?.value?.neg?.toString(),
				nem: data?.value?.nem?.toString(),
				cleanOutEidTagFirst: data?.value?.cleanOutEidTagFirst,
				cleanOutEidTagSecond: data?.value?.cleanOutEidTagSecond,
				binSettings: data?.value?.settingId?.toString(),
			});
		}
	}, [data]);

	const onSuccessCb = () => {
		refetch();
	};
	const updatePreSurvey = useUpdatePreSurvey({ successCb: onSuccessCb });

	const onSubmit: SubmitHandler<PreSurveyFormValues> = data => {
		// console.log(data);
		const payload: UpdatePreSurveyPayload = {
			...data,
			testStart: dayjs(data.testStart).format('YYYY-MM-DD'),
			turnInDate: dayjs(data.turnInDate).format('YYYY-MM-DD'),
			testFinish: dayjs(data.testFinish).format('YYYY-MM-DD'),
		};

		updatePreSurvey.mutate(payload);
	};

	const { data: binSettings } = useGetBinSettings();
	const allowedIntervals = useMemo(
		() =>
			intervalsData?.value.intervals.map(elem => ({
				start: new Date(elem.from),
				end: new Date(elem.to),
			})),
		[intervalsData],
	);

	const watchFields = watch([
		'testNumber',
		'testHead',
		'weightStartLow',
		'weightStartHigh',
		'vGate',
		'turnInDate',
		'testStart',
		'testFinish',
		'testDietCost',
		'testDryMatter',
		'neg',
		'nem',
	]);

	const isDisabled = !watchFields.every(field => field);

	return (
		<>
			<Box
				backgroundColor={'light'}
				boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
				borderRadius={'12px'}
				padding={'32px'}>
				<form onSubmit={handleSubmit(onSubmit)}>
					<Flex flexDirection={'column'}>
						<Flex paddingTop={'0px'} align={'stretch'}>
							{/*LEFT TWO COLUMNS*/}
							<Flex flexDirection={'column'} width={'50%'}>
								<Flex gap={'24px'}>
									<Flex
										width={'532px'}
										flexDirection={'column'}
										gap={'24px'}>
										<FormControl
											{...formControlHorizontalStyles}>
											<FormLabel {...labelVerticalStyles}>
												Customer
											</FormLabel>
											<Input
												{...inputVerticalStyles}
												{...register(
													'customerName',
													{},
												)}
												placeholder={'Enter Customer'}
											/>
										</FormControl>

										<FormControl
											{...formControlHorizontalStyles}>
											<FormLabel {...labelVerticalStyles}>
												Customer ID
											</FormLabel>
											<Input
												{...inputVerticalStyles}
												{...register('customerId', {})}
												placeholder={
													'Enter Customer ID'
												}
											/>
										</FormControl>

										<FormControl
											{...formControlHorizontalStyles}>
											<FormLabel {...labelVerticalStyles}>
												Premise State
											</FormLabel>
											<Input
												{...inputVerticalStyles}
												{...register(
													'premiseState',
													{},
												)}
												placeholder={
													'Enter Premise State'
												}
											/>
										</FormControl>

										<FormControl
											{...formControlHorizontalStyles}>
											<FormLabel {...labelVerticalStyles}>
												Test Group
											</FormLabel>
											<Input
												{...inputVerticalStyles}
												{...register('testGroup', {})}
												placeholder={'Enter Test Group'}
											/>
										</FormControl>

										<FormControl
											{...formControlHorizontalStyles}>
											<FormLabel {...labelVerticalStyles}>
												Breed
											</FormLabel>
											<Input
												{...inputVerticalStyles}
												{...register('breed', {})}
												placeholder={'Enter Breed'}
											/>
										</FormControl>
									</Flex>

									<Flex
										width={'532px'}
										flexDirection={'column'}
										gap={'24px'}>
										<FormControl
											{...formControlHorizontalStyles}>
											<FormLabel {...labelVerticalStyles}>
												Representative
											</FormLabel>
											<Input
												{...inputVerticalStyles}
												{...register(
													'representative',
													{},
												)}
												placeholder={
													'Enter Representative'
												}
											/>
										</FormControl>
										<FormControl
											{...formControlHorizontalStyles}>
											<FormLabel {...labelVerticalStyles}>
												Premise ID
											</FormLabel>
											<Input
												{...inputVerticalStyles}
												{...register('premiseId', {})}
												placeholder={'Enter Premise ID'}
											/>
										</FormControl>
										<FormControl
											{...formControlHorizontalStyles}>
											<FormLabel {...labelVerticalStyles}>
												Test #
											</FormLabel>
											<Input
												{...inputVerticalStyles}
												{...register('testNumber', {})}
												placeholder={'Enter Test #'}
											/>
										</FormControl>
										<FormControl
											{...formControlHorizontalStyles}>
											<FormLabel {...labelVerticalStyles}>
												Test Head #
											</FormLabel>
											<Input
												{...inputVerticalStyles}
												{...register('testHead', {})}
												placeholder={
													'Enter Test Head #'
												}
											/>
										</FormControl>
										<FormControl
											{...formControlHorizontalStyles}>
											<FormLabel {...labelVerticalStyles}>
												V-Gate setting
											</FormLabel>
											<Input
												{...inputVerticalStyles}
												{...register('vGate', {})}
												placeholder={
													'Enter V-Gate Setting'
												}
											/>
										</FormControl>
									</Flex>
								</Flex>

								<FormControl
									{...formControlHorizontalStyles}
									mt={'24px'}>
									<FormLabel {...labelVerticalStyles}>
										Weight Range (Start)
									</FormLabel>
									<Flex
										flexDirection={'column'}
										gap={'19px'}
										width={'100%'}>
										<Flex gap={'24px'}>
											<Input
												{...inputVerticalStylesReadOnly}
												isReadOnly={true}
												defaultValue={'Low'}
											/>
											<Input
												{...inputVerticalStyles}
												{...register('weightStartLow', {
													required: true,
												})}
												placeholder={'Enter Info'}
											/>
										</Flex>
										<Flex gap={'24px'}>
											<Input
												{...inputVerticalStylesReadOnly}
												isReadOnly={true}
												defaultValue={'High'}
											/>
											<Input
												{...inputVerticalStyles}
												{...register(
													'weightStartHigh',
													{},
												)}
												placeholder={'Enter Info'}
											/>
										</Flex>
									</Flex>
								</FormControl>
							</Flex>
							<Divider
								orientation="vertical"
								height={'auto'}
								mx={'24px'}
								color={'lightGray'}
							/>

							{/*RIGHT TWO COLUMNS*/}
							<Flex flexDirection={'column'} width={'50%'}>
								<FormControl {...formControlHorizontalStyles}>
									<FormLabel {...labelVerticalStyles}>
										Turn-in Date (Warm-up)
									</FormLabel>
									<Controller
										name="turnInDate"
										control={control}
										render={({
											field: { onChange, value },
										}) => (
											<CustomDatePicker
												showTime={true}
												onChange={onChange}
												className="pre-survey-datepicker"
												value={value}
												errorMsg={
													errors.turnInDate
														?.message as string
												}
												includeDateIntervals={
													allowedIntervals
												}
											/>
										)}
									/>
								</FormControl>

								<Flex mt={'24px'} gap={'24px'}>
									<Flex
										w={'50%'}
										flexDirection={'column'}
										gap={'24px'}>
										<FormControl
											{...formControlHorizontalStyles}>
											<FormLabel {...labelVerticalStyles}>
												Test Start
											</FormLabel>
											<Controller
												name="testStart"
												control={control}
												render={({
													field: { onChange, value },
												}) => (
													<CustomDatePicker
														showTime={false}
														className="pre-survey-datepicker"
														onChange={onChange}
														value={value}
														errorMsg={
															errors.testStart
																?.message as string
														}
														includeDateIntervals={
															allowedIntervals
														}
													/>
												)}
											/>
										</FormControl>
										<FormControl
											{...formControlHorizontalStyles}>
											<FormLabel {...labelVerticalStyles}>
												Test Diet cost/lb (AS FED)
											</FormLabel>
											<InputGroup>
												{watch('testDietCost') ? (
													<InputLeftAddon
														p={'0 2px 0 0'}
														border={'none'}
														borderBottom={`1px solid ${colors.gray}`}
														borderRadius={'0'}>
														$
													</InputLeftAddon>
												) : (
													''
												)}
												<Input
													{...inputVerticalStyles}
													marginRight={'10px'}
													{...register(
														'testDietCost',
														{},
													)}
													placeholder={'$0,00'}
												/>
											</InputGroup>
										</FormControl>

										<FormControl
											{...formControlHorizontalStyles}>
											<FormLabel {...labelVerticalStyles}>
												NEg
											</FormLabel>
											<Input
												{...inputVerticalStyles}
												{...register('neg', {})}
												placeholder={'Enter NEg'}
											/>
										</FormControl>

										<FormControl
											{...formControlHorizontalStyles}>
											<FormLabel {...labelVerticalStyles}>
												Cleanout EID Tag #1
											</FormLabel>
											<Input
												{...inputVerticalStyles}
												{...register(
													'cleanOutEidTagFirst',
													{},
												)}
												placeholder={
													'Enter Cleanout EID Tag #1'
												}
											/>
										</FormControl>
									</Flex>

									<Flex
										w={'50%'}
										flexDirection={'column'}
										gap={'24px'}>
										<FormControl
											{...formControlHorizontalStyles}>
											<FormLabel {...labelVerticalStyles}>
												Test Finish (projected)
											</FormLabel>
											<Controller
												name="testFinish"
												control={control}
												render={({
													field: { onChange, value },
												}) => (
													<CustomDatePicker
														showTime={false}
														className="pre-survey-datepicker"
														onChange={onChange}
														value={value}
														errorMsg={
															errors.testFinish
																?.message as string
														}
														includeDateIntervals={
															allowedIntervals
														}
													/>
												)}
											/>
										</FormControl>
										<FormControl
											{...formControlHorizontalStyles}>
											<FormLabel {...labelVerticalStyles}>
												Test Dry Matter
											</FormLabel>
											<InputGroup>
												{watch('testDryMatter') ? (
													<InputLeftAddon
														p={'0 2px 0 0'}
														border={'none'}
														borderBottom={`1px solid ${colors.gray}`}
														borderRadius={'0'}>
														%
													</InputLeftAddon>
												) : (
													''
												)}
												<Input
													{...inputVerticalStyles}
													marginRight={'10px'}
													{...register(
														'testDryMatter',
														{
															required: true,
														},
													)}
													placeholder={'%0,00'}
												/>
											</InputGroup>
										</FormControl>
										<FormControl
											{...formControlHorizontalStyles}>
											<FormLabel {...labelVerticalStyles}>
												NEm
											</FormLabel>
											<Input
												{...inputVerticalStyles}
												{...register('nem', {})}
												placeholder={'Enter NEm'}
											/>
										</FormControl>
										<FormControl
											{...formControlHorizontalStyles}>
											<FormLabel {...labelVerticalStyles}>
												Cleanout EID Tag #2
											</FormLabel>
											<Input
												{...inputVerticalStyles}
												{...register(
													'cleanOutEidTagSecond',
													{},
												)}
												placeholder={
													'Enter Cleanout EID Tag #2'
												}
											/>
										</FormControl>
									</Flex>
								</Flex>
							</Flex>
						</Flex>

						<Flex
							display={'flex'}
							p={'0'}
							alignSelf={'flex-end'}
							marginRight={'16px'}
							marginTop={'-35px'}
							width={'195px'}>
							<Button
								variant={'primary'}
								type={'submit'}
								isDisabled={isDisabled}>
								Save
							</Button>
						</Flex>
					</Flex>
				</form>
			</Box>
			<Box
				mt={'40px'}
				maxW={'530px'}
				backgroundColor={'light'}
				boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
				borderRadius={'12px'}
				padding={'32px'}>
				<Flex justify={'space-between'} align={'center'}>
					<Flex
						flexDirection={'column'}
						justify={'space-between'}
						gap={'10px'}>
						<Text
							color={'primary'}
							fontSize={'14px'}
							fontWeight={'500'}>
							Bin Settings
						</Text>
						{watch('binSettings') && binSettings?.value.length ? (
							<Text fontSize={'16px'} color={'dark'}>
								{binSettings.value.find(
									it =>
										it.id === Number(watch('binSettings')),
								)?.name || 'Not found'}
							</Text>
						) : (
							'NULL'
						)}
					</Flex>
					<Flex>
						<Button variant={'primary'} onClick={onOpen}>
							Change
						</Button>
					</Flex>
				</Flex>
			</Box>
			<ChangeBinSettingsModal
				isOpen={isOpen}
				onClose={onClose}
				binSettings={binSettings}
				currentSettingId={data?.value.settingId}
				onSuccessCb={() => {
					refetch();
				}}
			/>
		</>
	);
};
