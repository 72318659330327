import React, { useState } from 'react';
import {
	Button,
	Modal,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	Center,
} from '@chakra-ui/react';
import { colors } from '../../../theme/colors';
import { Icon, Loader, Pagination } from '../../../components';
import { useGetAnimalRemoveNotes } from '../../../query-hooks';
import { AnimalToRemoveModalRow } from './AnimalToRemoveModalRow';

interface AnimalToRemoveModalProps {
	isOpen: boolean;
	onClose: () => void;
}

export const AnimalToRemoveModal: React.FC<AnimalToRemoveModalProps> = ({
	isOpen,
	onClose,
}) => {
	const [page, setPage] = useState(1);
	const { data, isLoading } = useGetAnimalRemoveNotes({
		search: undefined,
		page,
	});

	const items = data?.value?.items;

	return (
		<Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
			<ModalOverlay />
			<ModalContent
				minH={'calc(100vh - 130px)'}
				maxW={'1134px'}
				borderRadius={'24px'}
				p={'32px'}>
				<ModalHeader display={'flex'} justifyContent={'center'}>
					<Text variant={'B03'} color={'dark'} fontSize={'20px'}>
						Animals that need to be removed
					</Text>
					<Button
						width={'40px'}
						height={'40px'}
						border={`1px solid ${colors.primary}`}
						p={'0px'}
						onClick={onClose}
						position={'absolute'}
						right={'16px'}
						borderRadius={'50%'}>
						<Icon
							name="close"
							color={colors.primary}
							width={'24px'}
							height={'24px'}
						/>
					</Button>
				</ModalHeader>
				<TableContainer
					display={'flex'}
					flexDirection={'column'}
					flexGrow={1}>
					{isLoading ? (
						<Loader />
					) : (
						<Table
							variant="custom"
							mb={'32px'}
							sx={{
								tr: { borderBottom: 'none !important' },
								td: { verticalAlign: 'top' },
							}}>
							<Thead>
								<Tr>
									<Th w="100px">#</Th>
									<Th w="100px">Tag #</Th>
									<Th>
										Notes: reason for the day being
										disqualified. Sickness, Doctoring, etc.
									</Th>
								</Tr>
							</Thead>
							<Tbody>
								{items?.length ? (
									items?.map(row => (
										<AnimalToRemoveModalRow
											key={row.id}
											id={row.id}
											ranchTag={row.ranchTag}
											note={row.note}
										/>
									))
								) : (
									<Tr>
										<Td colSpan={5}>
											<Center h="300px">
												<Text
													variant="B02"
													color="darkLight">
													No data yet
												</Text>
											</Center>
										</Td>
									</Tr>
								)}
							</Tbody>
						</Table>
					)}
				</TableContainer>
				<Pagination
					totalCount={data?.value?.count || 0}
					currentPage={page}
					pageSize={10}
					onPageChange={val => setPage(val)}
				/>
			</ModalContent>
		</Modal>
	);
};
