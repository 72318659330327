import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import API from '../../api/api.base';
import { QueryKeys } from '../../constants';
import { ApiResponse } from '../../types';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

export const useGetMeasurementSessions = () => {
	const { id } = useParams();
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useQuery<
		ApiResponse<{
			animals: {
				animalInTestId: number;
				eid: string;
				ranchTag: string;
				animalInTestCycleSickCount: number;
			}[];
			weightSessions: {
				sessionId: number;
				sessionName: string;
				date: string;
				weights: {
					animalInTestId: number;
					weight: number;
					note: string;
				}[];
			}[];
		}>
	>({
		queryKey: [QueryKeys.GET_MEASUREMENT_SESSIONS],
		queryFn: () =>
			API.get(
				`/api/ranchos/${ranchoId}/test-cycles/${id}/measurement-sessions`,
				{},
			),
	});
};
