import {
	Button,
	HStack,
	IconButton,
	Td,
	Text,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';
import {
	AnimalStatus,
	AnimalTest,
	ContemporaryGroup,
	DnaStatus,
	GenderStatus,
} from '../../../../../types';
import { DeleteConfirmPopup, Icon } from '../../../../../components';
import { colors } from '../../../../../theme/colors';
import { EditAnimalInfoModal } from '../modals';
import { useDeleteTestCyclesAnimal } from '../../../../../query-hooks';
import { getDateByFormat } from '../../../../../utils';

export const TableRow: React.FC<AnimalTest> = ({
	ranchTag,
	regNumber,
	sex,
	sire,
	birthday,
	dna,
	dam,
	contemporary,
	eid,
	eidFull,
	eidNote,
	eidTruncated,
	status,
	id,
	// isRemovedFromTestCycle,
	// animalId,
}) => {
	const {
		isOpen: isOpenEditAnimalModal,
		onOpen: onOpenEditAnimalModal,
		onClose: onCloseEditAnimalModal,
	} = useDisclosure();
	const deleteTestCycleAnimalMutation = useDeleteTestCyclesAnimal();

	const onDelete = useCallback(
		() =>
			deleteTestCycleAnimalMutation.mutate(
				{
					animalInTestCycleId: id,
				},
				{
					onSuccess: data => {
						if (!data.success) {
							return;
						}
						onCloseEditAnimalModal();
					},
				},
			),
		[id, deleteTestCycleAnimalMutation, onCloseEditAnimalModal],
	);

	return (
		<Tr>
			<Td p="8px">
				<HStack spacing="15px" justify="center">
					<Button
						padding={0}
						w="20px"
						h="20px"
						minW="20px"
						onClick={onOpenEditAnimalModal}>
						<Icon
							name="small-pen"
							width={'20px'}
							height={'20px'}
							color={`${colors.dark}`}
						/>
					</Button>
					<DeleteConfirmPopup
						title="Are you sure you want to delete that animal?"
						onConfirm={onDelete}
						confirmButtonProps={{
							isLoading: deleteTestCycleAnimalMutation.isPending,
						}}>
						<IconButton
							padding={0}
							aria-label="Delete animal"
							w="20px"
							h="20px"
							minW="20px">
							<Icon
								name="trash"
								width={'20px'}
								height={'20px'}
								color={colors.error}
							/>
						</IconButton>
					</DeleteConfirmPopup>
				</HStack>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{ranchTag}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{eid}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{regNumber}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{getDateByFormat(birthday)}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{sex === GenderStatus.Bull ? 'B' : 'C'}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{DnaStatus[dna]}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center" whiteSpace="nowrap">
					{sire}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center" whiteSpace="nowrap">
					{dam}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{eidFull}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{eidTruncated}
				</Text>
			</Td>
			<Td p="8px">
				<Text
					color="darker"
					textAlign="center"
					w="full"
					maxW="200px"
					isTruncated={true}>
					{eidNote}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{AnimalStatus[status]}
				</Text>
			</Td>
			<Td p="8px">
				<Text color="darker" textAlign="center">
					{ContemporaryGroup[contemporary]}
				</Text>
			</Td>
			<Td display="none">
				<EditAnimalInfoModal
					onClose={onCloseEditAnimalModal}
					isOpen={isOpenEditAnimalModal}
					id={id}
				/>
			</Td>
		</Tr>
	);
};
