import React, { PropsWithChildren, useEffect, useState } from 'react';
import {
	Avatar,
	Box,
	Button,
	Flex,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';
import { Icon, InputText, Loader } from '../../components';
import { useGetUsersDetails, useUpdateUserDetails } from '../../query-hooks';
import { colors } from '../../theme/colors';
import {
	ChangeAvatarModal,
	ChangePasswordModal,
	LogOutModal,
} from '../../modals';
import { SubmitHandler, useForm } from 'react-hook-form';
import defaultAvatar from '../../assets/images/default-avatar.webp';

export interface ProfileSettingsFormValues {
	username: string;
	phone: string;
	email: string;
	avatarUrl: string;
}
export const ProfileSettings: React.FC<PropsWithChildren> = () => {
	const { data: user, isLoading, refetch } = useGetUsersDetails();
	const [avatarUrl, setAvatarUrl] = useState<string>(
		user?.value?.avatarUrl || defaultAvatar,
	);
	const [uploadedImage, setUploadedImage] = useState<File | null>(null);

	const { isOpen, onOpen, onClose } = useDisclosure();
	const updateUserDetails = useUpdateUserDetails();

	const {
		register,
		reset,
		handleSubmit,
		formState: { errors },
	} = useForm<ProfileSettingsFormValues>({
		defaultValues: {
			username: '',
			phone: '',
			email: '',
		},
	});

	const onSubmit: SubmitHandler<ProfileSettingsFormValues> = data => {
		const formData = new FormData();
		formData.append('UserName', data.username);
		formData.append('PhoneNumber', data.phone);
		if (uploadedImage) {
			formData.append('Image', uploadedImage);
		}
		updateUserDetails.mutate(formData, {
			onSuccess: () => {
				refetch();
			},
		});
	};

	const {
		isOpen: isPasswordModalOpen,
		onOpen: onPasswordModalOpen,
		onClose: onPasswordModalClose,
	} = useDisclosure();
	const {
		isOpen: isLogOutModalOpen,
		onOpen: onLogOutModalOpen,
		onClose: onLogOutModalClose,
	} = useDisclosure();

	useEffect(() => {
		if (user) {
			reset({
				username: user.value?.userName || '',
				phone: user.value?.phoneNumber || '',
				email: user.value?.email || '',
			});
		}
	}, [user, reset]);

	useEffect(() => {
		if (user && user.value?.avatarUrl) {
			setAvatarUrl(user.value.avatarUrl);
		}
	}, [user]);

	const handleAvatarSave = (newAvatarUrl: string, file: File) => {
		setAvatarUrl(newAvatarUrl);
		setUploadedImage(file);
	};

	if (isLoading) {
		return <Loader centerProps={{ width: '100%' }} />;
	}

	return (
		<Flex
			flexDirection={'column'}
			width={'422px'}
			margin={' 0 auto'}
			alignItems={'center'}
			gap={'40px'}>
			<Box position={'relative'}>
				<Avatar
					w={'200px'}
					h={'200px'}
					name="Christian Nwamba"
					src={avatarUrl}
				/>
				<Flex
					h={'48px'}
					w={'48px'}
					position={'absolute'}
					bottom={0}
					right={0}
					justifyContent={'center'}
					alignItems={'center'}
					borderRadius={'50%'}
					border={'2px solid #175D00'}
					backgroundColor={'light'}
					cursor={'pointer'}
					onClick={onOpen}>
					<Icon name={'big-pen'} width="32px" height="32px" />
				</Flex>
			</Box>
			<form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
				<Flex flexDirection={'column'} gap={'40px'} w={'100%'}>
					<VStack spacing={'24px'}>
						<InputText
							label={'Your username'}
							variant={'flushed'}
							placeholder={'Enter your username'}
							autoComplete={''}
							rightElement={
								<Icon
									name={'small-pen'}
									width="20px"
									height="20px"
									color={'gray'}
								/>
							}
							{...register('username', {
								required: 'Username is required',
							})}
							errorMsg={errors.username?.message as string}
						/>
						<InputText
							label={'Your phone number'}
							variant={'flushed'}
							placeholder={'Enter your phone number'}
							autoComplete={''}
							rightElement={
								<Icon
									name={'small-pen'}
									width="20px"
									height="20px"
									color={'gray'}
								/>
							}
							{...register('phone', {
								required: 'Enter your phone',
								pattern: {
									value: /^[0-9]+$/,
									message: 'Phone number must be digits only',
								},
							})}
							errorMsg={errors.phone?.message as string}
						/>
						<InputText
							label={'Your email'}
							variant={'flushed'}
							placeholder={'Enter your email'}
							isReadOnly={true}
							autoComplete={'email'}
							{...register('email', {
								required: 'Email is required',
								pattern: {
									value: /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/,
									message: 'Invalid email address',
								},
							})}
							errorMsg={errors.email?.message as string}
						/>
						<Box onClick={onPasswordModalOpen} w={'100%'}>
							<Text mb={'12px'}>Your password</Text>
							<Flex
								justifyContent={'space-between'}
								borderBottom={`1px solid ${colors.dark}`}
								pb={'5px'}
								pr={'10px'}>
								<Text color={'dark'}>**********</Text>
								<Icon
									name={'right-arrow'}
									width="20px"
									height="20px"
									color={'gray'}
								/>
							</Flex>
						</Box>
					</VStack>
					<Button
						variant={'primary'}
						height={'48px'}
						onClick={handleSubmit(onSubmit)}
						isLoading={updateUserDetails.isPending}>
						Save Changes
					</Button>
					<Button
						variant={'secondary'}
						height={'48px'}
						onClick={onLogOutModalOpen}>
						Log Out
						<Box ml={'8px'}>
							<Icon name={'log-out'} width="24px" height="24px" />
						</Box>
					</Button>
				</Flex>
			</form>

			<ChangePasswordModal
				isOpen={isPasswordModalOpen}
				onClose={onPasswordModalClose}
			/>
			<LogOutModal
				isOpen={isLogOutModalOpen}
				onClose={onLogOutModalClose}
			/>
			<ChangeAvatarModal
				isOpen={isOpen}
				onClose={onClose}
				onSave={handleAvatarSave}
				avatarUrl={avatarUrl}
			/>
		</Flex>
	);
};
