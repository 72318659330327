import React, { useState } from 'react';
import {
	Button,
	Flex,
	Table,
	TableContainer,
	Tbody,
	Th,
	Thead,
	Tr,
	Td,
	Center,
	Text,
} from '@chakra-ui/react';
import { colors } from '../../../../theme/colors';
import { Icon, Pagination, Loader } from '../../../../components';
import { useGetDisqualifiedDays } from '../../../../query-hooks';
import { NewDisqualifiedDayRow } from './NewDisqualifiedDayRow';
import { DisqualifiedDayRow } from './DisqualifiedDayRow';

export const DisqualifiedDays: React.FC = () => {
	const [addNewNoteOpen, setAddNewNoteOpen] = useState(false);
	const [page, setPage] = useState(1);
	const { data, isLoading } = useGetDisqualifiedDays(page);

	const items = data?.value?.items;

	return (
		<TableContainer
			backgroundColor={'light'}
			boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
			borderRadius={'12px'}
			display={'flex'}
			flexDirection={'column'}
			minH={'calc(100vh - 271px)'}
			minW={'calc(100vw - 304px)'}
			p={'16px'}>
			<Table
				variant="custom"
				mb={'32px'}
				sx={{
					td: { borderBottom: 'none' },
					th: { borderBottom: 'none' },
				}}>
				<Thead>
					<Tr>
						<Th w="100px">#</Th>
						<Th w="140px">Date</Th>
						<Th>
							Notes: reason for the day being disqualified.
							Weather event, environmental, group behaviour. etc.
						</Th>
						<Th w="120px">Actions</Th>
					</Tr>
				</Thead>
				<Tbody>
					{addNewNoteOpen ? (
						<NewDisqualifiedDayRow
							onCancel={() => setAddNewNoteOpen(false)}
						/>
					) : null}
					{isLoading ? (
						<Tr>
							<Td colSpan={5}>
								<Loader centerHeight="400px" />
							</Td>
						</Tr>
					) : items?.length === 0 ? (
						<Tr>
							<Td colSpan={5}>
								<Center h="300px">
									<Text variant="B02" color="darkLight">
										No data yet
									</Text>
								</Center>
							</Td>
						</Tr>
					) : (
						items?.map((row, index) => (
							<DisqualifiedDayRow
								key={row.id}
								id={row.id}
								date={row.date}
								note={row.note}
								index={index}
							/>
						))
					)}
				</Tbody>
			</Table>
			<Flex
				flexDirection={'column'}
				flexGrow={'1'}
				justifyContent={'flex-end'}>
				<Flex justify="center" mb={4}>
					<Button
						onClick={() => {
							setAddNewNoteOpen(true);
						}}
						color={'primary'}>
						<Icon
							name="add"
							width={'20px'}
							height={'20px'}
							color={`${colors.primary}`}
						/>
						Add Info
					</Button>
				</Flex>
			</Flex>
			<Pagination
				totalCount={data?.value?.count || 0}
				currentPage={page}
				pageSize={10}
				onPageChange={val => setPage(val)}
			/>
		</TableContainer>
	);
};
