import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../constants';
import { Animals, ApiResponse } from '../../types';
import apiBase from '../../api/api.base';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

export const useGetAnimals = (page: number) => {
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useQuery({
		queryKey: [QueryKeys.GET_ANIMALS, page],
		queryFn: (): Promise<ApiResponse<Animals>> =>
			apiBase.get(`/api/ranchos/${ranchoId}/animals`, {
				PageSize: 5,
				CurrentPage: page,
			}),
	});
};
