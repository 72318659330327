import { createStandaloneToast } from '@chakra-ui/react';
import { PayloadError } from '../types';
import { theme } from '../theme';
import { Icon } from '../components';

const toast = createStandaloneToast({ theme });

export class Alerter {
	static success(message: string) {
		toast.toast({
			title: message,
			status: 'success',
			duration: 5000,
			isClosable: true,
			position: 'bottom-right',
			containerStyle: {
				backgroundColor: 'primary',
				borderRadius: '8px',
				fontSize: '12px',
				color: '#F1FFEC',
				fontWeight: 400,
			},
			icon: (
				<Icon name="check" width="24px" height="24px" color="white" />
			),
		});
	}
	static error(message: { errors: PayloadError[] } | string) {
		if (typeof message !== 'string') {
			return;
		}

		toast.toast({
			title: message,
			status: 'error',
			duration: 5000,
			isClosable: true,
			position: 'top-right',
			containerStyle: {
				borderRadius: '8px',
			},
		});
	}
	static warning(message: string) {
		toast.toast({
			title: 'Warning',
			description: message,
			status: 'warning',
			duration: 5000,
			isClosable: true,
			position: 'top-right',
			containerStyle: {
				borderRadius: '8px',
			},
		});
	}
	static info(message: string) {
		toast.toast({
			title: 'Info',
			description: message,
			status: 'info',
			variant: 'subtle',
			duration: 5000,
			isClosable: true,
			position: 'top-right',
			containerStyle: {
				color: 'white',
				borderRadius: '8px',
			},
		});
	}
}
