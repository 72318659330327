import React, { Suspense, ReactNode } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Loader } from '../Loader/Loader';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

interface IProtectedRouteProps {
	children?: ReactNode;
}

export const ProtectedRoute: React.FC<IProtectedRouteProps> = ({
	children,
}) => {
	const location = useLocation();
	const { accessToken } = useSelector((state: RootStore) => state.auth.token);

	if (!accessToken) {
		return <Navigate to="/" state={{ from: location }} replace={true} />;
	}

	return children ? (
		<Suspense fallback={<Loader centerProps={{ minH: '100vh' }} />}>
			{children}
		</Suspense>
	) : (
		<Outlet />
	);
};
