import React from 'react';
import { Tr, Td, Text, Box } from '@chakra-ui/react';
import { ReportsAnimalBehaviorItemType } from '../../../../../types';
import { trickyTableDividerStyles } from '../../../../../constants';
import { getDurationFromMinutes } from '../../../../../utils';

export const TableRow: React.FC<ReportsAnimalBehaviorItemType> = ({
	dailyAnimalBehavior,
	cumulative,
	eid,
	ranchTag,
}) => {
	const formattedCumulativeTotalDuration = cumulative?.totalDuration
		? getDurationFromMinutes(+cumulative.totalDuration.toFixed(0))
		: '';
	const formattedCumulativeAverageDuration = cumulative?.averageDuration
		? getDurationFromMinutes(+cumulative.averageDuration.toFixed(0))
		: '';
	return (
		<Tr>
			<Td p="8px">
				<Text color="darker" textAlign="center" minW="90px">
					{ranchTag}
				</Text>
			</Td>
			<Td p="8px" position="relative">
				<Text color="darker" textAlign="center" minW="90px">
					{eid || ''}
				</Text>
				<Box {...trickyTableDividerStyles} />
			</Td>
			{dailyAnimalBehavior?.map((el, index) => {
				const {
					averageDuration,
					broci,
					tagHits,
					totalDuration,
					totalFeedConsumed,
					totalFeedEventsCount,
				} = el.animalBehavior;
				const formattedTotalDuration = totalDuration
					? getDurationFromMinutes(+totalDuration.toFixed(0))
					: 0;
				const formattedAverageDuration = averageDuration
					? getDurationFromMinutes(+averageDuration.toFixed(0))
					: 0;
				return (
					<React.Fragment key={index}>
						<Td>{broci}</Td>
						<Td>{totalFeedEventsCount}</Td>
						<Td>{totalFeedConsumed}</Td>
						<Td style={{ minWidth: '110px' }}>
							{formattedTotalDuration}
						</Td>
						<Td style={{ minWidth: '110px' }}>
							{formattedAverageDuration}
						</Td>
						<Td borderRightWidth="1px" borderRightColor="lightGray">
							{tagHits}
						</Td>
					</React.Fragment>
				);
			})}
			<Td>{cumulative?.broci}</Td>
			<Td>{cumulative?.totalFeedEventsCount}</Td>
			<Td>{cumulative?.totalFeedConsumed}</Td>
			<Td style={{ minWidth: '110px' }}>
				{formattedCumulativeTotalDuration}
			</Td>
			<Td style={{ minWidth: '110px' }}>
				{formattedCumulativeAverageDuration}
			</Td>
			<Td>{cumulative?.tagHits}</Td>
		</Tr>
	);
};
