import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { ApiResponse } from '../../types';
import apiBase from '../../api/api.base';
import { Alerter } from '../../utils';
import { AddTestPayloadValues } from '../../modals/AddTest/types';

interface UseCreateTestArgs {
	successCb?: VoidFunction;
}

export const useCreateTest = ({ successCb }: UseCreateTestArgs) => {
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useMutation<ApiResponse<number>, unknown, AddTestPayloadValues>({
		mutationFn: payload =>
			apiBase.post(`/api/ranchos/${ranchoId}/test-cycles`, payload),
		onSuccess: response => {
			if (!response.success) {
				Alerter.error(response.errors?.[0]?.message);
				return;
			}
			successCb?.();
			Alerter.success('Test created!');
		},
	});
};
