import { Box, HStack, IconButton, Input, Td, Text, Tr } from '@chakra-ui/react';
import React, { useState } from 'react';
import { AnimalTestCyclesRetags } from '../../../types';
import { format } from 'date-fns';

export const AnimalRetagsRowModal: React.FC<AnimalTestCyclesRetags> = ({
	id,
	animalInTestCycleId,
	date,
	oldEid,
	newEid,
	eidNote,
	ranchTag,
}) => {
	return (
		<Tr>
			<Td w="100px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{id}
			</Td>
			<Td w="100px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{ranchTag}
			</Td>
			<Td w="140px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{format(date, 'M/d/yyyy')}
			</Td>
			<Td w="100px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{oldEid}
			</Td>
			<Td w="100px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{newEid}
			</Td>
			<Td verticalAlign="top" style={{ fontSize: '12px' }}>
				{eidNote}
			</Td>
		</Tr>
	);
};
