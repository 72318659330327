import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../constants';
import { Animal, ApiResponse } from '../../types';
import apiBase from '../../api/api.base';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

export const useGetAnimalsGetInfo = (animalId?: number) => {
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useQuery({
		queryKey: [QueryKeys.GET_ANIMALS_GET_INFO, animalId],
		queryFn: (): Promise<ApiResponse<Animal>> =>
			apiBase.get(
				`/api/ranchos/${ranchoId}/animals/${animalId}/get-info`,
				{},
			),
		enabled: !!animalId,
	});
};
