import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import {
	ForgotPassword,
	SignIn,
	SignUp,
	FinalOutput,
	FinalReport,
	ProfileSettings,
	UploadFilesPage,
	CreateNewPassword,
	Tests,
	Animals,
	Report,
	Test,
} from './pages';

import { Loader, ProtectedRoute } from './components';
import { AuthLayout, Layout } from './layout';
import { ROUTE_PATH } from './constants/route-paths';

const SuspenseLoader = <Loader centerProps={{ minH: '100vh' }} />;

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

export const AppRoutes: React.FC = () => {
	return (
		<SentryRoutes>
			<Route element={<AuthLayout />}>
				<Route
					path={ROUTE_PATH.sign_up}
					element={
						<Suspense fallback={SuspenseLoader}>
							<SignUp />
						</Suspense>
					}
				/>
				<Route
					path={ROUTE_PATH.sign_in}
					element={
						<Suspense fallback={SuspenseLoader}>
							<SignIn />
						</Suspense>
					}
				/>
				<Route
					path={ROUTE_PATH.forgot_password}
					element={
						<Suspense fallback={SuspenseLoader}>
							<ForgotPassword />
						</Suspense>
					}
				/>
				<Route
					path={ROUTE_PATH.create_new_password}
					element={
						<Suspense fallback={SuspenseLoader}>
							<CreateNewPassword />
						</Suspense>
					}
				/>
			</Route>
			<Route element={<ProtectedRoute />}>
				<Route element={<Layout />}>
					<Route
						path={ROUTE_PATH.final_output}
						element={
							<Suspense fallback={SuspenseLoader}>
								<FinalOutput />
							</Suspense>
						}
					/>
					<Route
						path={ROUTE_PATH.final_report}
						element={
							<Suspense fallback={SuspenseLoader}>
								<FinalReport />
							</Suspense>
						}
					/>
					<Route
						path={`${ROUTE_PATH.final_report}/:id`}
						element={
							<Suspense fallback={SuspenseLoader}>
								<Report />
							</Suspense>
						}
					/>
					<Route
						path={ROUTE_PATH.upload_files}
						element={
							<Suspense fallback={SuspenseLoader}>
								<UploadFilesPage />
							</Suspense>
						}
					/>
					<Route
						path={ROUTE_PATH.profile_settings}
						element={
							<Suspense fallback={SuspenseLoader}>
								<ProfileSettings />
							</Suspense>
						}
					/>
					<Route
						path={ROUTE_PATH.tests}
						element={
							<Suspense fallback={SuspenseLoader}>
								<Tests />
							</Suspense>
						}
					/>
					<Route
						path={`${ROUTE_PATH.tests}/:id`}
						element={
							<Suspense fallback={SuspenseLoader}>
								<Test />
							</Suspense>
						}
					/>
					<Route
						path={ROUTE_PATH.animals}
						element={
							<Suspense fallback={SuspenseLoader}>
								<Animals />
							</Suspense>
						}
					/>
				</Route>
			</Route>
		</SentryRoutes>
	);
};
