export const colors = {
	primary: '#175D00',
	primary2: '#89AB7D',
	primary3: '#DCE6D8',
	secondary: '#F1FFEC',
	dark: '#010101',
	darker: '#161616',
	error: '#AF0000',
	light: '#FAFAFA',
	gray: '#B0B0AF',
	lightGray: '#ECECEC',
	blue: '#5291DC',
	green: '#39C539',
	yellow: '#FFC235',
	yellowFill: 'rgb(225, 194, 53, 0.25)',
	greenFill: 'rgb(57, 197, 57, 0.15)',
	blueFill: 'rgb(82, 145, 220, 0.15)',
	darkLight: '#898989',
	gray5: '#DDDDDD',
};
