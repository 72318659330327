import React, { useEffect, useRef } from 'react';
import {
	FormControl,
	FormLabel,
	FormErrorMessage,
	FormControlProps,
	InputGroup,
	TextareaProps,
	Textarea,
} from '@chakra-ui/react';
import { inputStyles, labelStyles } from '../../constants';

interface ICustomTextarea extends TextareaProps {
	errorMsg?: string;
	label?: string;
	formControlProps?: FormControlProps;
	minHeight: number;
}

export const CustomTextarea: React.FC<ICustomTextarea> = ({
	label = '',
	errorMsg,
	formControlProps,
	minHeight,
	...rest
}) => {
	const textareaRef = useRef<HTMLTextAreaElement>(null);

	const adjustTextareaHeight = () => {
		const textarea = textareaRef.current;
		if (textarea) {
			textarea.style.height = 'auto';
			const scrollHeight = Math.max(minHeight, textarea.scrollHeight);
			textarea.style.height = `${scrollHeight}px`;
		}
	};

	const handleInputChange = () => {
		adjustTextareaHeight();
	};

	useEffect(() => {
		adjustTextareaHeight();
	}, [rest.value, minHeight]);
	return (
		<FormControl
			variant="floating"
			isInvalid={!!errorMsg}
			id={label}
			border="none"
			boxShadow="none"
			{...formControlProps}>
			{label ? <FormLabel {...labelStyles}>{label}</FormLabel> : null}
			<InputGroup>
				<Textarea
					ref={textareaRef}
					{...inputStyles}
					resize="none"
					rows={1}
					overflowY="clip"
					px="0"
					onInput={handleInputChange}
					_focusVisible={{
						borderBottomColor: 'primary',
					}}
					{...rest}
				/>
			</InputGroup>

			<FormErrorMessage
				color="error"
				fontSize="12px"
				lineHeight="18px"
				fontWeight={400}>
				{errorMsg}
			</FormErrorMessage>
		</FormControl>
	);
};
