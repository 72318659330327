import React, { ReactNode } from 'react';
import {
	FormControl,
	FormControlProps,
	FormLabel,
	Input,
	InputGroup,
	InputProps,
	InputRightElement,
	InputRightElementProps,
} from '@chakra-ui/react';
import { ErrorMessage } from './supplements';
import { inputStyles } from '../../constants/input-styles';
import { labelStyles } from '../../constants';

interface IInputTextProps extends InputProps {
	label?: string;
	errorMsg?: string;
	formControlProps?: FormControlProps;
	rightElement?: ReactNode;
	rightElementProps?: InputRightElementProps;
}

export const InputText = React.forwardRef<HTMLInputElement, IInputTextProps>(
	(
		{
			errorMsg,
			label,
			formControlProps,
			rightElement,
			rightElementProps,
			...rest
		},
		ref,
	) => {
		return (
			<FormControl
				isInvalid={!!errorMsg}
				_readOnly={{
					'> label': {
						color: 'darkLight',
					},
				}}
				{...formControlProps}>
				{label ? <FormLabel {...labelStyles}>{label}</FormLabel> : null}
				<InputGroup>
					<Input
						{...inputStyles}
						ref={ref}
						_readOnly={{
							color: 'darkLight',
							borderBottomColor: 'lightGray',
						}}
						{...rest}
					/>
					{rightElement ? (
						<InputRightElement h={'100%'} {...rightElementProps}>
							{rightElement}
						</InputRightElement>
					) : null}
				</InputGroup>
				<ErrorMessage>{errorMsg}</ErrorMessage>
			</FormControl>
		);
	},
);
InputText.displayName = 'InputText';
