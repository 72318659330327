import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import { ApiResponse } from '../../types';
import apiBase from '../../api/api.base';
import { Alerter } from '../../utils';
import { AddTestPayloadValues } from '../../modals/AddTest/types';
import { useParams } from 'react-router-dom';
import { UpdatePreSurveyPayload } from '../../types/pre-survey.types';

interface UseCreateTestArgs {
	successCb?: VoidFunction;
}

export const useUpdatePreSurvey = ({ successCb }: UseCreateTestArgs) => {
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	const { id } = useParams();
	return useMutation<ApiResponse<number>, unknown, UpdatePreSurveyPayload>({
		mutationFn: payload =>
			apiBase.put(
				`/api/ranchos/${ranchoId}/test-cycles/${id}/user-pre-survey`,
				payload,
			),
		onSuccess: response => {
			if (!response.success) {
				Alerter.error(response.errors?.[0]?.message);
				return;
			}
			successCb?.();
			Alerter.success('Pre survey updated');
		},
	});
};
