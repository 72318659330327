import React from 'react';
import { Tr, Td, IconButton, Input, Box, HStack } from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { Icon, CustomDatePicker, CustomTextarea } from '../../../../components';
import { colors } from '../../../../theme/colors';
import { inputStyles } from '../../../../constants';
import { useCreateSickDay } from '../../../../query-hooks';

interface INewSickDayRowProps {
	onCancel: VoidFunction;
	ranchoTag?: string;
	animalId: number;
}

interface INewNoteForm {
	date: Date;
	note: string;
}

export const NewSickDayRow: React.FC<INewSickDayRowProps> = ({
	onCancel,
	animalId,
	ranchoTag,
}) => {
	const createSickDayMutation = useCreateSickDay(animalId);

	const { control, register, handleSubmit } = useForm<INewNoteForm>();

	const onSubmit = (data: INewNoteForm) => {
		createSickDayMutation.mutate(
			{
				note: data.note,
				date: dayjs(data.date).format('YYYY-MM-DD'),
			},
			{
				onSuccess(data) {
					if (data.success) {
						onCancel();
					}
				},
			},
		);
	};

	return (
		<Tr>
			<Td w="100px" verticalAlign="top"></Td>
			<Td w="100px" verticalAlign="top">
				{ranchoTag}
			</Td>
			<Td w="140px" verticalAlign="top">
				<HStack align="flex-start">
					<Controller
						control={control}
						name="date"
						render={({ field }) => (
							<CustomDatePicker
								showTime={false}
								value={field.value}
								onChange={field.onChange}
								className="row-field-low"
							/>
						)}
					/>
					<Box boxSize="16px" pt="4px">
						<Icon name="calendar" width={'16px'} height={'16px'} />
					</Box>
				</HStack>
			</Td>
			<Td minW="170px" verticalAlign="top">
				<Controller
					name="note"
					control={control}
					defaultValue=""
					render={({ field: { onChange, value } }) => (
						<CustomTextarea
							minHeight={24}
							padding="4px 8px"
							value={value}
							fontSize="12px"
							lineHeight="14px"
							onChange={onChange}
						/>
					)}
				/>
			</Td>
			<Td verticalAlign="top" style={{ width: '90px' }}>
				<HStack justify="space-between" maxW="65px" mx="auto">
					<IconButton
						aria-label="Save note"
						w="20px"
						h="20px"
						minW="20px"
						isLoading={createSickDayMutation.isPending}
						onClick={handleSubmit(onSubmit)}>
						<Icon
							type="submit"
							name="save"
							width={'20px'}
							height={'20px'}
							color={colors.primary}
						/>
					</IconButton>
					<IconButton
						aria-label="Cancel creating note"
						w="20px"
						h="20px"
						minW="20px"
						onClick={onCancel}>
						<Icon
							name="trash"
							width={'20px'}
							height={'20px'}
							color={colors.error}
						/>
					</IconButton>
				</HStack>
			</Td>
		</Tr>
	);
};
