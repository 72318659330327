import React from 'react';
import { Button, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';
import { shadows } from '../../theme/shadows';
interface Option {
	label: string;
	value: string | number;
}

interface ISortProps {
	options: Option[];
	placeholder: string;
	value?: string | null | number;
	onChange?: (value: string | number) => void;
}

export const CustomSort: React.FC<ISortProps> = ({
	options,
	placeholder,
	value,
	onChange,
}) => {
	const arrowIcon = 'down-arrow-selector';
	return (
		<Menu>
			<MenuButton
				h="48px"
				as={Button}
				rightIcon={<Icon name={arrowIcon} width="24px" height="24px" />}
				variant="outline"
				color="dark"
				fontWeight={400}
				fontSize="14px"
				p="16px"
				borderRadius="md"
				borderColor="primary">
				{value
					? options.find(option => option.value === value)?.label
					: placeholder}
			</MenuButton>
			<MenuList
				overflow={'auto'}
				bg="white"
				minW="151px"
				borderRadius="12px"
				boxShadow={shadows.light}
				border="none"
				px={'8px'}>
				{options.map((option, index) => (
					<MenuItem
						key={index}
						value={option.value}
						onClick={() => onChange?.(option.value)}
						bg={
							value === option.value ? '#175d008b' : 'transparent'
						}
						borderRadius={'8px'}
						color={value === option.value ? 'white' : 'dark'}
						_hover={{
							bg: '#175d008b',
							borderRadius: '8px',
							color: 'white',
						}}>
						{option.label}
					</MenuItem>
				))}
			</MenuList>
		</Menu>
	);
};
