import React from 'react';
import {
	Text,
	IconButton,
	Flex,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	Button,
	useDisclosure,
} from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';
import { shadows } from '../../theme/shadows';
import { useDeleteBinSetting } from '../../query-hooks';

interface IDeleteSettingButtonProps {
	id: number;
	isUsed: boolean;
}

export const DeleteSettingButton: React.FC<IDeleteSettingButtonProps> = ({
	id,
	isUsed,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const deleteSettingMutation = useDeleteBinSetting();

	const onDeleteSetting = () => {
		deleteSettingMutation.mutate(id, {
			onSettled(data) {
				onClose();
			},
		});
	};

	return (
		<Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
			<PopoverTrigger>
				<IconButton
					aria-label="delete bin setting"
					isDisabled={isUsed}
					bg="light"
					borderRadius="50%"
					boxSize="32px"
					border="1px solid"
					borderColor="transparent"
					minW="auto"
					_hover={{ bg: 'secondary', borderColor: 'primary' }}>
					<Icon
						name="trash"
						height="18px"
						width="18px"
						color={isUsed ? '#B0B0AF' : '#AF0000'}
					/>
				</IconButton>
			</PopoverTrigger>
			<PopoverContent
				minH={'288px'}
				maxW={'296px'}
				borderRadius={'24px'}
				border={'1px solid #175D00'}
				boxShadow={shadows.dark}>
				<PopoverHeader
					display={'flex'}
					whiteSpace={'normal'}
					textAlign={'center'}
					justifyContent={'space-between'}
					p={'32px 32px 24px'}
					borderBottom={'1px solid #ECECEC'}>
					<Text variant={'B03'}>
						Are you sure you want to delete that Bin Setting?
					</Text>
				</PopoverHeader>
				<PopoverBody>
					<Flex flexDirection={'column'} gap={'24px'} p={'32px'}>
						<Button variant={'secondary'} onClick={onClose}>
							Cancel
						</Button>
						<Button
							variant={'delete'}
							onClick={onDeleteSetting}
							rightIcon={
								<Icon
									name={'trash'}
									width="24px"
									height="24px"
									color={'secondary'}
								/>
							}
							isLoading={deleteSettingMutation.isPending}>
							Delete
						</Button>
					</Flex>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};
