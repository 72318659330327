import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ErrorMessages } from '../constants';

const dailyFeedDeliverySchema = yup.object().shape({
	feedDispensed: yup.string().required(ErrorMessages.REQUIRED),
	date: yup.date().required(ErrorMessages.REQUIRED),
	note: yup.string().required(ErrorMessages.REQUIRED),
});

export type DailyFeedDeliveryFormType = yup.InferType<
	typeof dailyFeedDeliverySchema
>;

export const dailyFeedDeliveryResolver = yupResolver(dailyFeedDeliverySchema);
