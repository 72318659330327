import React, { PropsWithChildren, useCallback, useState } from 'react';
import {
	Box,
	Button,
	Table,
	TableContainer,
	Tbody,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
	Td,
	Center,
} from '@chakra-ui/react';
import { Icon, Loader, Pagination } from '../../../../components';
import { AddAnimalInfoModal } from '../../../../modals';
import { TableRow } from './components';
import {
	useCreateTestCyclesAnimal,
	useGetRanchoAnimalsOptions,
	useGetTestCyclesAnimals,
} from '../../../../query-hooks';
import { AnimalInfoHeaders } from './constants';
import { transformIdNameToOptions } from '../../../../utils';

export const AnimalInfo: React.FC<PropsWithChildren> = () => {
	const {
		isOpen: isOpenAddAnimalModal,
		onOpen: onOpenAddAnimalModal,
		onClose: onCloseAddAnimalModal,
	} = useDisclosure();

	const [page, setPage] = useState(1);

	const { data, isLoading } = useGetTestCyclesAnimals({
		search: undefined,
		currentPage: page,
	});
	const createTestCycleAnimalMutation = useCreateTestCyclesAnimal();
	const { data: ranchoAnimalsOptionsData } = useGetRanchoAnimalsOptions({
		search: undefined,
		isEnabled: isOpenAddAnimalModal,
	});

	const onCreateTestCycleAnimal = useCallback(
		(animalId: number) =>
			createTestCycleAnimalMutation.mutate(
				{ animalId },
				{
					onSuccess: data => {
						if (!data.success) {
							return;
						}
						onCloseAddAnimalModal();
					},
				},
			),
		[createTestCycleAnimalMutation, onCloseAddAnimalModal],
	);

	const items = data?.value?.items;

	return (
		<Box position={'relative'}>
			<TableContainer
				backgroundColor={'light'}
				boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
				borderRadius={'12px'}
				padding={'16px'}
				h="calc(100vh - 320px)"
				minW={'calc(100vw - 304px)'}
				overflowY={'auto'}
				display={'flex'}
				flexDirection={'column'}
				justifyContent={'space-between'}>
				{isLoading ? (
					<Loader centerHeight="400px" />
				) : (
					<Table variant="custom">
						<Thead>
							<Tr>
								{AnimalInfoHeaders.map((el, index) => (
									<Th key={index}>
										<Text
											textAlign="center"
											color="darker"
											whiteSpace="nowrap"
											minW="80px"
											fontWeight={700}>
											{el.label}
										</Text>
									</Th>
								))}
							</Tr>
						</Thead>
						<Tbody>
							{items?.length ? (
								items?.map(item => (
									<TableRow key={item.id} {...item} />
								))
							) : (
								<Tr>
									<Td colSpan={14}>
										<Center h="300px">
											<Text
												variant="B02"
												color="darkLight">
												No data yet
											</Text>
										</Center>
									</Td>
								</Tr>
							)}
						</Tbody>
					</Table>
				)}
				<Pagination
					totalCount={data?.value?.count || 0}
					currentPage={page}
					pageSize={10}
					onPageChange={val => setPage(val)}
					pt={5}
				/>
			</TableContainer>
			<Button
				variant={'primary'}
				position={'absolute'}
				bottom={'75px'}
				right={'65px'}
				width={'165px'}
				gap={'8px'}
				onClick={onOpenAddAnimalModal}>
				<Icon name="add" width={'20px'} height={'20px'} color="light" />
				Add Animal
			</Button>
			<AddAnimalInfoModal
				label="Ranch Tag"
				placeholder="Select Ranch Tag"
				title="Add Animal"
				onClose={onCloseAddAnimalModal}
				isOpen={isOpenAddAnimalModal}
				onSubmit={(id: number) => onCreateTestCycleAnimal(id)}
				isLoading={createTestCycleAnimalMutation.isPending}
				options={transformIdNameToOptions(
					ranchoAnimalsOptionsData?.value || [],
				)}
			/>
		</Box>
	);
};
