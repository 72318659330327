import React, { PropsWithChildren } from 'react';
import {
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverHeader,
	PopoverBody,
	Flex,
	Button,
	Box,
	useDisclosure,
	Text,
	ButtonProps,
} from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';
import { shadows } from '../../theme/shadows';

interface IDeleteConfirmPopupProps extends PropsWithChildren {
	onConfirm: VoidFunction;
	title: string;
	confirmButtonProps?: ButtonProps;
}

export const DeleteConfirmPopup: React.FC<IDeleteConfirmPopupProps> = ({
	children,
	onConfirm,
	title,
	confirmButtonProps,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	return (
		<Popover isOpen={isOpen} onOpen={onOpen}>
			<PopoverTrigger>{children}</PopoverTrigger>
			<PopoverContent
				minH={'288px'}
				maxW={'296px'}
				borderRadius={'24px'}
				border={'1px solid #175D00'}
				boxShadow={shadows.dark}>
				<PopoverHeader
					display={'flex'}
					whiteSpace={'normal'}
					textAlign={'center'}
					justifyContent={'space-between'}
					p={'32px 32px 24px'}
					borderBottom={'1px solid #ECECEC'}>
					<Text variant={'B03'}>{title}</Text>
				</PopoverHeader>
				<PopoverBody p="0">
					<Flex flexDirection={'column'} gap={'24px'} p={'32px'}>
						<Button
							variant={'secondary'}
							h="48px"
							onClick={onClose}>
							Cancel
						</Button>
						<Button
							variant={'delete'}
							h="48px"
							onClick={onConfirm}
							{...confirmButtonProps}>
							Delete
							<Box ml={'8px'}>
								<Icon
									name={'trash'}
									width="24px"
									height="24px"
									color={'secondary'}
								/>
							</Box>
						</Button>
					</Flex>
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};
