import React, { PropsWithChildren } from 'react';
import { Box, Text, Button, Flex } from '@chakra-ui/react';
import { Icon } from '../../components';

interface IBlockWrapperProps extends PropsWithChildren {
	title: string;
	buttonLabel: string;
	onAdd: VoidFunction;
	isDisabled?: boolean;
}

export const BlockWrapper: React.FC<IBlockWrapperProps> = ({
	children,
	title,
	buttonLabel,
	onAdd,
	isDisabled,
}) => {
	return (
		<Box>
			<Text
				fontSize="16px"
				color="#161616"
				fontWeight={700}
				textAlign="center"
				mb="24px">
				{title}
			</Text>
			<Flex flexDirection="column" gap="16px">
				{children}
			</Flex>
			<Button
				variant="link"
				mt="24px"
				w="100%"
				fontSize="14px"
				fontWeight="600"
				color="primary"
				isDisabled={isDisabled}
				leftIcon={
					<Icon
						name="add"
						width={'24px'}
						height={'24px'}
						color="primary"
					/>
				}
				onClick={onAdd}>
				{buttonLabel}
			</Button>
		</Box>
	);
};
