import React, { useState } from 'react';
import {
	Button,
	Flex,
	Menu,
	MenuButton,
	MenuGroup,
	MenuItem,
	MenuList,
} from '@chakra-ui/react';
import { Icon } from '../Icon/Icon';
import { shadows } from '../../theme/shadows';

interface CustomSelectorProps {
	onChange: (selectedItem: string) => void;
}

const CustomSelector: React.FC<CustomSelectorProps> = ({ onChange }) => {
	const [isOpen, setIsOpen] = useState(false);
	const [selectedItem, setSelectedItem] = useState<string | null>(null);

	const toggleMenu = () => setIsOpen(prev => !prev);
	const handleSelectItem = (item: string) => {
		setSelectedItem(item);
		onChange(item);
		setIsOpen(false);
	};

	const arrowIcon = isOpen ? 'up-arrow-selector' : 'down-arrow-selector';
	const leftColumnItems = [
		'Test Details',
		'Daily Error Report',
		'Test "Disqualified" Days',
		'Daily Feed Delivery',
		'Individual Animal Sick Days',
	];

	const rightColumnItems = [
		'Test Feed Ration',
		'All Files Combined',
		'Test Notes',
		'Individual Animal Retags',
		'Animals that need to be removed',
	];

	const renderMenuItems = (items: string[]) =>
		items.map(item => (
			<MenuItem
				key={item}
				onClick={() => handleSelectItem(item)}
				fontSize="14px"
				w="253px"
				// bg={
				// 	selectedItem === item
				// 		? 'rgba(23, 93, 0, 0.50)'
				// 		: 'transparent'
				// }
				_hover={{
					bg: 'rgba(23, 93, 0, 0.50)',
					color: 'white',
				}}
				color={'black'}
				p="8px"
				borderRadius="8px">
				{item}
			</MenuItem>
		));

	return (
		<Flex alignItems="center" justifyContent="center">
			<Menu isOpen={isOpen}>
				<MenuButton
					h="48px"
					as={Button}
					onClick={toggleMenu}
					rightIcon={
						<Icon name={arrowIcon} width="24px" height="24px" />
					}
					variant="outline"
					color="primary"
					fontWeight={400}
					fontSize="14px"
					p="16px"
					borderRadius="md"
					borderColor="primary">
					<Flex alignItems="center" gap="8px">
						<Icon name="data" width="24px" height="24px" />
						Other Data
					</Flex>
				</MenuButton>
				<MenuList
					w="587px"
					p="16px"
					border="none"
					borderRadius="12px"
					boxShadow={shadows.light}>
					<MenuGroup color="green.500" px="16px">
						<Flex gap="8px" justifyContent="space-around">
							<Flex flexDirection="column" px="8px" gap="8px">
								{renderMenuItems(leftColumnItems)}
							</Flex>
							<Flex
								flexDirection="column"
								gap="8px"
								borderStart="1px solid #E2E8F0"
								ps="16px">
								{renderMenuItems(rightColumnItems)}
							</Flex>
						</Flex>
					</MenuGroup>
				</MenuList>
			</Menu>
		</Flex>
	);
};

export default CustomSelector;
