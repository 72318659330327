import React from 'react';
import { format } from 'date-fns';
import { Button, Flex } from '@chakra-ui/react';

export const CustomInput: React.FC<{
	date: Date;
	setSelectedDate: (date: Date) => void;
}> = ({ date = new Date(), setSelectedDate }) => {
	const hours = date.getHours();
	const minutes = date.getMinutes();
	const isAM = hours < 12;

	const generateTimes = () => {
		const times = [];
		for (let hour = 0; hour < 12; hour++) {
			for (let minute = 0; minute < 60; minute += 15) {
				const time = new Date(date);
				time.setHours(hour + (isAM ? 0 : 12));
				time.setMinutes(minute);
				times.push(time);
			}
		}
		return times;
	};

	const handleTimeChange = (newHours: number, newMinutes: number) => {
		const newDate = new Date(date);
		newDate.setHours(newHours);
		newDate.setMinutes(newMinutes);
		setSelectedDate(newDate);
	};

	const toggleAMPM = () => {
		const newHours = isAM ? hours + 12 : hours - 12;
		handleTimeChange(newHours, minutes);
	};

	const times = generateTimes();

	return (
		<Flex direction="row" alignItems="center">
			<Flex
				overflowY="auto"
				maxH="270px"
				flex="1"
				maxW="min-content"
				gap={'10px'}
				flexDirection={'column'}
				paddingRight={'134px'}
				marginRight={'24px'}>
				{times.map((time, index) => (
					<Button
						key={index}
						onClick={() =>
							handleTimeChange(time.getHours(), time.getMinutes())
						}
						bg={
							time.getHours() === hours &&
							time.getMinutes() === minutes
								? 'primary'
								: 'transparent'
						}
						padding={'8px 16px'}
						borderRadius={'52px'}
						color={
							time.getHours() === hours &&
							time.getMinutes() === minutes
								? 'light'
								: 'dark'
						}
						flexShrink={0}
						height={'42px'}
						fontWeight={'400'}>
						{format(time, 'hh:mm')}
					</Button>
				))}
			</Flex>
			<Flex
				direction="column"
				gap={'10px'}
				position={'absolute'}
				right={'80px'}>
				<Button
					onClick={toggleAMPM}
					bg={isAM ? 'primary' : 'transparent'}
					padding={'8px 16px'}
					borderRadius={'52px'}
					color={!isAM ? 'dark' : 'light'}
					fontWeight={'400'}>
					AM
				</Button>
				<Button
					onClick={toggleAMPM}
					bg={!isAM ? 'primary' : 'transparent'}
					padding={'8px 16px'}
					borderRadius={'52px'}
					color={isAM ? 'dark' : 'light'}
					fontWeight={'400'}>
					PM
				</Button>
			</Flex>
		</Flex>
	);
};
