import { useMutation, useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../../constants';
import { ApiResponseEmpty, UpdateDailyFeedDeliveryPayload } from '../../types';
import apiBase from '../../api/api.base';
import { Alerter } from '../../utils';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

export const useUpdateDailyFeedDelivery = () => {
	const queryClient = useQueryClient();
	const { id } = useParams();

	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useMutation<
		ApiResponseEmpty,
		unknown,
		UpdateDailyFeedDeliveryPayload
	>({
		mutationFn: payload => {
			const { noteId, ...bodyPayload } = payload;
			return apiBase.put(
				`/api/ranchos/${ranchoId}/test-cycles/${id}/feed-notes/${noteId}`,
				bodyPayload,
			);
		},
		onSuccess: data => {
			if (!data.success) {
				Alerter.error(data.errors?.[0]?.message);
				return;
			}
			Alerter.success(
				data.messages?.[0]?.message ||
					'Daily feed delivery was updated',
			);

			queryClient.invalidateQueries({
				queryKey: [QueryKeys.GET_DAILY_FEED_DELIVERY],
			});
		},
	});
};
