import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import API from '../../api/api.base';
import { queryClient, QueryKeys } from '../../constants';
import { ApiResponse, CreateRemoveNotePayload } from '../../types';
import { Alerter } from '../../utils';

export const useCreateAnimalRemoveNotes = () => {
	const { id } = useParams();
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useMutation<ApiResponse<number>, unknown, CreateRemoveNotePayload>({
		mutationFn: ({ animalId, note }) =>
			API.post(
				`/api/ranchos/${ranchoId}/test-cycles/${id}/animals/${animalId}/remove-notes`,
				{ note },
			),
		onSuccess: response => {
			if (response.success) {
				Alerter.success(
					response.messages?.[0]?.message ||
						'Animal remove note was successfully added',
				);
				queryClient.invalidateQueries({
					queryKey: [QueryKeys.GET_REMOVE_NOTES],
				});
			} else {
				Alerter.error(response.errors?.[0]?.message);
			}
		},
	});
};
