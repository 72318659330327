import { useMutation } from '@tanstack/react-query';
import API from '../../api/api.base';
import { ApiResponseEmpty } from '../../types';
import { Alerter } from '../../utils';
import { queryClient, QueryKeys } from '../../constants';

export const useDeleteBinSetting = () => {
	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationFn: settingId => API.delete(`/api/app/settings/${settingId}`),
		onSuccess: response => {
			if (response.success) {
				Alerter.success('Successfully deleted');
				queryClient.invalidateQueries({
					queryKey: [QueryKeys.GET_BIN_SETTINGS],
				});
			} else {
				Alerter.error(response?.errors?.[0]?.message);
			}
		},
	});
};
