export const AnimalRetagsHeaders = [
	{
		label: '#',
	},
	{
		label: 'Tag #',
	},
	{
		label: 'Date',
	},
	{
		label: 'Old EID',
	},
	{
		label: 'New EID',
	},
	{
		label: 'Notes',
	},
	{
		label: 'Actions',
	},
];
