import React from 'react';
import { Box, Th, Tr, chakra } from '@chakra-ui/react';
import { trickyTableDividerStyles } from '../../../../../constants';
import { nowrapTableHeadersStyles } from '../../../constants';

interface ITableHeadersProps {
	extraHeaders: string[];
}

export const TableHeaders: React.FC<ITableHeadersProps> = ({
	extraHeaders,
}) => {
	return (
		<React.Fragment>
			<Tr>
				<Th style={{ backgroundColor: 'light' }}></Th>
				<Th style={{ backgroundColor: 'light' }} position="relative">
					<Box {...trickyTableDividerStyles} />
				</Th>
				{extraHeaders.map((el, index) => (
					<Th
						key={index}
						colSpan={3}
						borderRightWidth={
							extraHeaders.length - 1 !== index ? '1px' : '0px'
						}
						borderRightColor="lightGray"
						style={{
							fontSize: '16px',
							lineHeight: '24px',
							paddingTop: '16px',
							paddingBottom: '16px',
						}}>
						{el}
					</Th>
				))}
			</Tr>
			<Tr>
				<Th style={nowrapTableHeadersStyles}>Tag #</Th>
				<Th position="relative" style={nowrapTableHeadersStyles}>
					EID <Box {...trickyTableDividerStyles} />
				</Th>
				{extraHeaders.map((el, index) => {
					const extractedNum = el.split('#')[1] || '1';
					const isStartWeight = el === 'Start Weight';
					const isFinalWeight = el === 'Final Weight';
					const weightNum = isStartWeight
						? '1'
						: isFinalWeight
							? extraHeaders.length.toString()
							: extractedNum;
					return (
						<React.Fragment key={index}>
							<Th minW="85px" style={nowrapTableHeadersStyles}>
								Test <chakra.br /> Weight {weightNum}
							</Th>
							<Th minW="100px" style={nowrapTableHeadersStyles}>
								Wt <chakra.br /> Date {weightNum}
							</Th>
							<Th
								minW="160px"
								borderRightWidth={
									extraHeaders.length - 1 !== index
										? '1px'
										: '0px'
								}
								borderRightColor="lightGray"
								style={nowrapTableHeadersStyles}>
								Notes <chakra.br />
								{weightNum}
							</Th>
						</React.Fragment>
					);
				})}
			</Tr>
		</React.Fragment>
	);
};
