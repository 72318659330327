import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(utc);
dayjs.extend(advancedFormat);

export const getDateByFormat = (date?: string | Date, format?: string) =>
	dayjs(date).format(format || 'MM/DD/YYYY');

export const toPayloadDate = (date: string | Date) =>
	dayjs(date).format('YYYY-MM-DD');

export const convertStringToDate = (date?: string) => dayjs(date).toDate();
