import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import API from '../../api/api.base';
import { ApiResponseEmpty, UpdateRemoveNotePayload } from '../../types';
import { queryClient, QueryKeys } from '../../constants';
import { Alerter } from '../../utils';

export const useUpdateAnimalRemoveNote = () => {
	const { id } = useParams();
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);

	return useMutation<ApiResponseEmpty, unknown, UpdateRemoveNotePayload>({
		mutationFn: ({ animalInTestCycleId, noteId, note }) =>
			API.put(
				`/api/ranchos/${ranchoId}/test-cycles/${id}/animals/${animalInTestCycleId}/remove-notes/${noteId}`,
				{ note },
			),
		onSuccess: response => {
			if (response.success) {
				Alerter.success(
					response.messages?.[0]?.message ||
						'Animal remove note was successfully updated',
				);
				queryClient.invalidateQueries({
					queryKey: [QueryKeys.GET_REMOVE_NOTES],
				});
			} else {
				Alerter.error(response.errors?.[0]?.message);
			}
		},
	});
};
