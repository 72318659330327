import { useMutation } from '@tanstack/react-query';
import { ApiResponse } from '../../types';
import { Alerter } from '../../utils';
import apiBase from '../../api/api.base';

export const useSignUp = () => {
	return useMutation<
		ApiResponse<unknown>,
		unknown,
		{
			userName: string;
			email: string;
			password: string;
			phoneNumber: string;
			verificationCode: string;
		}
	>({
		mutationFn: payload => apiBase.post('/api/auth/sign-up', payload),
		onSuccess: data => {
			if (data.success) {
				Alerter.success('Sign up successful');
			} else {
				Alerter.error(data.errors[0].message);
			}
		},
		onError: () => {
			Alerter.error('Sign up failed');
		},
	});
};
