import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../constants';
import { ApiResponse, IdNameType } from '../../types';
import apiBase from '../../api/api.base';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

interface IPayload {
	search?: string;
	isEnabled?: boolean;
}

export const useGetRanchoAnimalsOptions = ({ search, isEnabled }: IPayload) => {
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useQuery<ApiResponse<IdNameType[]>>({
		queryKey: [QueryKeys.GET_RANCHO_ANIMALS_OPTIONS, search],
		queryFn: () =>
			apiBase.get(`/api/selectors/ranchos/${ranchoId}/animals`, {
				search,
			}),
		refetchOnWindowFocus: false,
		enabled: isEnabled,
	});
};
