import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import API from '../../api/api.base';
import { QueryKeys } from '../../constants';
import { ApiResponse, FeedRation } from '../../types';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

export const useGetFeedRation = () => {
	const { id } = useParams();
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useQuery<ApiResponse<{ count: number; items: FeedRation[] }>>({
		queryKey: [QueryKeys.GET_FEED_RATION],
		queryFn: () =>
			API.get(
				`/api/ranchos/${ranchoId}/test-cycles/${id}/test-feed-ration`,
				{},
			),
	});
};
