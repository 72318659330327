import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const addRationSchema = yup.object().shape({
	asFedPercent: yup
		.number()
		.required('Field is required')
		.typeError('Field is required'),
	dryMatter: yup
		.number()
		.required('Field is required')
		.typeError('Field is required'),
});

export const addRationResolver = yupResolver(addRationSchema);
