import {
	Button,
	Center,
	Flex,
	Table,
	TableContainer,
	Tbody,
	Td,
	Text,
	Th,
	Thead,
	Tr,
	useDisclosure,
} from '@chakra-ui/react';
import { AddAnimalInfoModal } from '../../../../modals';
import { colors } from '../../../../theme/colors';
import { Icon, Loader, Pagination } from '../../../../components';
import {
	useCreateTestCyclesAnimalChangeEidNote,
	useGetRanchoAnimalsTestOptions,
	useGetTestCyclesAnimalsChangeEidNote,
} from '../../../../query-hooks';
import React, { useCallback, useState } from 'react';
import { AnimalRetagsHeaders } from './constants';
import { TableRetagsRow } from './components';
import { useParams } from 'react-router-dom';

export const AnimalRetags: React.FC = () => {
	const { isOpen, onOpen, onClose } = useDisclosure();

	const [page, setPage] = useState(1);
	const { id } = useParams();

	const { data, isLoading, refetch } =
		useGetTestCyclesAnimalsChangeEidNote(page);

	const createTestCycleAnimalChangeEidNoteMutation =
		useCreateTestCyclesAnimalChangeEidNote();

	const { data: ranchoAnimalsOptionsData } = useGetRanchoAnimalsTestOptions({
		search: undefined,
		isEnabled: isOpen,
		testCycleId: +(id || 0),
	});

	const onCreateTestCycleAnimalChangeEidNote = useCallback(
		(animalInTestCycleId: number) =>
			createTestCycleAnimalChangeEidNoteMutation.mutate(
				{ animalInTestCycleId },
				{
					onSuccess: data => {
						if (!data.success) {
							return;
						}
						onClose();
					},
				},
			),
		[createTestCycleAnimalChangeEidNoteMutation, onClose, refetch],
	);

	const items = data?.value?.items;

	return (
		<>
			<TableContainer
				backgroundColor={'light'}
				boxShadow={'0px 0px 15px rgba(0, 0, 0, 0.10)'}
				borderRadius={'12px'}
				display={'flex'}
				flexDirection={'column'}
				minH={'calc(100vh - 271px)'}
				minW={'calc(100vw - 304px)'}
				p={'16px'}>
				<Table variant="custom">
					<Thead>
						<Tr>
							{AnimalRetagsHeaders.map((el, index) => (
								<Th key={index}>
									<Text
										textAlign="center"
										color="darker"
										whiteSpace="nowrap"
										minW="80px"
										fontWeight={700}>
										{el.label}
									</Text>
								</Th>
							))}
						</Tr>
					</Thead>
					<Tbody>
						{isLoading ? (
							<Tr>
								<Td colSpan={7}>
									<Loader centerHeight="400px" />
								</Td>
							</Tr>
						) : items?.length === 0 ? (
							<Tr>
								<Td colSpan={7}>
									<Center h="300px">
										<Text variant="B02" color="darkLight">
											No data yet
										</Text>
									</Center>
								</Td>
							</Tr>
						) : (
							items?.map((item, index) => (
								<TableRetagsRow
									key={item.id}
									{...item}
									index={index}
								/>
							))
						)}
					</Tbody>
				</Table>

				<Flex
					flexDirection={'column'}
					flexGrow={'1'}
					justifyContent={'flex-end'}>
					<Flex justify="center" mb={4}>
						<Button onClick={onOpen} color={'primary'}>
							<Icon
								name="add"
								width={'20px'}
								height={'20px'}
								color={`${colors.primary}`}
							/>
							Add Info
						</Button>
					</Flex>
				</Flex>
				<Pagination
					totalCount={data?.value?.count || 0}
					currentPage={page}
					pageSize={10}
					onPageChange={val => setPage(val)}
				/>
			</TableContainer>
			<AddAnimalInfoModal
				title="Add Info"
				label="Tag #"
				placeholder="Select or search Tag #"
				isOpen={isOpen}
				onClose={onClose}
				options={ranchoAnimalsOptionsData?.value?.map(elem => ({
					label: elem.ranchTag,
					value: elem.animalInTestId.toString(),
				}))}
				onSubmit={(id: number) =>
					onCreateTestCycleAnimalChangeEidNote(id)
				}
			/>
		</>
	);
};
