import React from 'react';
import { Td, Tr } from '@chakra-ui/react';
import { DailyFeedDeliveryItemType } from '../../../types';
import { getDateByFormat } from '../../../utils';

export const DailyFeedDeliveryRowModal: React.FC<DailyFeedDeliveryItemType> = ({
	date,
	feedDispensed,
	id,
	note,
}) => {
	return (
		<Tr>
			<Td w="100px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{id}
			</Td>
			<Td w="140px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{getDateByFormat(date)}
			</Td>
			<Td w="180px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{feedDispensed}
			</Td>
			<Td minW="170px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{note}
			</Td>
		</Tr>
	);
};
