import React from 'react';
import { Box, Th, Tr, chakra } from '@chakra-ui/react';
import { trickyTableDividerStyles } from '../../../../../constants';
import { nowrapTableHeadersStyles } from '../../../constants';
import { getDateByFormat } from '../../../../../utils';
import { CustomDatePicker } from '../../../../../components';

interface ITableHeadersProps {
	dates: string[];
	startDate?: Date;
	endDate?: Date;
	setStartDate: (date?: Date) => void;
	setEndDate: (date?: Date) => void;
	minDate?: Date;
	maxDate?: Date;
}

export const TableHeaders: React.FC<ITableHeadersProps> = ({
	dates,
	startDate,
	endDate,
	setStartDate,
	setEndDate,
	minDate,
	maxDate,
}) => {
	return (
		<React.Fragment>
			<Tr>
				<Th style={{ width: '150px' }} colSpan={1}>
					<CustomDatePicker
						showTime={false}
						calendarIconName="calendar-tiny"
						className="fake-button-left-icon"
						placeholder="Start Date"
						isClearable={true}
						value={startDate}
						onChange={setStartDate}
						minDate={minDate}
						maxDate={endDate || maxDate || new Date()}
						shouldCloseOnSelect={true}
					/>
				</Th>
				<Th style={{ width: '150px' }} position="relative" colSpan={1}>
					<CustomDatePicker
						showTime={false}
						calendarIconName="calendar-tiny"
						className="fake-button-left-icon"
						placeholder="End Date"
						isClearable={true}
						value={endDate}
						onChange={setEndDate}
						minDate={startDate || minDate || undefined}
						maxDate={maxDate || new Date()}
						shouldCloseOnSelect={true}
					/>
					<Box {...trickyTableDividerStyles} />
				</Th>

				{dates.map((el, index) => (
					<Th
						key={index}
						colSpan={6}
						borderRightWidth="1px"
						borderRightColor="lightGray"
						style={{
							fontSize: '16px',
							lineHeight: '24px',
							paddingTop: '16px',
							paddingBottom: '16px',
							fontWeight: 600,
						}}>
						{getDateByFormat(el)}
					</Th>
				))}
				<Th
					colSpan={6}
					style={{
						fontSize: '16px',
						lineHeight: '24px',
						paddingTop: '16px',
						paddingBottom: '16px',
						fontWeight: 600,
					}}>
					Cumulative for test
				</Th>
			</Tr>

			<Tr>
				<Th style={{ ...nowrapTableHeadersStyles, width: '150px' }}>
					Tag #
				</Th>
				<Th
					position="relative"
					style={{ ...nowrapTableHeadersStyles, width: '150px' }}>
					EID <Box {...trickyTableDividerStyles} />
				</Th>
				{dates.map((_el, index) => (
					<React.Fragment key={index}>
						<Th style={nowrapTableHeadersStyles}>
							BROCI <chakra.br />
							(normals)
						</Th>
						<Th style={nowrapTableHeadersStyles}>
							Total # of <chakra.br />
							Feed Events
						</Th>
						<Th style={nowrapTableHeadersStyles}>
							Total KG <chakra.br />
							Feed consumed
						</Th>
						<Th style={nowrapTableHeadersStyles}>
							Total <chakra.br />
							Duration
						</Th>
						<Th style={nowrapTableHeadersStyles}>
							Average <chakra.br />
							Duration
						</Th>
						<Th
							borderRightWidth="1px"
							borderRightColor="lightGray"
							style={nowrapTableHeadersStyles}>
							Tag Hit <chakra.br />
							COUNT
						</Th>
					</React.Fragment>
				))}
				<Th style={nowrapTableHeadersStyles}>
					BROCI <chakra.br />
					(normals)
				</Th>
				<Th style={nowrapTableHeadersStyles}>
					Total # of <chakra.br />
					Feed Events
				</Th>
				<Th style={nowrapTableHeadersStyles}>
					Total KG <chakra.br />
					Feed consumed
				</Th>
				<Th style={nowrapTableHeadersStyles}>
					Total <chakra.br />
					Duration
				</Th>
				<Th style={nowrapTableHeadersStyles}>
					Average <chakra.br />
					Duration
				</Th>
				<Th style={nowrapTableHeadersStyles}>
					Tag Hit <chakra.br />
					COUNT
				</Th>
			</Tr>
		</React.Fragment>
	);
};
