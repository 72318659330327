import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';
import API from '../../api/api.base';
import { QueryKeys } from '../../constants';
import { ApiResponse, RemoveNotes } from '../../types';

type Payload = {
	page: number;
	search?: string;
};

export const useGetAnimalRemoveNotes = ({ page, search }: Payload) => {
	const { id } = useParams();
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useQuery<ApiResponse<RemoveNotes>>({
		queryKey: [QueryKeys.GET_REMOVE_NOTES, page, search],
		queryFn: () =>
			API.get(
				`/api/ranchos/${ranchoId}/test-cycles/${id}/animals/remove-notes`,
				{ PageSize: 10, CurrentPage: page, search },
			),
	});
};
