import { colors } from '../theme/colors';
import { TestCycleStatus } from '../types';

interface StatusColorAttributes {
	bg: string;
	border: string;
}

export interface StatusColor {
	[TestCycleStatus.Pending]: StatusColorAttributes;
	[TestCycleStatus.Completed]: StatusColorAttributes;
	[TestCycleStatus.Active]: StatusColorAttributes;
	[TestCycleStatus.None]: StatusColorAttributes;
}

export const statusColor: StatusColor = {
	[TestCycleStatus.Pending]: {
		bg: `${colors.yellowFill}`,
		border: `2px solid ${colors.yellow}`,
	},
	[TestCycleStatus.Completed]: {
		bg: `${colors.greenFill}`,
		border: `2px solid ${colors.green}`,
	},
	[TestCycleStatus.Active]: {
		bg: `${colors.blueFill}`,
		border: `2px solid ${colors.blue}`,
	},
	[TestCycleStatus.None]: {
		bg: `${colors.dark}`,
		border: `2px solid ${colors.dark}`,
	},
} as const;
