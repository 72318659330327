export const labelStyles = {
	color: 'dark',
	minWidth: '190px',
	fontWeight: '600',
	fontSize: '14px',
	margin: 0,
};

export const preSurveyTextStyles = {
	lineHeight: '24px',
	fontSize: '14px',
	height: 'inherit',
	fontWeight: 500,
	color: 'dark',
};
export const wrapperBox = {
	display: 'flex',
	height: '24px',
};
