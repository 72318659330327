import React, { useState } from 'react';
import { Tr, Td, Input, IconButton, Box, HStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import {
	CustomDatePicker,
	Icon,
	DeleteConfirmPopup,
	CustomTextarea,
} from '../../../../components';
import { inputStyles } from '../../../../constants';
import { colors } from '../../../../theme/colors';
import { SickDay } from '../../../../types';
import { useUpdateSickDay, useDeleteSickDay } from '../../../../query-hooks';
import { convertStringToDate } from '../../../../utils';

interface ISickDayRowProps {}

interface SickDayForm {
	date: string;
	note: string;
}

export const SickDayRow: React.FC<SickDay> = ({
	date,
	id,
	note,
	ranchTag,
	animalInTestCycleId,
}) => {
	const [isEditMode, setIsEditMode] = useState(false);

	const { control, register, handleSubmit, reset } = useForm<SickDayForm>();

	const updateSickDayMutation = useUpdateSickDay(animalInTestCycleId, id);
	const deleteSickDayMutation = useDeleteSickDay(animalInTestCycleId, id);

	const onSubmit = (data: SickDayForm) => {
		updateSickDayMutation.mutate(
			{
				date: dayjs(data.date).format('YYYY-MM-DD'),
				note: data.note,
			},
			{
				onSuccess(data) {
					if (data.success) {
						setIsEditMode(false);
					}
				},
			},
		);
		console.log(data, 'edit data');
	};

	const onDelete = () => {
		deleteSickDayMutation.mutate();
	};

	return (
		<Tr>
			<Td w="100px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{id}
			</Td>
			<Td w="100px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{ranchTag}
			</Td>
			<Td w="140px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{isEditMode ? (
					<HStack align="flex-start">
						<Controller
							control={control}
							name="date"
							render={({ field }) => (
								<CustomDatePicker
									showTime={false}
									value={convertStringToDate(field.value)}
									onChange={field.onChange}
									className="row-field-low"
								/>
							)}
						/>
						<Box boxSize="16px" pt="4px">
							<Icon
								name="calendar"
								width={'16px'}
								height={'16px'}
							/>
						</Box>
					</HStack>
				) : (
					format(date, 'M/d/yyyy')
				)}
			</Td>
			<Td minW="170px" verticalAlign="top" style={{ fontSize: '12px' }}>
				{isEditMode ? (
					<Controller
						name="note"
						control={control}
						defaultValue=""
						render={({ field: { onChange, value } }) => (
							<CustomTextarea
								minHeight={24}
								placeholder="Enter Notes"
								padding="4px 8px"
								value={value}
								fontSize="12px"
								lineHeight="14px"
								onChange={onChange}
							/>
						)}
					/>
				) : (
					note
				)}
			</Td>
			<Td verticalAlign="top" style={{ width: '90px' }}>
				<HStack justify="space-between" maxW="65px" mx="auto">
					{isEditMode ? (
						<IconButton
							aria-label="Save editing of sick day"
							w="20px"
							h="20px"
							minW="20px"
							onClick={handleSubmit(onSubmit)}
							isLoading={updateSickDayMutation.isPending}>
							<Icon
								name="save"
								width={'20px'}
								height={'20px'}
								color={colors.primary}
							/>
						</IconButton>
					) : (
						<IconButton
							aria-label="Edit note"
							w="20px"
							h="20px"
							minW="20px"
							onClick={() => {
								reset({ date, note });
								setIsEditMode(true);
							}}>
							<Icon
								name="small-pen"
								width={'20px'}
								height={'20px'}
								color={colors.dark}
							/>
						</IconButton>
					)}
					<DeleteConfirmPopup
						title="Are you sure you want to delete that day?"
						onConfirm={onDelete}
						confirmButtonProps={{
							isLoading: deleteSickDayMutation.isPending,
						}}>
						<IconButton
							padding={0}
							aria-label="Delete day"
							w="20px"
							h="20px"
							minW="20px">
							<Icon
								name="trash"
								width={'20px'}
								height={'20px'}
								color={colors.error}
							/>
						</IconButton>
					</DeleteConfirmPopup>
				</HStack>
			</Td>
		</Tr>
	);
};
