import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../../constants';
import {
	ApiResponse,
	ReportsAnimalsWeightsResponse,
	ReportsAnimalWeightItemType,
} from '../../types';
import apiBase from '../../api/api.base';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootStore } from '../../redux/createStore';

export const useGetReportAnimalWeight = () => {
	const { id: testCycleId } = useParams();
	const ranchoId = useSelector(
		(state: RootStore) => state.auth.user.ranchoId,
	);
	return useQuery({
		queryKey: [QueryKeys.GET_REPORTS_ANIMAL_WEIGHTS, ranchoId, testCycleId],
		queryFn: (): Promise<ApiResponse<ReportsAnimalsWeightsResponse>> =>
			apiBase.get(
				`/api/ranchos/${ranchoId}/test-cycles/${testCycleId}/animal-weights`,
				{},
			),
		enabled: !!testCycleId,
	});
};
